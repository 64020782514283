import React, { useEffect } from 'react';
import { Image, View } from 'react-native';
import logo from '../assets/images/imgACLogo165px.png';
import LoginBase from './auth/LoginBase';
import { auth } from '../services/Firebase';
import { useIsFocused, useLinkTo, useNavigation } from '@react-navigation/native';

const LoadingScreen = () => {
  const isFocused = useIsFocused()
  const linkTo = useLinkTo()
  const nav = useNavigation()
  useEffect(() => {
    //This fixes us getting stuck on loading, but it means you can't back out of the app after doing anything:(
    //if (auth.currentUser) {
    //    linkTo('/search')
    //}
  }, [isFocused, auth.currentUser])
  
  return (
    <LoginBase>
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <Image style={{ resizeMode: 'contain' }} source={logo} />
      </View>
    </LoginBase>
  );
};

export default LoadingScreen;
