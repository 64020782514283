import { Collection, Collections, firestore } from './Firebase';
import { collection, doc, getDocs, query, setDoc } from 'firebase/firestore/lite';
// import { locationList, serviceList } from './ServiceData';

//Unused currently
// Pick Lists
export const Services = Collection(Collections.Services);
// export const SetServices = () => {
//   // const batch = firestore().batch();
//   serviceList.forEach(service => {
//     const docRef = doc(firestore, Collections.Services, service.name);
//     const toSet = {id: service.id, name: service.name}
//     if (service.child) {
//       toSet.child = service.child
//     }
//     setDoc(docRef, toSet)
//   });
// };
// export const SetLocations = () => {
//   // const batch = firestore().batch();
//   locationList.forEach(service => {
//     const docRef = doc(firestore, Collections.Locations, service.name);
//     const toSet = {id: service.id, name: service.name}
//     if (service.child) {
//       toSet.child = service.child
//     }
//     setDoc(docRef, toSet)
//   });
// };


export const getServices = () => {
  const servicesRef = collection(firestore, Collections.Services);
  return getDocs(servicesRef).then((services) => {
    if (services.empty) {
      throw new Error("No services found!")
    }
    else {
      return services.docs
    }
  })
}

export const getLocations = () => {
  const locationsRef = collection(firestore, Collections.Locations);

  return getDocs(locationsRef).then((locations) => {
    if (locations.empty) {
      throw new Error("No locations found!")
    }
    else {
      return locations.docs
    }
  })
}