import React from 'react';
import { Image, View } from 'react-native';
import img from '../../assets/images/imgCircleTick122px@3x.png';
import { PrimaryButton } from '../../components/Button';
import { useSafeBottomPadding } from '../../components/Hooks';
import Text from '../../components/Text';
import { SignupCompleteScreenProps } from '../../navigators/Types';
import LoginBase from './LoginBase';

const SignupCompleteScreen = ({ route, navigation }: SignupCompleteScreenProps) => {
  return (
    <LoginBase
      style={{
        paddingHorizontal: 30,
        paddingBottom: useSafeBottomPadding(),
        display: 'flex',
        justifyContent: 'center',
      }}
      hasHeader
    >
      <View style={{ display: 'flex', justifyContent: 'center' }}>
        <View style={{ flex: 1, alignItems: 'center' }}>
          <Image style={{ marginBottom: 30 }} source={img} />
          <Text
            fontFamily="Barlow-Medium"
            style={[{ textAlign: 'center', color: 'white', paddingHorizontal: 20, fontSize: 24 }]}
          >
            Thanks for signing up to AusLaw Concierge
          </Text>
        </View>
        <PrimaryButton
          inverted
          title="START SEARCHING"
          onPress={() => {
            navigation.reset({
              index: 0,
              routes: [{ name: 'SearchNavigator' }],
            });
          }}
        />
      </View>
    </LoginBase>
  );
};

export default SignupCompleteScreen;
