import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { Icon } from 'react-native-elements';
import CheckIcon from '@mui/icons-material/Check';
import { Colour } from '../../../styles/style';

const stylesheet = StyleSheet.create({
  stepContainer: {
    width: 22,
    height: 22,
    borderRadius: 11,
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
  },
  currentStep: { width: 14, height: 14, borderRadius: 7, backgroundColor: Colour.DarkBlue },
  futureStep: { width: 14, height: 14, borderRadius: 7, backgroundColor: 'white' },
  stepLine: { height: 2, flexGrow: 1, backgroundColor: 'rgba(0,0,0,0.2)' },
});

interface Props {
  step: number;
  setStep: (step: number) => void
}

const StepProgressHeader = (props: Props) => {
  const { step, setStep } = props;

  const progress = useMemo(() => {
    let internal: any = [];

    for (let i = 0; i <= 3; i++) {
      if (step === i) {
        internal.push(
          <View key={i} style={stylesheet.stepContainer}>
            <View style={stylesheet.currentStep} />
          </View>,
        );
      } else if (step > i) {
        internal.push(
          <View key={i} style={[stylesheet.stepContainer, {cursor: 'pointer'}]} onClick={() => {setStep(i)}}>
            <CheckIcon name="check" sx={{ width: 14, height: 14, color: Colour.Blue }} />
          </View>,
        );
      } else {
        internal.push(<View key={i} style={stylesheet.futureStep} />);
      }

      if (i !== 3) {
        internal.push(<View key={i + 'line'} style={stylesheet.stepLine} />);
      }
    }

    return (
      <View style={{ display: 'flex', alignItems: 'center' }}>
        <View
          style={{
            height: 60,
            width: '50%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {internal}
        </View>
      </View>
    );
  }, [step]);

  return <>{progress}</>;
};

export default StepProgressHeader;
