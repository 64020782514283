import { StyleSheet } from 'react-native';
import { StatusBarHeight } from '../components/StatusBarHelper';

export const Colour = {
  Blue: '#0A7BB8',
  DarkBlue: '#053A81',
  Grey: '#F0F0F0',
  MediumGrey: '#757575',
  DarkGrey: '#2E2E2E',
  Silver: '#C0C0C0',
  LightYellow: '#F5DB85',
  NavyBlue: '#3A7AB1',
  White: '#FFFFFF',
  LightBlue: '#DEE9F2',
  DarkerBlue: '#0E4486',
};

export const FontColour = StyleSheet.create({
  blue: {
    color: Colour.Blue,
  },
  darkBlue: {
    color: Colour.DarkBlue,
  },
  grey: {
    color: Colour.DarkGrey,
  },
});

export const FontSize = StyleSheet.create({
  small: {
    fontSize: 12,
  },
  normal: {
    fontSize: 16,
  },
  medium: {
    fontSize: 20,
  },
  large: {
    fontSize: 24,
  },
  veryLarge: {
    fontSize: 30,
  },
  extraLarge: {
    fontSize: 36,
  },
});

export const Barlow = StyleSheet.create({
  regular: {
    fontFamily: 'Barlow',
  },
  medium: {
    fontFamily: 'Barlow',
  },
  semibold: {
    fontFamily: 'Barlow',
  },
  bold: {
    fontFamily: 'Barlow',
  },
});

const Style = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: StatusBarHeight,
  },
  footer: {
    flex: 1,
    backgroundColor: 'white',
    borderRadius: 24,
    maxWidth: 600,
    width: '100%',
    marginBottom: '10%',
  },
  rowFixed: {
    flexDirection: 'row',
  },
  rowCenter: {
    alignItems: 'center',
  },
  scrollview: {
    paddingHorizontal: 30,
  },
  scrollviewContainer: {
    flexGrow: 1,
    justifyContent: 'space-between',
    paddingTop: 30,
  },
  label: {
    color: Colour.DarkBlue,
    marginVertical: 8,
    fontSize: FontSize.medium.fontSize,
    fontFamily: Barlow.medium.fontFamily,
  },
  text: {
    color: Colour.DarkGrey,
    fontSize: 18,
    letterSpacing: 0.9,
  },
  errorText: { color: 'red', paddingRight: 16 },
  row: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  profilePicture: {
    width: 135,
    height: 135,
    borderRadius: 67,
  },
  teamPicture: {
    width: 110,
    height: 110,
    borderRadius: 55,
  },
  shadow: {
    elevation: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
});

export default Style;
