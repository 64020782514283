import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { ISODateString } from 'react-native-calendar-events';
import InsetShadow from 'react-native-inset-shadow';
import block from '../../assets/images/block.png';
import Text from '../../components/Text';
import style, { Colour } from '../../styles/style';
import { DateProps, EventsProps } from './CalendarHelper';

const scheduleStyle = StyleSheet.create({
  box: {
    borderWidth: 1,
    borderColor: Colour.Grey,
    width: '100%',
  },
  edgeBox: {
    height: 20,
  },
  text: {
    position: 'absolute',
    left: '100%',
    transform: [{ translateY: -10 }],
    fontSize: 12,
    marginHorizontal: 5,
  },
  row: {
    paddingHorizontal: 40,
  },
  timeButton: {
    flex: 1,
  },
});

const WeekStrip = (props: DateProps & EventsProps) => {
  const { date, events, onChangeValue } = props;

  const [selectedTimes, setSelectedTimes] = useState<ISODateString[]>([]);

  const hasTime = React.useCallback(
    (time: ISODateString) => {
      return selectedTimes.findIndex(x => x === time) >= 0;
    },
    [selectedTimes],
  );

  const isWithinTime = React.useCallback(
    (time: ISODateString) => {
      if (selectedTimes.length > 0) {
        if ((selectedTimes.length === 2 && selectedTimes[0] <= time && selectedTimes[1] >= time) || hasTime(time)) {
          return true;
        }
      }
      return false;
    },
    [hasTime, selectedTimes],
  );

  const isBlocked = React.useCallback(
    (time: ISODateString) =>
      events &&
      events.findIndex(x => {
        const endTime = moment(time).add(1, 'hour').toISOString();
        const blocked = x.startDate < endTime && x.endDate > time;
        return blocked;
      }) >= 0,
    [events],
  );

  const toggleTime = React.useCallback(
    (time: ISODateString) => {
      let newTimes = selectedTimes;
      if (hasTime(time)) {
        newTimes = newTimes.filter(x => x !== time);
      } else {
        // Only use two times for now so that a range of blocks selected
        if (newTimes.length < 2) {
          newTimes = newTimes.concat(time);
        } else {
          if (time < newTimes[0]) {
            //If the new time is before the range, replace the first time
            newTimes = [time].concat(newTimes[1]);
          } else {
            // If the new time is between the range, replace the last time
            // OR If the new time is after the range, replace the last time
            newTimes = [newTimes[0]].concat(time);
          }
        }

        // Allow any amount of separate time blocks to be added
        // newTimes = newTimes.concat(time);
      }
      const sorted = newTimes.sort((a, b) => (a <= b ? -1 : 1));
      setSelectedTimes(sorted);
    },
    [hasTime, selectedTimes],
  );

  //Set up render blocks
  let weekDays: JSX.Element[] = [],
    padRowOne: JSX.Element[] = [],
    timeRows: JSX.Element[][] = [],
    padRowTwo: JSX.Element[] = [];

  //Construct views
  _.times(5, i => {
    const weekDay = moment(date).add(i, 'day');
    const isToday = weekDay.dayOfYear() === moment().dayOfYear();

    // Weekday render block
    weekDays.push(
      <View style={[{ flexGrow: 1, alignItems: 'center' }]} key={i}>
        <Text style={{ letterSpacing: 0.6 }}>{weekDay.format('ddd').toUpperCase()}</Text>
        <View
          style={{
            width: 24,
            height: 24,
            borderRadius: 12,
            backgroundColor: isToday ? Colour.Blue : undefined,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={{ color: isToday ? 'white' : undefined }}>{weekDay.format('D')}</Text>
        </View>
      </View>,
    );

    // Top padding
    padRowOne.push(
      <View key={'pad1' + i} style={[scheduleStyle.box, scheduleStyle.edgeBox, { flexShrink: 1, borderTopWidth: 0 }]}>
        {/* {i === 4 && <Text style={scheduleStyle.text}>8am</Text>} */}
      </View>,
    );

    // If we're using the search calendar, we only want two 4 hour blocks
    // Default setting is the two 4 hour blocks for searching
    let blockCount = 2,
      blockMoments = [moment(weekDay).hour(8), moment(weekDay).hour(13)];
    // If we're browising a profile calendar, we want to see hourly blocks
    if (events !== undefined) {
      blockCount = 9;
      blockMoments = _.times(blockCount, blockNum => moment(weekDay).hour(8 + blockNum));
    }

    // Create blocks
    _.times(blockCount, blockNum => {
      // If the block row isn't defined, initialise it to an empty array
      if (timeRows[blockNum] === undefined) {
        timeRows[blockNum] = [];
      }

      const blockMoment = blockMoments[blockNum];
      const blockIso = blockMoment.toISOString();
      const disabled = isBlocked(blockIso);
      const backgroundColor = isWithinTime(blockIso) ? Colour.Blue : 'transparent';

      // Add the current column to this row
      timeRows[blockNum].push(
        <View key={i + '_' + blockNum} style={[scheduleStyle.box, { flexShrink: 1 }]}>
          {i === 4 && <Text style={scheduleStyle.text}>{blockMoment.format('ha')}</Text>}

          <View
            style={[
              scheduleStyle.timeButton,
              {
                margin: events === undefined ? 0 : -1,
                backgroundColor,
                cursor: 'pointer',
              },
            ]}
            testID={`${i}-${blockNum}`}
            onClick={() => toggleTime(blockIso)}
          >
            {disabled && (
              <View style={{ flex: 1 }}>
                <Image style={{ width: '100%', height: undefined, aspectRatio: 1 }} source={block} resizeMode="cover" />
              </View>
            )}
          </View>
        </View>,
      );
    });

    // Bottomg padding
    padRowTwo.push(
      <View
        key={'pad2' + i}
        style={[scheduleStyle.box, scheduleStyle.edgeBox, { flexShrink: 1, borderBottomWidth: 0 }]}
      >
        {i === 4 && <Text style={scheduleStyle.text}>5pm</Text>}
      </View>,
    );
  });

  React.useEffect(() => {
    if (onChangeValue) {
      onChangeValue(selectedTimes);
    }
  }, [onChangeValue, selectedTimes]);

  return (
    <>
      <View style={{ flex: 1 }}>
        <View style={[style.rowFixed, scheduleStyle.row, { paddingVertical: 5 }]}>{weekDays.map(x => x)}</View>
        <View style={{ flex: 1 }}>
          <InsetShadow left={false} right={false}>
            <View style={[style.rowFixed, scheduleStyle.row]}>{padRowOne}</View>
            <View style={{ flex: 0, flexGrow: 1 }}>
              {timeRows.map((row, index) => (
                <View key={index} style={[style.rowFixed, scheduleStyle.row, { flex: 1 }]}>
                  {row}
                </View>
              ))}
            </View>
            <View style={[style.rowFixed, scheduleStyle.row, { flex: 0, paddingBottom: 10 }]}>{padRowTwo}</View>
          </InsetShadow>
        </View>
      </View>
    </>
  );
};

export default WeekStrip;
