import React, { useCallback } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Modal from 'react-native-modal';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

interface ModalProps {
  isVisible: boolean;
  setVisible: (val: boolean) => void;
  onDismiss?: () => void;

  buttonElement?: JSX.Element;
  buttonContent?: JSX.Element;

  modalElement: JSX.Element;

  animationIn?: Animation;
  animationOut?: Animation;
}

type Animation =
  | 'bounce'
  | 'flash'
  | 'jello'
  | 'pulse'
  | 'rotate'
  | 'rubberBand'
  | 'shake'
  | 'swing'
  | 'tada'
  | 'wobble'
  | 'bounceIn'
  | 'bounceInDown'
  | 'bounceInUp'
  | 'bounceInLeft'
  | 'bounceInRight'
  | 'bounceOut'
  | 'bounceOutDown'
  | 'bounceOutUp'
  | 'bounceOutLeft'
  | 'bounceOutRight'
  | 'fadeIn'
  | 'fadeInDown'
  | 'fadeInDownBig'
  | 'fadeInUp'
  | 'fadeInUpBig'
  | 'fadeInLeft'
  | 'fadeInLeftBig'
  | 'fadeInRight'
  | 'fadeInRightBig'
  | 'fadeOut'
  | 'fadeOutDown'
  | 'fadeOutDownBig'
  | 'fadeOutUp'
  | 'fadeOutUpBig'
  | 'fadeOutLeft'
  | 'fadeOutLeftBig'
  | 'fadeOutRight'
  | 'fadeOutRightBig'
  | 'flipInX'
  | 'flipInY'
  | 'flipOutX'
  | 'flipOutY'
  | 'lightSpeedIn'
  | 'lightSpeedOut'
  | 'slideInDown'
  | 'slideInUp'
  | 'slideInLeft'
  | 'slideInRight'
  | 'slideOutDown'
  | 'slideOutUp'
  | 'slideOutLeft'
  | 'slideOutRight'
  | 'zoomIn'
  | 'zoomInDown'
  | 'zoomInUp'
  | 'zoomInLeft'
  | 'zoomInRight'
  | 'zoomOut'
  | 'zoomOutDown'
  | 'zoomOutUp'
  | 'zoomOutLeft'
  | 'zoomOutRight';

interface StyleProps {
  buttonContainerStyle?: StyleProp<ViewStyle>;
  modalContainerStyle?: StyleProp<ViewStyle>;
}

const ModalBase = (props: ModalProps & StyleProps) => {
  const handleBack = useCallback(() => {
    if (props.onDismiss) {
      props.onDismiss();
    }
    props.setVisible(false);
  }, []);

  return (
    <>
      {props.buttonElement ? (
        props.buttonElement
      ) : (
        <View
          style={[
            {
              width: '100%',
              borderRadius: 25,
              justifyContent: 'center',
              backgroundColor: '#00000033',
              overflow: 'hidden',
            },
            props.buttonContainerStyle,
          ]}
        >
          <View onClick={() => props.setVisible && props.setVisible(true)}>
            <View
              style={{
                flexDirection: 'row',
                paddingVertical: 5,
                paddingRight: 10,
                paddingLeft: 15,
                cursor: 'pointer'
              }}
            >
              {props.buttonContent}
            </View>
          </View>
        </View>
      )}
      <Modal
        animationIn={props.animationIn ?? 'fadeIn'}
        animationOut={props.animationOut ?? 'fadeOut'}
        animationOutTiming={150}
        hasBackdrop={true}
        backdropTransitionOutTiming={0}
        hideModalContentWhileAnimating
        isVisible={props.isVisible}
        backdropColor="#000000"
        backdropOpacity={0.33}
        onBackButtonPress={handleBack}
        onBackdropPress={handleBack}
        style={[props.modalContainerStyle, { minWidth: '50%', alignSelf: 'center', maxHeight: '100%' }]}
      >
        <View
          style={[
            {
              backgroundColor: 'white',
              borderRadius: 25,
              overflow: 'hidden',
              maxHeight: '100vh',
              marginBottom: useSafeAreaInsets().bottom,
            },
            props.modalContainerStyle,
          ]}
        >
          {props.modalElement}
        </View>
      </Modal>
    </>
  );
};

export default ModalBase;
