import React, { useEffect } from 'react';
import { Image, ScrollView, View } from 'react-native';
import logo from '../../assets/images/imgACLogo165px.png';
import welcome from '../../assets/images/imgWelcomeIllustration@3x.png';
import { PrimaryButton } from '../../components/Button';
import { useSafeBottomPadding } from '../../components/Hooks';
import Text from '../../components/Text';
import { WelcomeScreenProps } from '../../navigators/Types';
import Style, { FontSize } from '../../styles/style';
import LoginBase from './LoginBase';
import { StackActions, useIsFocused, useLinkTo, useNavigation } from '@react-navigation/native';
import { auth } from '../../services/Firebase';
import { useSelector } from 'react-redux';
import { authStateSelector } from '../../redux/Store';

const WelcomeScreen = () => {
  const nav = useNavigation();
  const authState = useSelector(authStateSelector);
  const linkTo = useLinkTo();
  const isFocused = useIsFocused()
  //useEffect(() => {
    //This fixes us getting stuck on loading, but it means you can't back out of the app after doing anything:(
    //if (auth.currentUser) {
      //nav.dispatch(   
        //linkTo('')
        //StackActions.replace('SearchNavigator') 
      //);
    //}
  //}, [isFocused, auth.currentUser, authState])
  
  return (
    <LoginBase>
      <View style={{ height: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <View style={Style.header}>
          <Image style={{ height: 132, width: 145, marginTop: 44, marginBottom: 10, resizeMode: 'contain' }} source={logo} />
        </View>
        <Text
                fontFamily="Barlow-Medium"
                style={[FontSize.veryLarge, { textAlign: 'center', color: 'white', marginBottom: 15 }]}
              >
                Welcome!
              </Text>
        <View style={{ flexGrow: 2 }}>
          <View style={[Style.footer, { maxHeight: '480px' }]}>
            <View style={[Style.scrollview]}>
              <Image style={{ height: 200, width: 200, alignSelf: 'center', resizeMode: 'contain' }} source={welcome} />
              
              <Text style={[{ fontSize: 16, marginHorizontal: 30, marginBottom: 15, textAlign: 'center' }]}>
              AusLaw Concierge is legal directory and scheduling platform. It enables users to search, find and send booking requests to legal service providers, and allows those who provide legal services to create profiles to list their services and get more qualified clients
              </Text>
              <PrimaryButton
                title="SIGN UP"
                onPress={() => {
                  linkTo('/accounttype')
                }}
              />
              <PrimaryButton
                title="I HAVE AN ACCOUNT"
                onPress={() => {
                  linkTo('/login')
                }}
              />
            </View>
            <View style={{
              display: 'flex',
              justifyContent: 'space-around',
              flexDirection: 'row'
            }}>
              <a href='https://www.auslawconcierge.com.au/terms-and-conditions' style={{fontFamily: 'Barlow'}}>
                Terms & Conditions
              </a>


              <a href='https://www.auslawconcierge.com.au/privacy-policy' style={{fontFamily: 'Barlow'}}>
              Privacy Policy
              </a>

            </View>
          </View>
        </View>
      </View>
    </LoginBase>
  );
};

export default WelcomeScreen;
