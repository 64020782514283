import React from 'react';
import { Image, View } from 'react-native';
import { Asset } from 'react-native-image-picker';
import profileImage from '../assets/images/iconAddPhoto.png';
import teamImage from '../assets/images/iconAddTeamMember@3x.png';
import cameraImage from '../assets/images/iconCamera@3x.png';
import Style from '../styles/style';
import Text from './Text';
import { ToInitials } from './TextHelper';
import image_doyles from '../assets/images/crest.png';

type Default = 'profile' | 'team';

const DefaultToSource = (name?: Default) => {
  switch (name) {
    case 'profile':
      return profileImage;
    case 'team':
      return teamImage;
    default:
      return undefined;
  }
};

const ToImageSource = (uri?: string, image?: Asset, showDefault?: Default) =>
  image?.uri ? { uri: image.uri } : uri !== undefined && uri.length > 0 ? { uri } : DefaultToSource(showDefault);

export const ProfileImage = (props: {
  size: number;
  uri?: string;
  image?: Asset;
  defaultImage?: Default;
  inverted?: boolean;
  name?: string;
  isDoyles?: boolean;
  onClick?: () => {};
}) => {
  const { image, size, defaultImage, inverted, name, isDoyles, onClick } = props;
  let uri = props.uri;
  const radius = size * 0.5;
  const sizeImageWrapper = size + 14;
  const radiusImageWrapper = sizeImageWrapper * 0.5;
  if (uri === null) {
    uri = undefined;
  }

  if ((uri === undefined || uri.length === 0) && name !== undefined) {
    return (
      <View
        style={{
          backgroundColor: 'rgba(1,1,1,0.2)',
          width: size,
          height: size,
          borderRadius: radius,
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={onClick}
      >
        <Text
          style={{
            letterSpacing: 1,
            fontSize: radius,
            color: 'white',
            textAlignVertical: 'center',
          }}
        >
          {ToInitials(name)}
        </Text>
        {isDoyles && (
          <View style={{ position: 'absolute', top: 0 }}>
            <Image source={image_doyles} style={{ width: sizeImageWrapper, height: sizeImageWrapper }} />
          </View>
        )}
      </View>
    );
  }

  if ((uri !== undefined && uri.length > 0) || image !== undefined || defaultImage !== undefined) {
    const shadow =
      uri !== undefined || image !== undefined
        ? [Style.shadow, { width: size, height: size, borderRadius: radius }]
        : undefined;

    const imageWrapperStyle =
      (uri !== undefined || image !== undefined) && isDoyles
        ? [
            {
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
            },
          ]
        : undefined;
    return (
      <View style={shadow} onClick={onClick}>
        <View style={imageWrapperStyle}>
          <Image
            style={{ width: size, height: size, borderRadius: radius }}
            source={ToImageSource(uri, image, defaultImage)}
          />
          {isDoyles && (
            <View style={{ position: 'absolute', top: 0 }}>
              <Image source={image_doyles} style={{ width: sizeImageWrapper, height: sizeImageWrapper }} />
            </View>
          )}
        </View>
      </View>
    );
  } else {
    return (
      <View
        style={{
          width: size,
          height: size,
          borderRadius: radius,
          borderStyle: 'dashed',
          borderColor: '#DDD',
          borderWidth: 2,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Image source={cameraImage} />
        <Text
          fontFamily="Barlow-Medium"
          style={{
            fontSize: 16,
            marginTop: 10,
            color: inverted ? '#DDD' : '#757575',
          }}
        >
          ADD PHOTO
        </Text>
      </View>
    );
  }
};
