import { useLinkTo } from '@react-navigation/native';
import React from 'react';
import { ActivityIndicator, Text, View, FlatList } from 'react-native';
import { useSelector } from 'react-redux';
import { usePromise } from '../../components/Hooks';
import ListingItem from '../../components/Search/ListingItem';
import { userStateSelector } from '../../redux/Store';
import { Listing } from '../../redux/User';
import { GetListings } from '../../services/ListingService';
import { Barlow } from '../../styles/style';

const FavoriteListScreen = () => {
  const linkTo = useLinkTo();
  // Redux
  const { favoriteListingIds } = useSelector(userStateSelector)!;

  const [listings, error, isPending] = usePromise(
    () =>
      new Promise<Listing[]>((res, rej) => {
        //Check if we have profiles loaded
        const idsLoaded = listings?.map(x => x.id!) || [];

        // Load profiles we don't have
        const listingIdsToGet = favoriteListingIds.filter(x => !idsLoaded.includes(x));
        GetListings(listingIdsToGet).then(loadedListings => {
          //The old listings we need
          const currentListings = listings?.filter(x => favoriteListingIds.includes(x.id!));
          //Combine with new list
          const newListings = loadedListings.concat(currentListings || []);
          //Set listings
          res(newListings.sort((a, b) => (a.id! > b.id! ? 1 : -1)));
        });
      }),
    [favoriteListingIds],
  );

  return isPending ? (
    <View style={{ flex: 1, alignContent: 'center', justifyContent: 'center' }}>
      <ActivityIndicator size="large" />
    </View>
  ) : (
    <FlatList
      contentContainerStyle={{ flexGrow: 1 }}
      data={listings}
      renderItem={({ item: listing }) => (
        <ListingItem
          testID="favoriteListingItem"
          key={listing.id}
          listing={listing}
          isFavorite={favoriteListingIds.includes(listing.id!)}
          onPress={() => {
            linkTo({
              screen: listing?.isOrg ? 'ViewOrganisation' : 'ViewListing',
              params: {
                id: listing.id,
                profileId: listing.profileId,
              },
            })
          }}
        />
      )}
      ListEmptyComponent={
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <Text style={[Barlow.regular, { color: 'grey' }]}>No favorites found</Text>
        </View>
      }
    />
  );
};

export default FavoriteListScreen;
