import SegmentedControl from '@react-native-segmented-control/segmented-control';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { Divider } from 'react-native-elements';
import { Rate } from '../redux/Search';
import { Colour, FontColour, FontSize } from '../styles/style';
import { PrimaryButton, SecondaryButton } from './Button';
import CheckBox from './Checkbox';
import Chip from './Chip';
import InlinePicker from './InlinePicker';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ModalBase from './ModalBase';
import Text from './Text';

interface ModalRateSelectProps {
  value?: Rate;
  onChange: (newValue: Rate) => void;
}

export const ratePeriods = ['Hourly Rate', 'Daily Rate'];

export const rateValues = [
  'Under $200',
  '$200 - $299',
  '$300 - $399',
  '$400+',
  'Under $1000',
  '$1000 - $1999',
  '$2000 - $2999',
  '$3000+',
];

export const reportValues = ['Under $1000', '$1000 - $2999', '$3000 - $4999', '$5000+'];

const ModalRateSelect = (props: ModalRateSelectProps) => {
  const [showModal, setShowModal] = useState(false);

  const { value, onChange } = props;

  // Local state
  const [selectedRate, setSelectedRate] = useState<Rate>(
    value ?? {
      ratePeriod: 0,
      onlyHourly: false,
      onlyDaily: false,
      onlyReport: false,
    },
  );
  useEffect(() => {
    setSelectedRate(
      value ?? {
        ratePeriod: 0,
        onlyHourly: false,
        onlyDaily: false,
        onlyReport: false,
      },
    );
  }, [value]);

  const handleSubmit = useCallback(() => {
    onChange(selectedRate);
    setShowModal(false);
  }, [onChange, selectedRate]);

  const isModified = useMemo(
    () =>
      selectedRate.rate !== undefined ||
      selectedRate.onlyHourly === true ||
      selectedRate.onlyDaily === true ||
      selectedRate.reportRate !== undefined ||
      selectedRate.onlyReport === true,
    [selectedRate],
  );

  return (
    <ModalBase
      isVisible={showModal}
      setVisible={setShowModal}
      buttonElement={
        <>
          <SecondaryButton
            title="Rate"
            iconRight
            icon={
              <View>
                <KeyboardArrowDownIcon sx={{ color: Colour.DarkBlue }} />
              </View>
            }
            onPress={() => setShowModal(true)}
          />
          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            {selectedRate.rate !== undefined && (
              <Chip onDelete={() => onChange({ ...selectedRate, rate: undefined } as Rate)}>
                <Text>
                  {ratePeriods[selectedRate.ratePeriod]}:{' '}
                  {rateValues[4 * Number(selectedRate.ratePeriod) + Number(selectedRate.rate)]}
                  <Text style={{ fontSize: 15, color: '#757575' }}> (ex GST)</Text>
                </Text>
              </Chip>
            )}
            {selectedRate.onlyHourly && (
              <Chip onDelete={() => onChange({ ...selectedRate, onlyHourly: false })}>
                <Text>Only Hourly</Text>
              </Chip>
            )}
            {selectedRate.onlyDaily && (
              <Chip onDelete={() => onChange({ ...selectedRate, onlyDaily: false })}>
                <Text>Only Daily</Text>
              </Chip>
            )}
            {selectedRate.reportRate !== undefined && (
              <Chip onDelete={() => onChange({ ...selectedRate, reportRate: undefined } as Rate)}>
                <Text>
                  Report Rate: {reportValues[Number(selectedRate.reportRate)]}
                  <Text style={{ fontSize: 15, color: '#757575' }}> (ex GST)</Text>
                </Text>
              </Chip>
            )}
            {selectedRate.onlyReport && (
              <Chip onDelete={() => onChange({ ...selectedRate, onlyReport: false })}>
                <Text>Only Report</Text>
              </Chip>
            )}
          </View>
        </>
      }
      modalContainerStyle={{ flex: 1 }}
      modalElement={
        <>
          <Text
            fontFamily="Barlow-Medium"
            style={[
              FontSize.medium,
              {
                paddingVertical: 10,
                textAlign: 'center',
                color: 'white',
                backgroundColor: Colour.Blue,
              },
            ]}
          >
            Rate
          </Text>
          <ScrollView>
            <Text
              fontFamily="Barlow-Medium"
              style={[FontSize.medium, FontColour.darkBlue, { marginVertical: 10, marginHorizontal: 20 }]}
            >
              Hourly &amp; Daily Rates <Text style={{ fontSize: 15, color: '#757575' }}>(ex GST)</Text>
            </Text>
            <Divider style={{ marginBottom: 10 }} />
            <SegmentedControl
              values={ratePeriods}
              selectedIndex={selectedRate?.ratePeriod}
              onChange={e =>
                setSelectedRate({
                  ...selectedRate,
                  ratePeriod: e.nativeEvent.selectedSegmentIndex,
                } as Rate)
              }
              tintColor={Colour.Blue}
              backgroundColor={Colour.Grey}
              fontStyle={{ fontFamily: 'Barlow', color: 'black' }}
              activeFontStyle={{ fontFamily: 'Barlow', color: 'white' }}
              style={{
                backgroundColor: 'lightgrey',
                height: 40,
                alignItems: 'center',
                marginHorizontal: 20,
                marginBottom: 10,
                flexDirection: 'row',
              }}
            />
            <InlinePicker
              value={selectedRate?.rate?.toString()}
              items={rateValues.slice(4 * selectedRate.ratePeriod, 4 * selectedRate.ratePeriod + 4)}
              onChange={rate => setSelectedRate({ ...selectedRate, rate: Number(rate) } as Rate)}
            />
            <CheckBox
              containerStyle={{ marginTop: 15, marginHorizontal: 20 }}
              textStyle={{ fontSize: 16 }}
              value={selectedRate.onlyHourly}
              onChange={() => setSelectedRate({ ...selectedRate, onlyHourly: !selectedRate.onlyHourly })}
              text="Only show search results with an hourly rate"
            />
            <CheckBox
              containerStyle={{ marginHorizontal: 20 }}
              textStyle={{ fontSize: 16 }}
              value={selectedRate.onlyDaily}
              onChange={() => setSelectedRate({ ...selectedRate, onlyDaily: !selectedRate.onlyDaily })}
              text="Only show search results with a daily rate"
            />

            <Text
              fontFamily="Barlow-Medium"
              style={[FontSize.medium, FontColour.darkBlue, { marginVertical: 10, marginLeft: 20 }]}
            >
              Reports <Text style={{ fontSize: 15, color: '#757575' }}>(ex GST)</Text>
            </Text>
            <Divider />
            <InlinePicker
              value={selectedRate?.reportRate?.toString()}
              items={reportValues}
              onChange={reportRate =>
                setSelectedRate({
                  ...selectedRate,
                  reportRate: Number(reportRate),
                } as Rate)
              }
            />
            <CheckBox
              containerStyle={{
                marginTop: 20,
                marginBottom: 30,
                marginHorizontal: 20,
              }}
              textStyle={{ fontSize: 16 }}
              value={selectedRate.onlyReport}
              onChange={() => setSelectedRate({ ...selectedRate, onlyReport: !selectedRate.onlyReport })}
              text="Only show search results with report costs"
            />
          </ScrollView>
          <PrimaryButton
            title="SELECT"
            containerStyle={{ marginVertical: 0, borderRadius: 0 }}
            disabled={!isModified}
            buttonStyle={{ borderRadius: 0 }}
            onPress={handleSubmit}
          />
        </>
      }
    />
  );
};

export default ModalRateSelect;
