import React, { useMemo, useState, useEffect } from 'react';
import { Text, View, Image } from 'react-native';
import { Avatar, ListItem, ListItemProps } from 'react-native-elements';
import { useDispatch, useSelector } from 'react-redux';
import { getCategoryName, getNameFromMultiIndex } from '../../redux/PickLists';
import { pickListsStateSelector } from '../../redux/Store';
import { Listing, toggleFavorite } from '../../redux/User';
import { Barlow, FontColour } from '../../styles/style';
import FavoriteButton from '../FavoriteButton';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { ToInitials } from '../TextHelper';
import image_doyles from '../../assets/images/crest.png';
import { GetProfile } from '../../services/Firebase';
const ListingItem = (props: ListItemProps & { listing: Listing; isFavorite: boolean; navigateOnClick: () => void }) => {
  const dispatch = useDispatch();
  const { services, locations } = useSelector(pickListsStateSelector);
  const [profileDoyles, setProfileDoyles] = useState(false);
  const { listing, navigateOnClick } = props;

  useEffect(() => {
    GetProfile(listing.profileId!).then(profileResult => {
      handleDoyles(profileResult?.isDoyles);
    });

    return () => {
      setProfileDoyles(false);
    };
  }, [listing]);

  const handleDoyles = (doyles: boolean) => {
    setProfileDoyles(doyles);
  };

  const [listingServices, listingLocations] = useMemo(() => {
    let listingServicesResult;
    let listingLocationsResult;
    if (listing.services && listing.services.length > 0) {
      listingServicesResult = [...new Set(listing.services.map(s => getCategoryName(s, services)))].join(' | ');
    }
    if (listing.locations && listing.locations.length > 0) {
      listingLocationsResult = [...new Set(listing.locations.map(l => getNameFromMultiIndex(l, locations, true)))].join(
        ' | ',
      );
    }
    return [listingServicesResult, listingLocationsResult];
  }, [listing.locations, listing.services, locations, services]);

  return (
    <ListItem
      hasTVPreferredFocus={undefined}
      tvParallaxProperties={undefined}
      containerStyle={{ paddingVertical: 5, paddingHorizontal: 10, cursor: 'pointer' }}
      {...props}
      bottomDivider
    >
      <View
        style={[
          ...(listing?.isDoyles
            ? [
                {
                  position: 'relative',
                },
              ]
            : []),
        ]}
      >
        <Avatar
          rounded
          size={50}
          source={listing.imageUrl ? { uri: listing.imageUrl } : undefined}
          title={ToInitials(listing.name)}
          overlayContainerStyle={{ backgroundColor: 'rgba(1,1,1,0.2)' }}
        />
        {(listing?.isDoyles || profileDoyles) && (
          <View
            style={{
              position: 'absolute',
              top: 0,
              left: -2,
            }}
          >
            <Image source={image_doyles} style={{ width: 54, height: 54 }} />
          </View>
        )}
      </View>
      <ListItem.Content onClick={navigateOnClick}>
        <ListItem.Title style={{ ...FontColour.darkBlue, paddingVertical: 2 }}>{listing.name}</ListItem.Title>
        <ListItem.Subtitle>
          <View>
            {listingServices !== undefined && (
              <Text numberOfLines={1} style={[Barlow.regular, { fontSize: 16, color: '#2E2E2E', paddingVertical: 2 }]}>
                {listingServices}
              </Text>
            )}
            {listingLocations !== undefined && (
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginRight: 30,
                  paddingVertical: 2,
                }}
              >
                <LocationOnIcon sx={{ height: 14, width: 14, color: '#C0C0C0' }} />
                <Text numberOfLines={1} style={[Barlow.regular, { fontSize: 16, color: '#757575' }]}>
                  {listingLocations}
                </Text>
              </View>
            )}
            <View style={{ flexDirection: 'row', alignItems: 'flex-end', paddingVertical: 2 }}>
              {listing.hourlyRate !== undefined && listing.hourlyRate! > 0 && (
                <>
                  <Text style={[Barlow.regular, FontColour.darkBlue, { fontSize: 17 }]}>${listing.hourlyRate}</Text>
                  <Text style={{ marginRight: 5, fontSize: 15, color: '#2E2E2E' }}>/hr</Text>
                </>
              )}

              {listing.dailyRate !== undefined && listing.dailyRate! > 0 && (
                <>
                  <Text style={[Barlow.regular, FontColour.darkBlue, { fontSize: 17 }]}>${listing.dailyRate}</Text>
                  <Text
                    style={{
                      marginRight: 5,
                      fontSize: 15,
                      color: '#2E2E2E',
                      textAlignVertical: 'bottom',
                    }}
                  >
                    /day
                  </Text>
                </>
              )}

              {listing.reportCost !== undefined && listing.reportCost! > 0 && (
                <>
                  <Text style={[Barlow.regular, FontColour.darkBlue, { fontSize: 17 }]}>${listing.reportCost}</Text>
                  <Text
                    style={{
                      marginRight: 5,
                      fontSize: 15,
                      color: '#2E2E2E',
                      textAlignVertical: 'bottom',
                    }}
                  >
                    /report
                  </Text>
                </>
              )}
              {listing.hourlyRate || listing.dailyRate || listing.reportCost ? (
                <Text style={{ fontSize: 12 }}>(ex GST)</Text>
              ) : undefined}
            </View>
          </View>
        </ListItem.Subtitle>
      </ListItem.Content>
      <FavoriteButton isFavorite={props.isFavorite} onClick={() => dispatch(toggleFavorite(listing.id!))} />
    </ListItem>
  );
};

export default ListingItem;
