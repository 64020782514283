import { Asset } from 'react-native-image-picker';
import { Action } from 'redux';
import { SetUserFavorites, auth, logAnalyticsEvent } from '../services/Firebase';
import { CancelInvitation, GetUnacceptedInvitations } from '../services/InvitationService';
import { GetListingsForOrg } from '../services/ListingService';
import { Plan } from '../services/StripeService';
import { CalendarEvent } from './Calendar';
import { UserType } from './Register';
import { ThunkPromise, ThunkResult } from './Store';
import { captureException } from '@sentry/react';

// MODELS
export interface Auth {
  userId: string;
  email: string | null;
}

export interface Profile {
  name: string;
  email: string;
  userType: UserType;
  phone?: string;
  plan?: Plan;
  parentProfileId?: string;
  isDoyles?: boolean;
}

export interface SignInModel {
  email: string;
  password: string;
}

export interface Listing {
  id: string;
  profileId?: string;

  imageUrl?: string;
  name?: string;

  hourlyRate?: number;
  dailyRate?: number;
  reportCost?: number;
  bio?: string;
  phone?: string;
  mobile?: string;
  email?: string;

  streetAddress?: string;
  suburb?: string;
  postcode?: string;
  state?: string;

  services?: string[];
  locations?: string[];
  accreditations?: string;
  nylasConnected?: boolean;
  planStatus?: string;
  events?: CalendarEvent[];

  isOrg?: boolean;

  dateCreated?: string;
  dateModified?: string;

  isDoyles?: boolean;
  doylesImageUrls?: string[];
  doylesImages?: Asset[];
}

export interface Invite {
  id: string;
  inviterName: string;
  inviteeName: string;
  inviteeEmail: string;
  organisationId: string;

  code: string;
  cancelled: boolean;
  accepted: boolean;

  loading?: boolean;
}

// ACTION TYPES
enum ActionType {
  SET_AUTH = 'USER_SET_AUTH',
  SET_PROFILE = 'USER_SET_PROFILE',
  SET_LISTING = 'USER_SET_LISTING',
  SET_ORG_LISTINGS = 'USER_SET_ORG_LISTINGS',
  SET_ORG_INVITES = 'USER_SET_ORG_INVITES',

  SET_FAVORITES = 'USER_SET_FAVORITES',
  TOGGLE_FAVORITES = 'USER_TOGGLE_FAVORITES',
  SET_LOADING = 'SET_LOADING',
  RESET = 'USER_RESET',
}

interface UserAction extends Action<ActionType> {
  auth?: Auth;
  profile?: Profile;
  favoriteListingIds?: string[];
  listing?: Listing;
  orgListings?: Listing[];
  orgInvites?: Invite[];
  loadingState?: boolean
}

// ACTIONS
export const setAuth = (auth: Auth): UserAction => ({
  type: ActionType.SET_AUTH,
  auth,
});
export const setProfile = (profile: Profile): UserAction => ({
  type: ActionType.SET_PROFILE,
  profile,
});
export const setFavorites = (favoriteUserIds: string[]): UserAction => ({
  type: ActionType.SET_FAVORITES,
  favoriteListingIds: favoriteUserIds,
});
export const setListing = (listing?: Listing): UserAction => ({
  type: ActionType.SET_LISTING,
  listing,
});
export const setOrgListings = (orgListings: Listing[]): UserAction => ({
  type: ActionType.SET_ORG_LISTINGS,
  orgListings,
});
export const setOrgInvites = (orgInvites: Invite[]): UserAction => ({
  type: ActionType.SET_ORG_INVITES,
  orgInvites,
});
export const setLoadingState = (loadingState: boolean): UserAction => ({
  type: ActionType.SET_LOADING,
  loadingState
})
export const reset: UserAction = { type: ActionType.RESET };

// THUNKS
export const toggleFavorite =
  (id: string): ThunkResult<void> =>
  (dispatch, getState) => {
    const newFavorites = [...getState().user.favoriteListingIds];
    const exists = newFavorites.indexOf(id);
    if (exists >= 0) {
      newFavorites.splice(exists, 1);
    } else {
      newFavorites.push(id);
    }
    logAnalyticsEvent('favorite_toggled', {
      favoritedId: id,
      isFavorite: exists < 0

    })
    // Update store
    dispatch(setFavorites(newFavorites));

    // Update firestore
    SetUserFavorites(newFavorites);
  };

export const fetchOrgListings =
  (id?: string): ThunkPromise =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      // Get the org listings
      if (!id && !getState().user.auth?.userId) {
        return {};
      }
      GetListingsForOrg(id ?? getState().user.auth!.userId)
        .then(res => {
          dispatch(setOrgListings(res));
          resolve();
        })
        .catch(error => {
          captureException(error)
          alert("Error fetching listings for organisation")
        });
    });

export const fetchOrgInvites = (): ThunkPromise => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const userId = getState().user.auth!.userId;
    // Get the invitations and update state
    GetUnacceptedInvitations(userId)
      .then(snapshot => {
        const invites = snapshot.docs.map(
          doc =>
            ({
              id: doc.id,
              inviteeName: doc.data().inviteeName,
            }) as Invite,
        );
        dispatch(setOrgInvites(invites));
        resolve();
      })
      .catch(error => {
        captureException(error)
        alert("Error fetching invites for organisation")
      });
  });

export const cancelInvite =
  (inviteId: string): ThunkPromise =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const invites = getState().user.orgInvites;
      if (invites) {
        invites[invites.findIndex(x => x.id === inviteId)!].loading = true;
        dispatch(setOrgInvites(invites));
      }
      //Cancel the invite
      CancelInvitation(inviteId)
        .then(() => {
          logAnalyticsEvent('invite_cancelled', {orgId: auth.currentUser?.uid!})
          //Remove the invite from local state
          dispatch(fetchOrgInvites())
            .then(() => {
              resolve();
            })
            .catch(error => {
              captureException(error)
              reject()
            });
        })
        .catch(error => {
          captureException(error)
          alert('Error canceling invite')
        });
    });

// STATE
interface UserState {
  auth?: Auth;
  profile?: Profile;
  favoriteListingIds: string[];
  listing?: Listing;
  orgListings?: Listing[];
  orgInvites?: Invite[];
  loadingState: boolean
}

const initialState: UserState = {
  auth: undefined,
  profile: undefined,
  favoriteListingIds: [],
  listing: undefined,
  orgInvites: undefined,
  orgListings: undefined,
  loadingState: true
};

// REDUCER
const reducer = (state = initialState, action: UserAction): UserState => {
  switch (action.type) {
    case ActionType.SET_AUTH:
      return { ...state, auth: action.auth };
    case ActionType.SET_PROFILE:
      return { ...state, profile: action.profile };
    case ActionType.SET_FAVORITES:
      return { ...state, favoriteListingIds: action.favoriteListingIds! };
    case ActionType.SET_LISTING:
      return { ...state, listing: action.listing };
    case ActionType.SET_ORG_LISTINGS:
      return { ...state, orgListings: action.orgListings };
    case ActionType.SET_ORG_INVITES:
      return { ...state, orgInvites: action.orgInvites };
    case ActionType.SET_LOADING:
      return {...state, loadingState: action.loadingState || false}

    case ActionType.RESET:
      return initialState;
    default:
      return state;
  }
};
export default reducer;
