import { max } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

export const usePromise = <T extends unknown>(
  promise: () => Promise<T>,
  deps: React.DependencyList,
): [T | undefined, any, boolean] => {
  const [state, setState] = useState<{
    value: T | undefined;
    error: any;
    isPending: boolean;
  }>({ value: undefined, error: undefined, isPending: true });

  useEffect(() => {
    let isSubscribed = true;

    setState({ ...state, error: undefined, isPending: true });
    promise()
      .then(result => {
        if (isSubscribed) {
          setState({ ...state, value: result, isPending: false });
        }
      })
      .catch(error => {
        if (isSubscribed) {
          setState({ ...state, error, isPending: false });
        }
      });

    return () => {
      isSubscribed = false;
    };
  }, deps);

  const { value, error, isPending } = state;
  return [value, error, isPending];
};

export const useSafeBottomPadding = () => {
  const inset = useSafeAreaInsets();
  return max([10, inset.bottom]);
};
