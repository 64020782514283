import React from 'react';
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native';
import { Button, ButtonProps } from 'react-native-elements';
import Text from '../components/Text';
import { Colour } from '../styles/style';

export const PrimaryButton = (props: ButtonProps & { inverted?: boolean }) => (
  <Button
    {...props}
    titleStyle={[
      {
        fontFamily: 'Barlow',
        fontSize: 18,
        fontWeight: '500',
        letterSpacing: 1.5,
        color: props.inverted ? Colour.DarkBlue : 'white',
      },
      props.titleStyle,
    ]}
    containerStyle={[
      {
        marginVertical: 10,
        width: '100%',
      },
      props.containerStyle,
    ]}
    buttonStyle={[
      {
        height: 50,
        borderRadius: 25,
        backgroundColor: props.inverted ? 'white' : Colour.Blue,
      },
      props.buttonStyle,
    ]}
  />
);

export const SecondaryButton = (props: ButtonProps & { inverted?: boolean }) => (
  <Button
    titleStyle={{
      fontFamily: 'Barlow',
      fontSize: 16,
      color: props.inverted ? 'white' : '#757575',
      textAlign: 'left',
      flex: 1,
    }}
    containerStyle={{
      marginVertical: 5,
    }}
    buttonStyle={{
      borderRadius: 5,
      padding: 10,
      backgroundColor: props.disabled ? '#C0C0C0' : props.inverted ? 'white' : Colour.Grey,
    }}
    {...props}
  />
);

export const TextButton = (props: {
  onPress: () => void;
  text: string;
  disabled?: boolean;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
}) => {
  return (
    <View
      disabled={props.disabled}
      style={[{ alignSelf: 'center', margin: 10 }, props.style, { cursor: 'pointer' }]}
      onClick={props.onPress}
    >
      <Text fontFamily="Barlow-Medium" style={[{ color: Colour.DarkBlue }, props.textStyle]}>
        {props.text}
      </Text>
    </View>
  );
};
