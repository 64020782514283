import React from 'react';
import { Linking, View, StyleProp, TextStyle } from 'react-native';
import { Colour } from '../styles/style';

import Text from '../components/Text';

export const ToInitials = (name: string) => {
  if (name === undefined || name == null) {
    return '';
  }

  let regexp = name.match(/\b\w/g) || [];
  let initials = ((regexp.shift() || '') + (regexp.pop() || '')).toUpperCase();
  return initials;
};

export const Link = (props: { children: string; link: string; style?: StyleProp<TextStyle> }) => (
  <Text style={[{ color: Colour.DarkBlue }, props.style]} onPress={() => Linking.openURL(props.link)}>
    {props.children}
  </Text>
);

export const BulletPoint = (dash: JSX.Element, text: JSX.Element) => {
  return (
    <View style={{ flexDirection: 'row', alignItems: 'flex-start', padding: 20 }}>
      <View style={{ width: 40, height: 40, alignItems: 'center', marginRight: 10 }}>{dash}</View>
      <View style={{ flex: 1 }}>{text}</View>
    </View>
  );
};

export const StringIsUndefinedOrEmpty = (str?: string) => str === undefined || str === null || str.length === 0;
