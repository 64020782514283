import moment from 'moment';
import React, { useLayoutEffect } from 'react';
import { Image, ScrollView, TouchableOpacity, View } from 'react-native';
import { useSelector } from 'react-redux';
import iconEdit from '../../assets/images/iconEditCircleWhite@3x.png';
import { useSafeBottomPadding } from '../../components/Hooks';
import Text from '../../components/Text';
import { ViewPersonalProfileScreenProps } from '../../navigators/Types';
import { userStateSelector } from '../../redux/Store';
import Style, { Colour, FontSize } from '../../styles/style';
import { useLinkTo } from '@react-navigation/native';
import LoginBase from '../auth/LoginBase';

const ViewPersonalProfile = ({ route, navigation }: ViewPersonalProfileScreenProps) => {
  const userState = useSelector(userStateSelector);
  const linkTo = useLinkTo();

  const hasPlan = userState.profile?.plan;
  let planDescription = 'Free';
  if (hasPlan) {
    const unixTimestamp = Number(userState.profile?.plan?.trial_end);
    const trialEnd = moment.unix(unixTimestamp);
    planDescription = moment() < trialEnd ? `Trial to ${trialEnd.format('DD/MM/YY')}` : 'Active';
  }

  planDescription = userState.profile?.parentProfileId ? 'Organisation Plan' : planDescription;

  return (
    <LoginBase>
      <View style={{ flex: 1, width: '100%', flexWrap: 'wrap', alignContent: 'center' }}>
        <Text
          style={[
            FontSize.extraLarge,
            {
              color: 'white',
              letterSpacing: 3,
              height: '10%',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
          ]}
        >
          Profile
        </Text>

        <View style={[Style.footer, { maxHeight: 350 }]}>
          <View
            testID="editProfileButton"
            style={{
              marginRight: 15,
              marginTop: 15,
              display: 'flex',
              flexWrap: 'wrap',
              alignContent: 'flex-end',
              position: 'absolute',
              width: '100%',
              zIndex: 1,
              cursor: 'pointer',
            }}
            onClick={() => {
              linkTo('/editprofile');
            }}
          >
            <Image source={iconEdit} style={{ width: 36, height: 36 }} />
          </View>
          <View
            style={[Style.scrollview, {paddingTop: 10}]}
            // contentContainerStyle={{ paddingTop: 30, paddingBottom: useSafeBottomPadding() }}
          >
            <Text style={Style.label}>Full name</Text>
            <Text style={Style.text}>{userState.profile?.name}</Text>

            <Text style={[Style.label, { marginTop: 30 }]}>Email</Text>
            <Text style={Style.text}>{userState.profile?.email}</Text>

            <Text style={[Style.label, { marginTop: 30 }]}>Phone</Text>
            <Text style={Style.text}>{userState.profile?.phone || 'Not set'}</Text>

            <Text style={[Style.label, { marginTop: 30 }]}>Current Plan</Text>
            <Text style={Style.text}>{planDescription}</Text>
          </View>
        </View>
      </View>
    </LoginBase>
  );
};

export default ViewPersonalProfile;
