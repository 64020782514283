import React, { FunctionComponent } from 'react';
import { Platform } from 'react-native';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { SearchBar as ElementsSearchBar, SearchBarProps } from 'react-native-elements';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Colour } from '../../styles/style';

const SearchBar: FunctionComponent<SearchBarProps> = props => {
  const { value, onChangeText } = props;
  return (
    <ElementsSearchBar
      value={value}
      onChangeText={onChangeText}
      placeholder={props.placeholder ?? 'Search'}
      platform={Platform.OS === 'ios' ? 'ios' : 'android'}
      placeholderTextColor={Colour.Grey}
      searchIcon={<SearchOutlinedIcon sx={{ color: 'white' }} />}
      clearIcon={<ClearIcon sx={{ color: 'white' }} onClick={() => onChangeText('')} />}
      cancelIcon={<ArrowBackIcon sx={{ color: 'white' }} />}
      cancelButtonProps={{
        color: 'white',
        buttonTextStyle: { fontFamily: 'Barlow' },
      }}
      containerStyle={{
        marginLeft: Platform.OS === 'ios' ? 0 : 20,
        backgroundColor: 'transparent',
      }}
      inputContainerStyle={[
        { backgroundColor: 'rgba(0,0,0,0.2)', borderRadius: 10 },
        Platform.OS === 'android' && { paddingRight: 0, marginRight: 0 },
      ]}
      inputStyle={[{ color: 'white', fontFamily: 'Barlow' }, Platform.OS === 'android' && { marginLeft: 5 }]}
      {...(Platform.OS === 'ios'
        ? {}
        : {
            leftIconContainerStyle: {
              margin: 0,
              marginHorizontal: 0,
              marginLeft: 0,
              marginRight: 0,
              paddingRight: 0,
            },
          })}
    />
  );
};

export default SearchBar;
