import { Platform, ScrollView, View } from 'react-native';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Divider } from 'react-native-elements';
import CheckIcon from '@mui/icons-material/Check';
import { getNameFromMultiIndex, NSelectItem } from '../redux/PickLists';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Colour, FontSize } from '../styles/style';
import { PrimaryButton, SecondaryButton } from './Button';
import Chip from './Chip';
import ModalBase from './ModalBase';

import SearchBar from './Search/SearchBar';
import Text from './Text';

interface ModalMultiSelectProps {
  items: NSelectItem[];

  value?: string[];
  onChange: (newValue: string[]) => void;
  onModalOpen?: () => void;
  onSelect?: (selected: string[]) => void;
  selectText?: string;
  headerText?: string;
}

const toggleId = (id: string, list: string[]) => {
  const newSelected = [...list];
  const exists = list.indexOf(id);
  if (exists >= 0) {
    newSelected.splice(exists, 1);
  } else {
    newSelected.push(id);
  }
  return newSelected;
};

interface MultiAccordionProps {
  sections: NSelectItem[];
  selected: string[];
  onChange: (newSelected: string[]) => void;

  expanded: string[];
  onChangeExpanded: (newExpanded: string[]) => void;
}

const MultiAccordion = (props: MultiAccordionProps) => {
  const { sections, selected, onChange, expanded, onChangeExpanded } = props;

  const sortItemChild = (items: NSelectItem[]): NSelectItem[] => {
    return items.sort((a, b) => a.name.localeCompare(b.name));
  };

  return (
    <div>
      {sections.map(item => {
        const isSelected = selected.includes(item.id);

        const hasChildren = item.child !== undefined && item.child.length > 0;
        const isExpanded = hasChildren && expanded.includes(item.id);
        return (
          <View key={item.id} style={{ flexGrow: 1 }}>
            <Divider />
            <View>
              <View
                style={{
                  paddingLeft: 15,
                  paddingVertical: 15,
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <View
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: 15,
                    justifyContent: 'center',
                    backgroundColor: Colour.Grey,
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  testID={item.id}
                  onClick={() => {
                    onChange(toggleId(item.id, selected));
                  }}
                >
                  {isSelected && <CheckIcon sx={{ color: Colour.DarkBlue, height: 18, width: 18 }} />}
                </View>
                <Text
                  style={{
                    marginHorizontal: 15,
                    fontSize: 18,
                    color: Colour.DarkGrey,
                    flex: 1,
                  }}
                >
                  {item.name}
                </Text>
                {hasChildren && (
                  <View style={{ marginRight: 10 }}>
                    {isExpanded ? (
                      <KeyboardArrowDownIcon
                        sx={{ color: Colour.DarkBlue, cursor: 'pointer' }}
                        onClick={() => {
                          onChangeExpanded(toggleId(item.id, expanded));
                        }}
                      />
                    ) : (
                      <KeyboardArrowUpIcon
                        sx={{ color: Colour.DarkBlue, cursor: 'pointer' }}
                        onClick={() => {
                          onChangeExpanded(toggleId(item.id, expanded));
                        }}
                      />
                    )}
                  </View>
                )}
              </View>
            </View>
            <View style={{ overflow: 'hidden', marginLeft: 20 }}>
              {hasChildren && isExpanded && (
                <MultiAccordion
                  sections={sortItemChild(item.child!)}
                  selected={selected}
                  onChange={onChange}
                  expanded={expanded}
                  onChangeExpanded={onChangeExpanded}
                />
              )}
            </View>
          </View>
        );
      })}
    </div>
  );
};

const ModalMultiSelect = (props: ModalMultiSelectProps) => {
  const { items, value, onChange, selectText, headerText } = props;
  const [showModal, setShowModal] = useState(false);

  const [selected, setSelected1] = useState<string[]>(value !== undefined ? [...value] : []);

  const setSelected = (val: any) => {
    setSelected1(val)
  }
  useEffect(() => {
    setSelected(value !== undefined ? [...value] : []);
  }, [value]);

  const [expanded, setExpanded] = useState<string[]>([]);

  useEffect(() => {
    if (!showModal) {
      setExpanded([]);
      handleSubmit()
    }
  }, [showModal]);

  const handleSubmit = useCallback(() => {
    if (selected !== undefined) {
      if (props.onSelect) {
        props.onSelect(selected)
      }
      onChange(selected);
    }
    setShowModal(false);
  }, [onChange, selected]);

  const [searchText, setSearchText] = useState('');

  const searchItems = useMemo(() => {
    let itemResults;

    if (searchText.length > 0) {
      const searchItem = (item: NSelectItem) => {
        // Check if name matches
        let matches = item.name.toLowerCase().includes(searchText.toLowerCase());
        // Check if detail exists
        if (item.detail) {
          // Does it match
          matches = matches || item.detail.toLowerCase().includes(searchText.toLowerCase());
        }

        // Check if any child matches
        let foundChildren: NSelectItem[] = [];
        item.child?.forEach(child => {
          const childFound = searchItem(child);
          if (childFound) {
            foundChildren.push(childFound);
          }
        });

        return matches || foundChildren.length > 0
          ? ({
              id: item.id,
              name: item.name,
              child: foundChildren.length > 0 ? foundChildren : undefined,
            } as NSelectItem)
          : undefined;
      };

      itemResults = items.reduce((group: NSelectItem[], item) => {
        const found = searchItem(item);
        if (found) {
          group.push(found);
        }
        return group;
      }, []);
    }

    return itemResults;
  }, [items, searchText]);

  const setShowAndCallCallback = (val: boolean) => {
    if (val && props.onModalOpen) {
      props.onModalOpen()
    }
    setShowModal(val)
  }
  return (
    <ModalBase
      isVisible={showModal}
      setVisible={setShowAndCallCallback}
      buttonElement={
        <>
          <SecondaryButton
            title={selectText ?? 'Select'}
            iconRight
            testID={selectText}
            icon={
              <View style={{ transform: [{ rotate: '90deg' }] }}>
                <KeyboardArrowRightIcon sx={{ color: Colour.DarkBlue }} />
              </View>
            }
            onPress={() => setShowAndCallCallback(true)}
          />
          {selected.length > 0 && (
            <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
              {selected.map(selectedIndex => (
                <Chip key={selectedIndex} onDelete={() => onChange(toggleId(selectedIndex, selected))}>
                  <Text style={{ flexShrink: 1, flexWrap: 'wrap' }}>{getNameFromMultiIndex(selectedIndex, items)}</Text>
                </Chip>
              ))}
            </View>
          )}
        </>
      }
      modalElement={
        <>
          <View style={{ backgroundColor: Colour.Blue }}>
            <Text fontFamily="Barlow" style={[FontSize.large, { marginTop: 10, textAlign: 'center', color: 'white' }]}>
              {headerText ?? 'Select'}
            </Text>
            <SearchBar
              value={searchText}
              onChangeText={setSearchText}
              platform={Platform.OS === 'ios' ? 'ios' : 'android'}
            />
          </View>

          <ScrollView style={{ flex: 1 }}>
            <MultiAccordion
              sections={searchItems ?? items}
              selected={selected}
              onChange={setSelected}
              expanded={expanded}
              onChangeExpanded={setExpanded}
            />
          </ScrollView>
          <PrimaryButton
            title="SELECT"
            containerStyle={{ marginVertical: 0, borderRadius: 0 }}
            {...props}
            buttonStyle={{ borderRadius: 0 }}
            onPress={handleSubmit}
          />
        </>
      }
    />
  );
};

export default ModalMultiSelect;
