import React, { useEffect, useMemo, useState } from 'react';
import { ActivityIndicator, Image, Linking, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import iconLocation from '../../assets/images/iconLocation@3x.png';
import { PrimaryButton } from '../../components/Button';
import FavoriteButton from '../../components/FavoriteButton';
import { useSafeBottomPadding } from '../../components/Hooks';
import { ProfileImage } from '../../components/ProfileImage';
import callIcon from '../../assets/images/iconCall@3x.png';
import Text from '../../components/Text';
import { StringIsUndefinedOrEmpty } from '../../components/TextHelper';
import { ViewListingProps } from '../../navigators/Types';
import { getCategoryName, getNameFromMultiIndex } from '../../redux/PickLists';
import { pickListsStateSelector, userStateSelector } from '../../redux/Store';
import { Listing, toggleFavorite } from '../../redux/User';
import { GetProfile, logAnalyticsEvent } from '../../services/Firebase';
import { GetListing, GetListingByProfile } from '../../services/ListingService';
import Style, { Colour, FontSize } from '../../styles/style';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DoylesImageList from '../../components/DoylesImageList';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import LoginBase from '../auth/LoginBase';
import { auth as firebaseAuth } from '../../services/Firebase';
import { CircularProgress } from '@mui/material';

const stylesheet = StyleSheet.create({
  button: {
    width: 77,
    height: 77,
    borderRadius: 50,
    backgroundColor: Colour.Blue,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  topButton: { color: 'white', fontSize: 14, letterSpacing: 1.17, marginTop: 11, textAlign: 'center' },
  price: { color: Colour.DarkBlue, fontFamily: 'Barlow', fontSize: 20, letterSpacing: 0.83 },
  priceItem: { color: '#757575', fontSize: 15, letterSpacing: 0.83 },
  priceCell: {
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 20,
    paddingRight: 20,
    width: 'auto',
    alignSelf: 'flex-start',
  },
  priceCellRightBorder: { borderRightColor: Colour.Grey, borderRightWidth: 2 },
  whitearea: {
    flex: 1,
    backgroundColor: 'white',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    paddingBottom: 10,
  },
});

const ViewListing = ({ route, navigation }: ViewListingProps) => {
  const { id } = route.params;

  const linkTo = useLinkTo();
  const dispatch = useDispatch();
  const { auth, favoriteListingIds, listing: userListing } = useSelector(userStateSelector);
  const { services, locations } = useSelector(pickListsStateSelector);
  const [profileDoyles, setProfileDoyles] = useState(false);

  const [parentOrg, setParentOrg] = useState<Listing>();
  const [listing, setListing] = useState<Listing>();
  const userIsTheOwner = useMemo(() => auth?.userId == listing?.profileId, [auth, listing]);
  const phone = useMemo(() => (listing?.mobile ? listing?.mobile : listing?.phone), [listing]);

  React.useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      // The screen is focused
      logAnalyticsEvent('listing_viewed', {
        listingId: listing?.id
      })
      // Call any action
    });

    // Return the function to unsubscribe from the event so it gets removed on unmount
    return unsubscribe;
  }, [navigation]);
  useEffect(() => {
    const getListing = async () => {
      if (userListing?.id === id) {
        setListing(userListing);
      } else if (firebaseAuth.currentUser) {
        const listing = await GetListing(id);
        setListing(listing);
      }
    };

    getListing();
  }, [id, userListing, firebaseAuth.currentUser]);

  useEffect(() => {
    GetProfile(listing?.profileId!).then(profileResult => {
      handleDoyles(profileResult?.isDoyles);
      if (profileResult?.parentProfileId) {
        GetListingByProfile(profileResult.parentProfileId).then(setParentOrg);
      } else {
        setParentOrg(undefined)
      }
    });
    return () => {
      setProfileDoyles(false);
    };
  }, [listing]);

  const handleDoyles = (doyles: boolean) => {
    setProfileDoyles(doyles);
  };

  const [listingServices, listingSpecialisations, listingLocations] = useMemo(() => {
    let listingServices;
    let listingSpecialisations;
    let listingLocations;

    if (listing?.services && listing?.services.length > 0) {
      listingServices = [...new Set(listing?.services.map(s => getCategoryName(s, services)))].join(', ');
      listingSpecialisations = [...new Set(listing?.services.map(s => getNameFromMultiIndex(s, services, true)))].join(
        ', ',
      );
    }
    if (listing?.locations && listing?.locations.length > 0) {
      listingLocations = [...new Set(listing?.locations.map(l => getNameFromMultiIndex(l, locations, true)))].join(
        ', ',
      );
    }
    return [listingServices, listingSpecialisations, listingLocations];
  }, [listing?.locations, listing?.services, locations, services]);
  if (!listing) {
    return (
      <View style={{ flex: 1, alignContent: 'center', justifyContent: 'center'}}>
        <ActivityIndicator size={70} color={'white'}/>
      </View>
    )
  }
  return (
    <LoginBase>
      <div style={{ flex: 1, minWidth: '70%' }}>
        <View style={{ flexGrow: 1, alignItems: 'center' }}>
          <View
            style={{
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'space-between',
              paddingTop: 10,
              width: '100%',
            }}
          >
            <ProfileImage
              inverted
              uri={listing?.imageUrl}
              name={listing?.name}
              size={200}
              isDoyles={listing?.isDoyles || profileDoyles}
            />
          </View>

          <View
            style={{
              alignContent: 'center',
              alignItems: 'center',
              paddingBottom: 20,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <Text
              fontFamily="Barlow-Medium"
              style={[FontSize.large, { marginTop: 20, color: 'white', letterSpacing: 1 }]}
            >
              {listing?.name}
            </Text>
            {listingServices !== undefined && listingServices.length > 0 && (
              <Text
                fontFamily="Barlow-Medium"
                style={[FontSize.medium, { marginTop: 11, color: 'white', letterSpacing: 0.83, textAlign: 'center' }]}
              >
                {listingServices}
              </Text>
            )}
            {parentOrg && (
              <TouchableOpacity
                activeOpacity={0.8}
                onPress={() => linkTo({ screen: 'ViewOrganisation', params: { id: parentOrg.id } })}
              >
                <Text
                  fontFamily="Barlow-Medium"
                  style={{ color: 'white', fontSize: 18, letterSpacing: 1, opacity: 0.8, marginTop: 7 }}
                >
                  {parentOrg.name} &gt;
                </Text>
              </TouchableOpacity>
            )}
          </View>
          <View
            style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', marginBottom: 30 }}
          >
            {!StringIsUndefinedOrEmpty(phone) && !userIsTheOwner && (
              <View style={{ alignItems: 'center', alignContent: 'center', paddingLeft: 20, paddingRight: 20 }}>
                <TouchableOpacity
                  onPress={() => {
                    logAnalyticsEvent('call_pressed', {
                      listingId: listing?.id!
                    })
                    Linking.openURL('tel:' + phone)
                  }}
                  style={[Style.shadow, stylesheet.button]}
                >
                  <Image source={callIcon} style={{ height: 29, width: 29 }} />
                </TouchableOpacity>
                <Text fontFamily="Barlow-SemiBold" style={stylesheet.topButton}>
                  CALL
                </Text>
              </View>
            )}

            {!userIsTheOwner && (
              <View style={{ alignItems: 'center', alignContent: 'center', paddingLeft: 20, paddingRight: 20 }}>
                <TouchableOpacity
                  testID="messageButton"
                  onPress={() => linkTo({ screen: 'SendMessage', params: { id: listing.id } })}
                  style={[Style.shadow, stylesheet.button]}
                >
                  <Image source={require('../../assets/images/iconMessage@3x.png')} style={{ height: 20, width: 29 }} />
                </TouchableOpacity>
                <Text fontFamily="Barlow-SemiBold" style={stylesheet.topButton}>
                  MESSAGE
                </Text>
              </View>
            )}
          </View>
          <View style={[Style.footer, { flexGrow: 1, paddingBottom: useSafeBottomPadding() }]}>
            {listing?.hourlyRate || listing?.dailyRate || listing?.reportCost ? (
              <View style={{ borderBottomColor: Colour.Grey, borderBottomWidth: 2, flexDirection: 'row' }}>
                {listing?.hourlyRate ? (
                  <View style={[stylesheet.priceCell, stylesheet.priceCellRightBorder]}>
                    <Text style={stylesheet.price}>${listing?.hourlyRate}</Text>
                    <Text style={stylesheet.priceItem}>/hour</Text>
                    <Text style={stylesheet.priceItem}>(ex GST)</Text>
                  </View>
                ) : null}
                {listing?.dailyRate ? (
                  <View style={[stylesheet.priceCell, stylesheet.priceCellRightBorder]}>
                    <Text style={stylesheet.price}>${listing?.dailyRate}</Text>
                    <Text style={stylesheet.priceItem}>/day</Text>
                    <Text style={stylesheet.priceItem}>(ex GST)</Text>
                  </View>
                ) : null}
                {listing?.reportCost ? (
                  <View style={[stylesheet.priceCell, stylesheet.priceCellRightBorder]}>
                    <Text style={stylesheet.price}>${listing?.reportCost}</Text>
                    <Text style={stylesheet.priceItem}>/report</Text>
                    <Text style={stylesheet.priceItem}>(ex GST)</Text>
                  </View>
                ) : null}

                {!userIsTheOwner && (
                  <View
                    style={{
                      flexGrow: 1,
                      justifyContent: 'end',
                      flexDirection: 'row',
                      alignItems: 'center',
                      paddingRight: 15,
                    }}
                  >
                    <FavoriteButton
                      isFavorite={favoriteListingIds.includes(listing?.id!)}
                      onClick={() => dispatch(toggleFavorite(listing?.id!))}
                    />
                  </View>
                )}
              </View>
            ) : null}

            <View style={{ flex: 1, paddingHorizontal: 20, paddingTop: 16, paddingBottom: 16 }}>
              {listingSpecialisations !== undefined && listingSpecialisations.length > 0 ? (
                <>
                  <Text fontFamily="Barlow-Medium" style={{ color: Colour.DarkBlue, fontSize: 16, letterSpacing: 0.8 }}>
                    Specialisation(s):
                  </Text>
                  <Text style={{ color: Colour.DarkGrey, fontSize: 16, letterSpacing: 0.8 }}>
                    {listingSpecialisations}
                  </Text>
                </>
              ) : null}

              {listingLocations !== undefined && listingLocations.length > 0 ? (
                <View style={{ marginTop: 9, flexDirection: 'row', alignItems: 'center' }}>
                  <Image source={iconLocation} style={{ marginRight: 12, width: 13, height: 16 }} />
                  <Text style={{ color: '#757575', fontSize: 16, letterSpacing: 0.67 }}>{listingLocations}</Text>
                </View>
              ) : null}
              {(listing?.isDoyles || profileDoyles) && listing?.doylesImageUrls?.length ? (
                <DoylesImageList data={listing?.doylesImageUrls} />
              ) : null}

              {listing?.accreditations && (
                <>
                  <Text
                    fontFamily="Barlow-Medium"
                    style={{ color: Colour.DarkBlue, fontSize: 16, letterSpacing: 1, marginVertical: 8 }}
                  >
                    Accreditations
                  </Text>

                  <Text style={{ color: Colour.DarkGrey, fontSize: 16, letterSpacing: 0.8 }}>{listing?.accreditations}</Text>
                </>
              )}

              <Text
                fontFamily="Barlow-Medium"
                style={{ color: Colour.DarkBlue, fontSize: 24, letterSpacing: 1, marginVertical: 8 }}
              >
                Bio
              </Text>

              <Text style={{ color: Colour.DarkGrey, fontSize: 16, letterSpacing: 0.8 }}>{listing?.bio}</Text>
            </View>


            <PrimaryButton
              containerStyle={{ paddingHorizontal: 20 }}
              title="Check Calendar"
              onPress={() => linkTo({
                screen: 'Calendar', params: {
                  profileId: listing?.profileId,
                  id: listing?.id
                }
              })}
              icon={<CalendarMonthIcon style={{ marginRight: 10, color: 'white' }} />}
            />
          </View>
        </View>
      </div>
    </LoginBase>
  );
};

export default ViewListing;
