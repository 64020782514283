import moment from 'moment';
import { Asset } from 'react-native-image-picker';
import { Listing } from '../redux/User';
import { Collection, Collections, Document, ProfileCollection, SaveImage, auth, firestore } from './Firebase';
import { collection, doc, getDoc, getDocs, query, setDoc, where } from 'firebase/firestore/lite';
import { captureException } from '@sentry/react';

// Models
export interface OrgListing {
  id?: string;
  profileId?: string;
  name: string;
  services?: string[];
  streetAddress?: string;
  email?: string;
  phone?: string;
  bio?: string;
  imageUrl?: string;
}

// Collection and Document helpers
const ListingCollection = Collection(Collections.Listings);
export const ListingDoc = (docId?: string) => Document(Collections.Listings, docId);

export const GetListing = (listingId: string) =>
  new Promise<Listing>(resolve => {
    // Set up document connections
    getDoc(ListingDoc(listingId))
      .then(result => {
        let toRet = result.data() as Listing;
        toRet.id = listingId;
        resolve(toRet);
      })
      .catch(error => {
        alert('Error getting listing')
        captureException(error)
      });
  });

export const GetListingByProfile = (profileId: string) => {
  const q = query(ListingCollection, where('profileId', '==', profileId));
  return getDocs(q).then(snapshot => {
    if (snapshot.size > 0) {
      const doc = snapshot.docs[0];
      const data = doc.data();
      data.id = doc.id;
      return data;
    }
    throw new Error('Query returned no profiles');
  });
};

export const SaveIndividual = async (listing: Listing, imageData?: Asset, newlyAddedDoylesImages?: Asset[]) => {
  return await SaveListing(listing, imageData, newlyAddedDoylesImages);
};

export const SaveOrg = async (org: OrgListing, imageData?: Asset) => {
  return await SaveListing({ ...org, isOrg: true } as Listing, imageData);
};

const SaveListing = async (listing: Listing, imageData?: Asset, newlyAddedDoylesImages?: Asset[]) => {
  // Get listing doc
  const newListingsRef =
    listing?.id && listing?.id.length
      ? doc(collection(firestore, Collections.Listings), listing.id)
      : doc(collection(firestore, Collections.Listings));


  // Prepare data
  let data: Listing = {
    ...listing,
    profileId: auth.currentUser?.uid,
  };

  if (listing.id) {
    data.dateModified = moment().toISOString();
  } else {
    data.dateCreated = moment().toISOString();
  }

  // If we need to upload an image
  if (imageData) {
    await SaveImage(newListingsRef.id, imageData).then(url => {
      // Insert image url into data
      data.imageUrl = url;
    });
  }

  if (newlyAddedDoylesImages && newlyAddedDoylesImages.length) {
    let doylesImagesData = [];
    const randomValue = Math.floor(Math.random() * 100000).toString();
    const imageId = listing.id + randomValue;
    const promiseUploadDoylesImage = newlyAddedDoylesImages.map(async image => await SaveImage(imageId, image, true));
    const result = await Promise.all(promiseUploadDoylesImage);

    if (result && result.length) {
      data.doylesImageUrls = [...(data.doylesImageUrls ?? []), ...result];
    }
  }

  // Update the listing
  await setDoc(newListingsRef, data, { merge: true });

  return {
    ...data,
    id: newListingsRef.id,
  } as Listing;
};

export const GetListings = (listingIds: string[]) =>
  new Promise<Listing[]>(resolve => {
    // Set up document connections
    const docs = listingIds.map(async id => await getDoc(Document(Collections.Listings, id)));
    // Wait for all documents to load
    Promise.all(docs)
      .then(snapshots => {
        const listings = snapshots.map(doc => ({ ...doc.data(), id: doc.id }) as Listing);
        resolve(listings);
      })
      .catch(error => {
        alert('Error getting listings')
        captureException(error)
      });
  });

export const GetListingsForOrg = (id: string) =>
  new Promise<Listing[]>(resolve => {
    let final: Listing[] = [];

    const q = query(ProfileCollection, where('parentProfileId', '==', id));

    getDocs(q).then(pResult => {
      const profiles = pResult.docs;

      let listingPromises = profiles.map(p => {
        return getDocs(query(ListingCollection, where('profileId', '==', p.id)));
      });

      Promise.all(listingPromises).then(result => {
        result.forEach(l => {
          l.docs.forEach(a => {
            let b = a.data();
            b.id = a.id;
            final.push(b as Listing);
          });
        });

        resolve(final);
      });
    });
  });
