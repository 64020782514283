import React, { FunctionComponent } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Colour } from '../styles/style';

const Chip: FunctionComponent<{
  onDelete?: () => void;
  chipStyle?: StyleProp<ViewStyle>;
}> = props => {
  return (
    <View
      style={{
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'row',
      }}
    >
      <View
        style={[
          {
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 10,
            paddingVertical: 7,
            margin: 3,
            borderColor: Colour.Blue,
            borderRadius: 20,
            borderWidth: 1,
          },
          props.chipStyle,
        ]}
      >
        {props.children}
        {props.onDelete && (
          <CloseOutlinedIcon sx={{ marginLeft: 0.25, height: 15, width: 15, color: 'grey' }} onClick={props.onDelete} />
        )}
      </View>
    </View>
  );
};

export default Chip;
