import React, { useState } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { Asset } from 'react-native-image-picker';
import { ProfileImage } from './ProfileImage';

const MAX_FILE_SIZE = 5120;

interface Props {
  image?: Asset;
  imageUrl?: string;
  onSetImage(image: Asset): void;
  style?: StyleProp<ViewStyle>;
}

const ModalChooseAvatar = (props: Props) => {
  const { image, imageUrl, onSetImage, style } = props;

  const [errorMessage, setErrorMessage] = useState<String | null>(null);
  const handleImageInput = e => {
    const file = e.target.files[0];
    const sizeInKiloByytes = file.size / 1024;
    if (sizeInKiloByytes > MAX_FILE_SIZE) {
      setErrorMessage('File too big');
    }
    file.uri = URL.createObjectURL(file);
    onSetImage(file);
  };

  const imageInputRef = React.useRef(null);

  return (
    <View style={[{ alignItems: 'center' }, style]}>
      <View
        style={{ alignItems: 'center', cursor: 'pointer' }}
        onClick={e => {
          imageInputRef?.current?.click();
        }}
      >
        <ProfileImage uri={imageUrl} image={image} size={135} />
        <input
          ref={imageInputRef}
          accept="image/*"
          type="file"
          style={{ visibility: 'hidden' }}
          onChange={handleImageInput}
        />
      </View>
    </View>
  );
};

export default ModalChooseAvatar;
