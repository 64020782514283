import { useLinkTo } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { Alert, Image, SectionList, View } from 'react-native';
import { Divider, ListItem } from 'react-native-elements';
import { useDispatch, useSelector } from 'react-redux';
import tick from '../../assets/images/iconTickBlue@3x.png';
import { PrimaryButton } from '../../components/Button';
import { useSafeBottomPadding } from '../../components/Hooks';
import Text from '../../components/Text';
import { ChooseCalendarScreenProps } from '../../navigators/Types';
import { Calendar, updateSelectedCalendar } from '../../redux/Calendar';
import { calendarStateSelector, userStateSelector } from '../../redux/Store';
import { CalendarError, deleteMobileCalendar } from '../../services/CalendarService';
import { CallFunction, auth, logAnalyticsEvent } from '../../services/Firebase';
import Style, { Colour } from '../../styles/style';
import LoginBase from '../auth/LoginBase';
import { Invite, setListing } from '../../redux/User';
import { GetInvitation } from '../../services/InvitationService';
import { GetListing } from '../../services/ListingService';
import { captureException } from '@sentry/react';

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const ChooseCalendarScreen = ({ route, navigation }: ChooseCalendarScreenProps) => {
  const dispatch = useDispatch();
  const linkTo = useLinkTo();
  const calendarState = useSelector(calendarStateSelector);
  const userState = useSelector(userStateSelector);
  const [calendarList, setCalendarList] = useState<Calendar[]>([]);
  const [selected, setSelected] = useState<Calendar | undefined>(calendarState.selectedCalendar);

  useEffect(() => {
    setSelected(calendarState.selectedCalendar);
  }, [calendarState.selectedCalendar]);
  const [loadingError, setLoadingError] = useState<{ message: string, retry: () => {} }>();
  const [showNotReadyMessage, setShowNotReadyMessage] = useState(false)
  const [busy, setBusy] = useState(false);
  const [runOnce, setRunOnce] = useState(false);
  const [invite, setInvite] = useState<Invite>()
  const safeBottomPadding = useSafeBottomPadding();
  const nylasCode = route?.params?.code;

  useEffect(() => {
    const getInv = async () => {
      if (route?.params?.state) {
        const invitation = await GetInvitation(route?.params?.state)
        setInvite(invitation)
      }
    }
    getInv()
  }, [route?.params?.state])
  const authorise = async () => {
    // User needs to select a calendar
    setBusy(true);
    const response = (await CallFunction('getNylasAuthLink', {
      email: auth.currentUser?.email,
    })) as string;
    //Redirect to the pyas hosted auth
    window.location.replace(response);
  };

  const handleSubmit = async () => {
    // User needs to select a calendar
    if (selected) {
      setBusy(true);
      // This saves the calender to Firebase for future reference but does nothing with the events

      //Refresh listing to get updated fields
      GetListing(userState.listing?.id!).then(listing => {
        return dispatch(setListing(listing))
      }).then(() => {
        dispatch(updateSelectedCalendar(selected))
          .then(() => {
            logAnalyticsEvent('calendar_connected', {})
            //Remove their mobile calendar
            deleteMobileCalendar()

            //Do a first time sync
            CallFunction('syncCalendar', {}).then((events) => {
              if (!events?.length) {
                logAnalyticsEvent('calendar_synced_with_no_events', {
                  listingId: userState.listing?.id!,
                  calendarId: selected.id
                })
              }
            })
            if (route?.params?.state) {
              //Navigate to invite accepted
              linkTo({
                screen: 'AcceptInvitation',
                params: { ...invite, allDone: true }
              })
            }
            else if (navigation.canGoBack()) {
              navigation.goBack();
            } else {
              linkTo({
                screen: 'CreateListing',
                params: {
                  id: userState.listing?.id,
                  skipToStep: 3
                },
              });
            }
          })
          .catch((error: Error) => {
            captureException(error)
          });
      })
    } else {
      Alert.alert('Please select a calendar');
    }
    setBusy(false);
  };

  useEffect(() => {
    const connectGoogleAccount = async () => {
      try {
        //&& userState.listing
        if (nylasCode && !runOnce && !userState?.listing?.nylasConnected) {
          setRunOnce(true);
          setBusy(true)
          await CallFunction('exchangeMailboxToken', {
            token: nylasCode,
          });
          //Nylas takes time to sync. It doesn't apppear to be accurate
          //it gets to sync_state 'valid' or 'running' before the calendars are truly synced...
          //so let's jst wait a bit... just in case it finishes slightl quickly
          //otherwise we go through the flow where we email the user
          await sleep(5000)
          await getCalendars(true);
          setBusy(false)
        }
      } catch (e) {
        setLoadingError({ message: 'Error connecting your calendar', retry: authorise })
        setBusy(false)
      }
    };

    connectGoogleAccount();
  }, [nylasCode, userState.listing]);

  const getCalendars = async (force = false) => {
    try {
      if (force || (!calendarList.length && userState?.listing?.nylasConnected)) {
        setBusy(true);
        const calendars = await CallFunction('getCalendars', {});
        if (!calendars.ready) {
          setShowNotReadyMessage(true)
          setLoadingError(null)
          setBusy(false);
        } else {
          setCalendarList(calendars.calendars);
          setShowNotReadyMessage(false)
          setLoadingError(null)
          setBusy(false);

        }
      }
    } catch (e) {
      setLoadingError({ message: 'Error loading your calendars. Please try again.', retry: getCalendars })
      setBusy(false);
    }
  };

  useEffect(() => {
    getCalendars();
  }, [userState.listing]);

  return (
    <LoginBase>
      <View style={[Style.footer, { backgroundColor: 'rgba(0, 0, 0, 0)', justifyContent: 'center' }]}>
        <View style={{ backgroundColor: 'white', borderRadius: 24, padding: 30 }}>
          {loadingError &&
            <>
              <Text style={[Style.errorText, { textAlign: 'center' }]}>{loadingError.message}</Text>
              <PrimaryButton
                loading={busy}
                containerStyle={{ marginBottom: safeBottomPadding }}
                buttonStyle={{ marginHorizontal: 20 }}
                title="Try again"
                disabled={busy}
                onPress={() => loadingError.retry()}
              />
            </>
          }
          {showNotReadyMessage &&
            <>
              <Text style={[Style.text, { textAlign: 'center' }]}>
                We're just getting your calendars ready. We'll send you an email when they are ready.
              </Text>
              <PrimaryButton
                loading={busy}
                containerStyle={{ marginBottom: safeBottomPadding }}
                buttonStyle={{ marginHorizontal: 20 }}
                title="CONFIRM"
                disabled={busy}
                onPress={() => {
                  linkTo({
                    screen: 'CreateListing',
                    params: {
                      id: userState.listing?.id,
                      skipToStep: 3
                    },
                  });
                }}
              />
            </>}
          {(!userState.listing?.nylasConnected && !calendarList?.length) && !loadingError && (
            <>
              <Text style={{ textAlign: 'center', marginBottom: 10 }} fontFamily={'Barlow-Medium'}>
                Please connect your work email/calendar account
              </Text>
              <PrimaryButton
                loading={busy}
                title={!!userState.listing?.nylasConnected ? 'Connected' : 'Connect'}
                disabled={!!userState.listing?.nylasConnected}
                containerStyle={{ marginVertical: 0, borderRadius: 0 }}
                buttonStyle={{ borderRadius: 0 }}
                onPress={() => authorise()}
              />
            </>
          )}
          {(userState.listing?.nylasConnected || !!calendarList?.length) && !loadingError && !showNotReadyMessage && (
            <>
              <SectionList
                contentContainerStyle={{ flexGrow: 1 }}
                sections={[{ title: 'Calendars', data: calendarList }]}
                renderSectionHeader={({ section }) => (
                  <View>
                    <Text
                      fontFamily="Barlow-Medium"
                      style={{
                        backgroundColor: 'white',
                        color: Colour.DarkBlue,
                        letterSpacing: 1,
                        fontSize: 24,
                        padding: 15,
                      }}
                    >
                      {section.title}
                    </Text>
                  </View>
                )}
                renderItem={({ item }) => (
                  <ListItem
                    style={{ marginLeft: 20 }}
                    bottomDivider
                    onPress={() => {
                      setSelected(item);
                    }}
                    hasTVPreferredFocus={undefined}
                    tvParallaxProperties={undefined}
                  >
                    <View
                      style={{
                        width: 30,
                        height: 30,
                        borderRadius: 15,
                        backgroundColor: Colour.Grey,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {item.id === selected?.id && (
                        <Image source={tick} width={51} height={48} style={{ width: 21.5, height: 20 }} />
                      )}
                    </View>
                    <ListItem.Content>
                      <ListItem.Title>
                        <Text
                          fontFamily="Barlow-Medium"
                          style={{ fontSize: 18, color: Colour.DarkGrey, letterSpacing: 0.9 }}
                        >
                          {item.title}
                        </Text>
                      </ListItem.Title>
                    </ListItem.Content>
                  </ListItem>
                )}
                ListEmptyComponent={
                  <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                    <Text>No calendars are available.</Text>
                  </View>
                }
              />
              <PrimaryButton
                loading={busy}
                containerStyle={{ marginBottom: safeBottomPadding, width: 'auto' }}
                buttonStyle={{ marginHorizontal: 20, marginTop: 20 }}
                title="CHOOSE A DIFFERENT ACCOUNT"
                disabled={!selected || busy}
                onPress={() => {
                  setBusy(true)
                  CallFunction('cancelNylasAccount', {
                  }).then(() => {
                    authorise()
                  })
                }}
              />
              <PrimaryButton
                loading={busy}
                containerStyle={{ marginBottom: safeBottomPadding }}
                buttonStyle={{ marginHorizontal: 20 }}
                title="CONFIRM"
                disabled={!selected || busy}
                onPress={handleSubmit}
              />
            </>
          )}
        </View>
      </View>
    </LoginBase>
  );
};

export default ChooseCalendarScreen;
