import React from 'react';
import { FlatList, View, Image } from 'react-native';

interface DoylesImageListProps {
  data: string[];
}

const renderDoylesImage = ({ item }: { item: string }) => {
  return (
    <View
      style={{
        width: 100,
        height: 100,
        borderRadius: 50,
        overflow: 'hidden',
        marginRight: 6,
      }}
    >
      <Image source={{ uri: item }} style={{ width: '100%', height: '100%', resizeMode: 'cover' }} />
    </View>
  );
};

function DoylesImageList({ data }: DoylesImageListProps) {
  return (
    <View style={{ marginTop: 20 }}>
      <FlatList
        showsHorizontalScrollIndicator={false}
        horizontal
        data={data}
        renderItem={renderDoylesImage}
        keyExtractor={item => item}
      />
    </View>
  );
}

export default DoylesImageList;
