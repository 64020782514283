import { Action } from 'redux';
import { ThunkPromise } from './Store';
import { getLocations, getServices } from '../services/PickListService';

// Models
export interface PickSection {
  title: string;
  data: string[];
}

export interface NSelectItem {
  name: string;
  id: string;
  child?: NSelectItem[];
  detail?: string;
}

export const getCategoryName = (id: string, items: NSelectItem[]) => {
  if (id.length === 0) {
    return '';
  }

  const searchId = id.split('.')[0];
  const found = items.find(item => item.id === searchId);
  if (found !== undefined) {
    return found.name;
  }
  return '';
};

export const getNameFromMultiIndex = (
  id: string,
  items: NSelectItem[],
  onlyItemName: boolean = false,
  depth?: number,
) => {
  if (id.length === 0) {
    return '';
  }

  let name = '';
  const split = id.split('.');
  const searchDepth = depth ?? 1;

  const searchId = split.slice(0, searchDepth).join('.');
  const found = items.find(item => item.id === searchId);
  if (found !== undefined) {
    name = found.name;
    if (found.child !== undefined && split.length > searchDepth) {
      name += ` - ${getNameFromMultiIndex(id, found.child!, onlyItemName, searchDepth + 1)}`;
    }
  }
  if (onlyItemName) {
    const endName = name.split('- ');
    return endName[endName.length - 1];
  }
  return name;
};

// ACTION TYPES
export enum ActionType {
  SET_SERVICES = 'SET_SERVICES',
  SET_LOCATIONS = 'SET_LOCATIONS',
}

interface PickListsAction extends Action<ActionType> {
  services?: NSelectItem[];
  locations?: NSelectItem[];
}

// ACTIONS
export const setServices = (services: NSelectItem[]): PickListsAction => ({
  type: ActionType.SET_SERVICES,
  services,
});
export const setLocations = (locations: NSelectItem[]): PickListsAction => ({
  type: ActionType.SET_LOCATIONS,
  locations,
});

// THUNKS
export const fetchServices = (): ThunkPromise => (dispatch, getState) => {
  // Initially set values from data we packed with the app
  // dispatch(setServices(serviceList));
  // Get updated data from firebase
  return getServices().then((services) => {
    dispatch(setServices(services.map(doc => {
      const docData = doc.data()
      const specialisations = docData.child as NSelectItem[];
      const formattedDoc = {
        id: docData.id,
        name: docData.name,
      }
      if (specialisations?.length) {
        formattedDoc.child = specialisations
      }
      return formattedDoc;
    })));
  })
  // Services.get().then(snapshot => {
  //     if (snapshot) {
  //         dispatch(setServices(snapshot.docs.map(doc => {
  //             const specialisations = doc.data().data as string[];

  //             return ({
  //                 title: doc.id,
  //                 data: specialisations
  //             });
  //         })));
  //     }
  // }).catch(err => console.log('services get', err));
}
export const fetchLocations = (): ThunkPromise => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    // Initially set values from data we packed with the app
    // dispatch(setLocations(locationList));
    return getLocations().then((locations) => {
      dispatch(setLocations(locations.map(doc => {
        const docData = doc.data();
        const locations = docData.child as string[];
        const formattedDoc = {
          id: docData.id,
          name: docData.name,
        }
        if (locations?.length) {
          formattedDoc.child = locations
        }
        return formattedDoc;
      })));
    })
    // Get updated data from firebase
    // Locations.get().then(snapshot => {
    //     if (snapshot) {
    //         dispatch(setLocations(snapshot.docs.map(doc => {
    //             const locations = doc.data().locations as string[];

    //             return ({
    //                 title: doc.id,
    //                 data: locations
    //             });
    //         })));
    //         resolve();
    //     } else {
    //         reject();
    //     }
    // }).catch(err => {
    //     console.log('locations get', err);
    //     reject();
    // });
  });

// STATE
interface PickListsState {
  services: NSelectItem[];
  locations: NSelectItem[];
}

const initialState: PickListsState = {
  services: [],
  locations: [],
};

// REDUCER
const reducer = (state = initialState, action: PickListsAction): PickListsState => {
  switch (action.type) {
    case ActionType.SET_SERVICES:
      return { ...state, services: action.services! };
    case ActionType.SET_LOCATIONS:
      return { ...state, locations: action.locations! };
    default:
      return state;
  }
};
export default reducer;
