import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { View } from 'react-native';
import { Icon, Input } from 'react-native-elements';
import { PrimaryButton } from '../../../components/Button';
import { emailValidationRx, FormError } from '../../../components/FormHelper';
import { UserType } from '../../../redux/Register';
import Style from '../../../styles/style';
import { useSelector } from 'react-redux';
import { registerStateSelector } from '../../../redux/Store';
import MailIcon from '@mui/icons-material/MailOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import CheckBox from '../../../components/Checkbox';
import { Link } from '../../../components/TextHelper';
import { ISignUpForm } from '../utils/model';
import Text from '../../../components/Text';
import ReCAPTCHA from 'react-google-recaptcha';
import config from '../../../config.json';

interface Props {
  suggestedEmailAddress?: string;
  suggestedName?: string;
  loading: boolean;
  errorMessage: string;
  onSubmit(values: ISignUpForm): void;
  forceEmail?: boolean;
  setCheckRecaptch(value: { isrecaptchaError: boolean; isRecaptchaToken: boolean }): void;
  checkRecaptch: {
    isrecaptchaError: boolean;
    isRecaptchaToken: boolean;
  };
}

const SignUpForm = (props: Props) => {
  const {
    suggestedEmailAddress,
    forceEmail,
    suggestedName,
    loading,
    errorMessage,
    onSubmit,
    setCheckRecaptch,
    checkRecaptch,
  } = props;
  const { userType: type } = useSelector(registerStateSelector);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ISignUpForm>({
    defaultValues: { email: suggestedEmailAddress, password: '', name: suggestedName, agree: false },
  });

  const [hidePassword, setHidePassword] = useState<boolean>(true);

  const onChangeRecaptche = () => {
    setCheckRecaptch({
      isrecaptchaError: false,
      isRecaptchaToken: true,
    });
  };

  return (
    <View style={[Style.container, { flexDirection: 'column', justifyContent: 'space-between' }]}>
      <Controller
        control={control}
        name="name"
        rules={{
          required: { value: true, message: 'Name is required' },
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Input
            value={value}
            testID="nameInputField"
            onChangeText={onChange}
            errorMessage={error?.message}
            placeholder={type === UserType.Individual ? 'Full Name' : 'Organisation Name'}
            returnKeyType="next"
            autoCapitalize="none"
          />
        )}
      />

      <Controller
        control={control}
        name="email"
        rules={{
          required: { value: true, message: 'Email is required' },
          pattern: { value: emailValidationRx, message: 'Email is not valid' },
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Input
            value={value}
            testID="emailInputField"
            onChangeText={onChange}
            disabled={forceEmail && !!suggestedEmailAddress?.length}
            errorMessage={error?.message}
            placeholder="Email"
            keyboardType="email-address"
            returnKeyType="next"
            autoCapitalize="none"
            leftIcon={<MailIcon />}
          />
        )}
      />

      <Controller
        control={control}
        name="password"
        rules={{
          required: { value: true, message: 'Password is required' },
          minLength: { value: 8, message: 'Password must be 8 characters long' },
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Input
            value={value}
            onChangeText={onChange}
            errorMessage={error?.message}
            testID="passwordInputField"
            placeholder="Password"
            secureTextEntry={hidePassword}
            autoCapitalize="none"
            leftIcon={<LockOutlinedIcon />}
            rightIcon={
              hidePassword ? (
                <VisibilityOutlinedIcon onClick={() => setHidePassword(v => !v)} />
              ) : (
                <VisibilityOffOutlinedIcon onClick={() => setHidePassword(v => !v)} />
              )
            }
          />
        )}
      />

      <Controller
        control={control}
        name="agree"
        rules={{
          required: { value: true, message: 'You must agree to the Terms & Conditions' },
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <CheckBox
            value={value}
            onChange={onChange}
            testID="termsInputField"
            error={error}
            textElement={
              <Text style={{ paddingRight: 64 }}>
                I agree to the
                <Link link="https://auslawconcierge.com.au/app/terms-and-conditions/">
                  &nbsp; Terms &amp; Conditions
                </Link>{' '}
                and&nbsp;
                <Link link="https://auslawconcierge.com.au/app/privacy-policy/">Privacy Policy</Link>
                &nbsp;and that Concierge may transmit phone number and installed application information to perform
                services.
              </Text>
            }
          />
        )}
      />
      <Text style={{ color: 'red', paddingLeft: 16 }}>{errors.agree?.message || ' '}</Text>

      <ReCAPTCHA style={{ marginTop: '10px' }} onChange={onChangeRecaptche} sitekey={config.siteKeyRecaptcha} />
      {checkRecaptch.isrecaptchaError && <Text style={{ color: 'red' }}>Confirm your identity verification</Text>}

      {FormError(errorMessage)}

      <PrimaryButton
        loading={loading}
        testID="signupButton"
        disabled={loading}
        title="SIGN UP"
        onPress={handleSubmit(onSubmit)}
      />
    </View>
  );
};

export default SignUpForm;
