import { Action } from 'redux';

// MODELS
export enum UserType {
  Individual = 'individual',
  Organisation = 'organisation',
}

export interface RegisterModel {
  name: string;
  email: string;
  password: string;
  userType: UserType;
}

// ACTION TYPES
export enum ActionType {
  SET_TYPE = 'SET_TYPE',

  RESET = 'USER_RESET',
}

interface RegisterAction extends Action<ActionType> {
  userType?: UserType;
}

// ACTIONS
export const setType = (userType: UserType): RegisterAction => ({
  type: ActionType.SET_TYPE,
  userType,
});

// STATE
interface RegisterState {
  userType: UserType;
}

const initialState: RegisterState = {
  userType: UserType.Individual,
};

// REDUCER
const reducer = (state = initialState, action: RegisterAction): RegisterState => {
  switch (action.type) {
    case ActionType.SET_TYPE:
      return { ...state, userType: action.userType! };
    case ActionType.RESET:
      return initialState;
    default:
      return state;
  }
};
export default reducer;
