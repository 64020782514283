import React, { useState } from 'react';
import { FlatList, StyleProp, View, ViewStyle } from 'react-native';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import Text from '../components/Text';
import { FontColour } from '../styles/style';
import ModalBase from './ModalBase';

export interface ModalPickerItem {
  title: string;
  disabled: boolean;
}

interface ModalPickerProps {
  selectionText: string;
  selectedIndex: number;
  list: ModalPickerItem[];
  onChange: (newValue: number) => void;

  buttonContainerStyle?: StyleProp<ViewStyle>;
}

const ModalPicker = (props: ModalPickerProps) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <ModalBase
      buttonContainerStyle={props.buttonContainerStyle}
      isVisible={showModal}
      setVisible={setShowModal}
      buttonContent={
        <>
          <Text
            fontFamily="Barlow-Medium"
            style={[
              {
                flexGrow: 1,
                color: 'white',
                alignSelf: 'center',
                textAlignVertical: 'center',
              },
            ]}
          >
            {props.selectionText}
          </Text>
          <Text
            fontFamily="Barlow-Medium"
            style={[
              {
                color: 'white',
                alignSelf: 'center',
                textAlignVertical: 'center',
              },
            ]}
          >
            {props.list[props.selectedIndex].title}
          </Text>
          <div style={{ transform: [{ rotate: '90deg' }] }}>
            <KeyboardArrowRightIcon sx={{ color: 'white' }} />
          </div>
        </>
      }
      modalElement={
        <FlatList
          data={props.list}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item, index }) => (
            <View
              onClick={() => {
                props.onChange(index);
                setShowModal(false);
              }}
              style={{cursor: 'pointer'}}
            >
              <View style={{ backgroundColor: item.disabled ? 'grey' : undefined }}>
                <Text
                  fontFamily="Barlow-Medium"
                  style={[FontColour.darkBlue, { textAlign: 'center', marginVertical: 10 }]}
                >
                  {item.title}
                </Text>
              </View>
            </View>
          )}
        />
      }
    />
  );
};

export default ModalPicker;
