import React from 'react';
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native';
import Style from '../styles/style';
import Text from './Text';
import Checkbox from '@mui/material/Checkbox';
import { FieldError } from 'react-hook-form';

interface CheckboxProps {
  text?: string;
  textElement?: React.ReactElement<{}>;
  value?: boolean;
  onChange: (newValue: boolean) => void;
  disabled?: boolean;
  error?: FieldError;
  containerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  testID: string;
}

const CheckBox = (props: CheckboxProps) => {
  return (
    <View style={[Style.rowFixed, Style.rowCenter, { marginVertical: 10 }, props.containerStyle]}>
      <Checkbox {...props} required />
      {props.text !== undefined ? (
        <Text
          onPress={() => props.onChange(!props.value)}
          testID={props.testID}
          style={[{ textAlignVertical: 'center', flex: 1, flexWrap: 'wrap' }, props.textStyle]}
        >
          {props.text}
        </Text>
      ) : (
        props.textElement !== undefined && props.textElement
      )}
    </View>
  );
};

export default CheckBox;
