import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { Keyboard, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { TextButton } from '../../components/Button';
import { useSafeBottomPadding } from '../../components/Hooks';
import ScrollView from '../../components/ScrollView';
import Text from '../../components/Text';
import { SignupScreenProps } from '../../navigators/Types';
import { registerStateSelector } from '../../redux/Store';
import { setProfile } from '../../redux/User';
import { GetAuthErrorMessage, ProfileDocument, Register } from '../../services/Firebase';
import { default as style, FontSize } from '../../styles/style';
import SignUpForm from './components/SignUpForm';
import LoginBase from './LoginBase';
import { ISignUpForm } from './utils/model';
import { setDoc } from 'firebase/firestore/lite';
import { captureException } from '@sentry/react';

const SignupScreen = ({ route, navigation, suggestedEmailAddress }: SignupScreenProps) => {
  const { userType: type } = useSelector(registerStateSelector);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(' ');
  const [checkRecaptch, setCheckRecaptch] = useState({
    isrecaptchaError: false,
    isRecaptchaToken: false,
  });

  const onSignUp = useCallback(
    (values: ISignUpForm) => {
      if (!checkRecaptch.isRecaptchaToken) {
        setCheckRecaptch({
          isrecaptchaError: true,
          isRecaptchaToken: false,
        });
        return;
      }

      Keyboard.dismiss();
      setErrorMessage(' ');
      setLoading(true);

      Register({
        ...values,
        email: values.email.trim(),
        userType: type,
      })
        .then(res => {
          // Set up firestore profile data
          const data = {
            userType: type,
            name: values.name,
            email: res.user.email!,
            dateCreated: moment().toISOString(),
          };
          setDoc(ProfileDocument(res.user.uid), data);
          // Not fetched by our auth state listener, so dispatch new profile data to store
          dispatch(setProfile(data));

          // Signup complete screen
          navigation.reset({
            index: 0,
            routes: [{ name: 'SignupComplete' }],
          });
        })
        .catch(reason => {
          setErrorMessage(GetAuthErrorMessage(reason.code));
          captureException(reason);
        })
        .then(() => setLoading(false));
    },
    [dispatch, navigation, type, checkRecaptch],
  );

  return (
    <LoginBase hasHeader>
      <View style={style.header}>
        <Text
          style={[
            FontSize.extraLarge,
            {
              color: 'white',
              letterSpacing: 1.5,
              marginTop: 40,
              marginBottom: 100,
            },
          ]}>
          Sign Up
        </Text>
      </View>
      <View style={[style.footer, { maxHeight: 600 }]}>
        <ScrollView
          style={style.scrollview}
          contentContainerStyle={[style.scrollviewContainer, { paddingBottom: useSafeBottomPadding() }]}>
          <SignUpForm
            loading={loading}
            errorMessage={errorMessage}
            suggestedEmailAddress={suggestedEmailAddress}
            onSubmit={onSignUp}
            setCheckRecaptch={setCheckRecaptch}
            checkRecaptch={checkRecaptch}
          />

          <View style={{ alignItems: 'center', marginTop: 20 }}>
            <Text>Already have an account?</Text>
            <TextButton onPress={() => navigation.replace('Login')} text="Log in" />
          </View>
        </ScrollView>
      </View>
    </LoginBase>
  );
};

export default SignupScreen;
