import React from 'react';
import Text from '../components/Text';

// Validators
export const emailValidationRx =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

export const auLandlineRx =
  /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-37-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;

export const auMobileRx =
  /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[45])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;

export const mobilePhoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;

export const ValidateEmail = (email: string) => emailValidationRx.test(email);

export const ValidateLandline = (landline: string) => auLandlineRx.test(landline);

export const ValidateAuMobile = (mobile: string) => auMobileRx.test(mobile);

export const ValidateMobile = (mobile: string) => mobilePhoneRegex.test(mobile);

// Errors
export const FormError = (error: string | undefined) => error && <Text style={{ color: 'red' }}>{error}</Text>;
