// import moment from 'moment';
// import calendar from 'react-native-calendar-events';
// import { CalendarEvent } from '../redux/Calendar';
// import { lexicographicSort } from '../tools/ArrayExtensions';
import { Collections, auth, Document } from './Firebase';
import { deleteDoc } from 'firebase/firestore/lite';

// Selected Calendar
export const SelectedCalendar = () => Document(Collections.SelectedCalendars, auth.currentUser?.uid);

export interface CalendarError {
  status: string;
  message: string;
  alert?: string;
}

export const deleteMobileCalendar = async () => {
  const mobileCalRef = Document(Collections.MobileCalendars, auth.currentUser?.uid);
  return deleteDoc(mobileCalRef)
}

// export const GetCalendarEvents = (calendarId: string) =>
//   new Promise<CalendarEvent[]>((resolve, reject) => {
//     var from = moment().toISOString();
//     var to = moment().add(5, 'years').toISOString();
//     calendar
//       .fetchAllEvents(from, to, [calendarId])
//       .then(calendarEvents => {
//         var events: CalendarEvent[] = calendarEvents.map(event => ({
//           startDate: event.startDate,
//           endDate: event.endDate!,
//         }));

//         // Sort the events by date, and then limit to 10,000
//         var sortedLimitedEvents = lexicographicSort(events, e => e.startDate).slice(0, Math.min(events.length, 10000));

//         resolve(sortedLimitedEvents);
//       })
//       .catch(reject);
//   });
