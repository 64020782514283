import React from 'react';
import { ScrollView, View } from 'react-native';
import Style from '../../../styles/style';
import { useSafeBottomPadding } from '../../../components/Hooks';
import styled from 'styled-components/native';
import { PrimaryButton } from '../../../components/Button';
import { SignOut } from '../../../services/Firebase';
import { useNavigation } from '@react-navigation/native';
import LoginBase from '../../auth/LoginBase';

const ConfirmationCopy = styled.Text`
  font-family: Barlow;
  font-size: 18px;
  font-weight: 500;
  margin-top: 24px;
  margin-bottom: 24px;
`;

const AccountRemovedScreen = () => {
  const navigation = useNavigation();
  return (
    <LoginBase>
      <View style={[Style.footer, { backgroundColor: 'rgba(0, 0, 0, 0)', justifyContent: 'center' }]}>
        <View style={{ backgroundColor: 'white', borderRadius: 24, padding: 30 }}>
          <ConfirmationCopy>We are sorry to see you go.</ConfirmationCopy>
          <ConfirmationCopy>
            Your account has been automatically deleted upon your request and all Profile information has been removed
            from the system.
          </ConfirmationCopy>
          <ConfirmationCopy style={{ marginBottom: 0 }}>Regards,</ConfirmationCopy>
          <ConfirmationCopy style={{ marginTop: 8 }}>The AusLaw Concierge Team</ConfirmationCopy>
          <PrimaryButton
            title="OK"
            onPress={async () => {
              //@ts-ignore
              navigation.replace('Welcome');
            }}
          />
        </View>
      </View>
    </LoginBase>
  );
};

export default AccountRemovedScreen;
