import { Picker } from '@react-native-picker/picker';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { TouchableOpacity, View } from 'react-native';
import { Input } from 'react-native-elements';
import { auLandlineRx, auMobileRx, emailValidationRx } from '../../../components/FormHelper';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Text from '../../../components/Text';
import { Listing } from '../../../redux/User';
import Style, { Barlow, Colour } from '../../../styles/style';
import { IListingParams } from '../utils/model';
import { PrimaryButton } from '../../../components/Button';
// Import React Scrit Libraray to load Google object
import Script from 'react-load-script';
import config from '../../../config.json';

const genAddress = (data: Listing) => {
  const address = [];
  if (data.streetAddress) {
    address.push(data.streetAddress);
  }
  if (data.suburb) {
    address.push(data.suburb);
  }
  if (data.state) {
    address.push(data.state);
  }
  if (data.postcode) {
    address.push(data.postcode);
  }

  return address.join(', ');
};

interface Props {
  listing?: Listing;
  useManualAddress: boolean;
  changeManualAddress(val: boolean): void;
  onNextStep3(values: IListingParams): void;
}

const CreateListingStep2 = (props: Props) => {
  const { listing, useManualAddress, onNextStep3, changeManualAddress } = props;

  const { control, setValue, handleSubmit } = useForm<IListingParams>({
    defaultValues: {
      phone: listing?.phone || '',
      mobile: listing?.mobile || '',
      email: listing?.email || '',
      streetAddress: listing?.streetAddress || '',
      suburb: listing?.suburb || '',
      postcode: listing?.postcode || '',
      state: listing?.state || '',
      address: listing ? genAddress(listing) : '',
    },
  });

  const userSelectedAddress = React.useCallback(
    (address: string) => {
      // there are weird circumstances where you might end up with an address like this being selected:
      // Twelfth Night Complex, 4 Cintra Rd, Bowen Hills QLD 4006, Australia
      // We want to prune off the "building name" if its there and end up with just a street address
      setValue('address', address, {
        shouldValidate: true,
      });

      let parts = address.split(',');

      if (parts.length > 3) {
        parts = parts.slice(0, parts.length - 3);
      }

      if (parts.length > 0) {
        setValue('streetAddress', parts[0]);

        if (parts.length > 1) {
          let secondPart = parts[1].trim().split(' ');
          // Some suburbs are two words, ie "Bowen Hills", so we work on the array backwards to get the correct values

          setValue('postcode', secondPart[secondPart.length - 1]);
          setValue('state', secondPart[secondPart.length - 2]);
          setValue(
            'suburb',
            secondPart.reduce((prev, current, index, array) => {
              if (index >= secondPart.length - 2) {
                return prev;
              }

              return prev + ' ' + current;
            }),
          );
        }
      }
      // parse the value and split it into the individual fields. We can safely assume all addresses are Australia for now?
    },
    [setValue],
  );

  const handleScriptLoad = () => {
    const autocomplete = new google.maps.places.Autocomplete(document.getElementById('autocomplete'), {
      fields: ['formatted_address'],
    });

    const handlePlaceSelect = () => {
      const addressObject = autocomplete.getPlace();
      userSelectedAddress(addressObject.formatted_address);
    };
    // Fire Event when a suggested name is selected
    autocomplete.addListener('place_changed', handlePlaceSelect);
  };

  return (
    <>
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${config.firebaseApiKey}&libraries=places`}
        onLoad={handleScriptLoad}
      />
      <View style={{ flex: 1 }}>
        <Text
          fontFamily="Barlow-Medium"
          style={{ color: Colour.DarkBlue, fontSize: 24, paddingTop: 20, paddingBottom: 15 }}
        >
          Contact
        </Text>

        <Text style={Style.label}>Landline</Text>
        <Controller
          control={control}
          name="phone"
          rules={{
            // required: { value: true, message: 'Name is required' },
            pattern: { value: auLandlineRx, message: 'Landline is not valid (include your area code)' },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input value={value} onChangeText={onChange} keyboardType="phone-pad" errorMessage={error?.message} />
          )}
        />

        <Text style={Style.label}>Mobile</Text>
        <Controller
          control={control}
          name="mobile"
          rules={{
            // required: { value: true, message: 'Mobile is required' },
            pattern: { value: auMobileRx, message: 'Mobile is not valid' },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              value={value}
              testID="mobileInput"
              onChangeText={onChange}
              keyboardType="phone-pad"
              errorMessage={error?.message}
            />
          )}
        />

        <Text style={Style.label}>Email</Text>
        <Controller
          control={control}
          name="email"
          rules={{
            required: { value: true, message: 'Email is required' },
            pattern: { value: emailValidationRx, message: 'Email is not valid' },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              value={value}
              testID="emailInput"
              onChangeText={onChange}
              errorMessage={error?.message}
              keyboardType="email-address"
              autoCapitalize="none"
              placeholder="Enter an email"
            />
          )}
        />

        <Text style={Style.label}>Address</Text>
        <Controller
          control={control}
          name="address"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              id="autocomplete"
              value={value}
              onChangeText={text => {
                onChange(text);
              }}
              errorMessage={error?.message}
              autoCapitalize="none"
              placeholder="Enter an address"
            />
          )}
        />

        <View style={{ marginBottom: 12 }}>
          <Text style={{ textAlign: 'center', fontSize: 18, color: '#4A4A4A' }}>OR</Text>
        </View>

        <TouchableOpacity
          onPress={() => changeManualAddress(!useManualAddress)}
          activeOpacity={0.5}
          style={{
            flexDirection: 'row',
            backgroundColor: Colour.Blue,
            height: 36,
            borderRadius: 18,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={{ color: 'white', fontSize: 16 }}>
            {useManualAddress ? 'Hide manual address' : 'Add address manually'}
          </Text>
        </TouchableOpacity>

        {useManualAddress ? (
          <View>
            <Text style={Style.label}>Street Address</Text>
            <Controller
              control={control}
              name="streetAddress"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input value={value} onChangeText={onChange} placeholder="e.g. 15 Evergreen Terrace" />
              )}
            />

            <Text style={Style.label}>Suburb</Text>
            <Controller
              control={control}
              name="suburb"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input value={value} onChangeText={onChange} />
              )}
            />

            <Text style={Style.label}>Postcode</Text>
            <Controller
              control={control}
              name="postcode"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input value={value} onChangeText={onChange} />
              )}
            />

            <Text style={Style.label}>State</Text>
            <Controller
              control={control}
              name="postcode"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Picker itemStyle={[Barlow.regular]} mode="dialog" onValueChange={onChange} selectedValue={value}>
                  <Picker.Item label="Select" value="" />
                  <Picker.Item label="Australian Capital Territory" value="ACT" />
                  <Picker.Item label="New South Wales" value="NSW" />
                  <Picker.Item label="Northern Territory" value="NT" />
                  <Picker.Item label="Queensland" value="QLD" />
                  <Picker.Item label="South Australia" value="SA" />
                  <Picker.Item label="Tasmania" value="TAS" />
                  <Picker.Item label="Victoria" value="VIC" />
                  <Picker.Item label="Western Australia" value="WA" />
                </Picker>
              )}
            />
          </View>
        ) : null}
      </View>

      <PrimaryButton
        title="NEXT"
        iconRight
        icon={
          <View style={{ justifyContent: 'center' }}>
            <KeyboardArrowRightIcon sx={{ color: 'white' }} />
          </View>
        }
        onPress={handleSubmit(onNextStep3)}
      />
    </>
  );
};

export default CreateListingStep2;
