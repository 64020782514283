import { Action } from 'redux';
import { Product } from '../services/StripeService';

// ACTION TYPES
enum ActionType {
  SET_PRODUCTS = 'STRIPE_SET_PRODUCTS',
}

interface StripeAction extends Action<ActionType> {
  products?: Product[];
}

// ACTIONS
export const setProducts = (products?: Product[]): StripeAction => ({
  type: ActionType.SET_PRODUCTS,
  products,
});

// STATE
interface StripeState {
  products?: Product[];
}

const initialState: StripeState = {
  products: undefined,
};

// REDUCER
const reducer = (state = initialState, action: StripeAction): StripeState => {
  switch (action.type) {
    case ActionType.SET_PRODUCTS:
      return { ...state, products: action.products };
    default:
      return state;
  }
};

export default reducer;
