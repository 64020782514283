import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ActivityIndicator, TouchableOpacity, View, Alert } from 'react-native';
import { Input } from 'react-native-elements';
import { useSelector } from 'react-redux';
import { PrimaryButton, TextButton } from '../../../components/Button';
import Text from '../../../components/Text';
import { userStateSelector } from '../../../redux/Store';
import { AddServiceRequest } from '../../../services/Firebase';
import Style, { Barlow, Colour } from '../../../styles/style';
import { captureException } from '@sentry/react';

interface ServiceAddParams {
  servicesToAdd: string;
  specialisationsToAdd: string;
  locationsToAdd: string;
  furtherInfoToAdd: string;
}

interface Props {
  onClose(): void;
}

const AddServiceOrSpecialisation = (props: Props) => {
  const { onClose } = props;

  const user = useSelector(userStateSelector);

  const [finished, setFinished] = useState(false);

  const [busy, setBusy] = useState(false);

  const { control, handleSubmit } = useForm<ServiceAddParams>({
    defaultValues: {
      servicesToAdd: '',
      specialisationsToAdd: '',
      locationsToAdd: '',
      furtherInfoToAdd: '',
    },
  });

  const onSubmit = React.useCallback(
    (values: ServiceAddParams) => {
      const { servicesToAdd, specialisationsToAdd, locationsToAdd, furtherInfoToAdd } = values;

      if (!servicesToAdd && !specialisationsToAdd && !locationsToAdd && !furtherInfoToAdd) {
        alert('Please fill in at least one field');
      } else {
        setBusy(true);

        AddServiceRequest(user.auth?.userId!, servicesToAdd, specialisationsToAdd, locationsToAdd, furtherInfoToAdd)
          .then(() => {
            setFinished(true);
            setBusy(false);
          })
          .catch(error => {
            alert('Error adding service request')
            captureException(error)
            setBusy(false);
          });
      }
    },
    [user.auth?.userId],
  );

  if (busy) {
    return <ActivityIndicator style={{ marginTop: '50%' }} />;
  }

  if (finished) {
    return (
      <View style={{ flex: 1, paddingBottom: 30, paddingTop: 20 }}>
        <Text style={{ lineHeight: 22.4, color: Colour.DarkGrey, fontSize: 16, paddingBottom: 15 }}>
          Thank you, your submission will be reviewed for addition to our database.
        </Text>
        <TouchableOpacity onPress={onClose} style={{ alignContent: 'center', justifyContent: 'center', marginTop: 10 }}>
          <Text style={{ textAlign: 'center' }}>Close</Text>
        </TouchableOpacity>
      </View>
    );
  }

  return (
    <View style={{ flex: 1 }}>
      <Text
        fontFamily="Barlow-Medium"
        style={{ color: Colour.DarkBlue, fontSize: 24, paddingTop: 20, paddingBottom: 15 }}
      >
        Add Services &amp; Specialisations
      </Text>

      <Text style={{ lineHeight: 22.4, color: Colour.DarkGrey, fontSize: 16, paddingBottom: 15 }}>
        If your service, specialisation or practice area is missing from the app, please enter it below and we will add
        it as soon as we can. You can enter multiple by separating them with a comma.
      </Text>

      <Text style={Style.label}>Service(s)</Text>
      <Controller
        control={control}
        name="servicesToAdd"
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Input value={value} onChangeText={onChange} />
        )}
      />

      <Text style={Style.label}>Specialisation(s)</Text>
      <Controller
        control={control}
        name="specialisationsToAdd"
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Input value={value} onChangeText={onChange} />
        )}
      />

      <Text style={Style.label}>Location(s)</Text>
      <Controller
        control={control}
        name="locationsToAdd"
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Input value={value} onChangeText={onChange} />
        )}
      />

      <Text style={Style.label}>Further information</Text>
      <Controller
        control={control}
        name="furtherInfoToAdd"
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Input
            value={value}
            onChangeText={onChange}
            multiline
            placeholder="Add any further information here"
            numberOfLines={4}
            inputStyle={{ fontFamily: 'Barlow', textAlignVertical: 'top', minHeight: 100 }}
          />
        )}
      />

      <PrimaryButton title="SUBMIT" onPress={handleSubmit(onSubmit)} />

      <TextButton text="Cancel" onPress={onClose} textStyle={[Barlow.regular, { color: 'black' }]} />
    </View>
  );
};

export default AddServiceOrSpecialisation;
