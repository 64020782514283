import { collection, getDocs, query, where, doc, setDoc, getDoc, updateDoc } from 'firebase/firestore/lite';
import { Profile } from '../../functions/src/PaymentTypes';
import {
  CallFunction,
  Collection,
  Collections, DocumentSnapshot,
  ProfileDocument,
  firestore
} from './Firebase';

export type InvitationEmailData = {
  name: string;
  organisation: string;
  link: string;
};

type UpdatedProfileData = {
  parentProfileId: string;
  isDoyles?: boolean;
  profileId: string;
};

export const ConfirmInvitation = async (
  invitationId: string,
  profileId: string,
  organisationId: string,
  subscriptionId: string,
  cancelOldSubscription: boolean
) => {
  // Update the invitation
  const invitationRef = doc(firestore, Collections.Invitations, invitationId);
  await setDoc(
    invitationRef,
    {
      accepted: true,
    },
    { merge: true },
  );

  const orgProfileDoc = await getDoc(ProfileDocument(organisationId));
  if (orgProfileDoc.exists()) {
    const orgProfile = orgProfileDoc.data() as Profile;
    const isDoylesOrg = !!orgProfile.isDoyles ? true : false;

    const updatedProfileData: UpdatedProfileData = {
      parentProfileId: organisationId,
      profileId: profileId,
    };

    if (isDoylesOrg) {
      updatedProfileData.isDoyles = isDoylesOrg;
    }

    // Then update the profile
    const userProfileRef = doc(firestore, Collections.Profiles, profileId);
    await setDoc(userProfileRef, updatedProfileData, { merge: true });

    // cancel individual's subscription when join organisation
    if (subscriptionId && cancelOldSubscription) {
      await CallFunction('cancelSubscription', { subscriptionId, fromInvitation: true });
    }
    // Then update the listing if there is one
    await CallFunction('updateListingManual', { profileId, isDoylesMember: isDoylesOrg });
  }
};

export const SendInvitationEmail = (to: string, data: InvitationEmailData) => {
  const newMailRef = doc(collection(firestore, Collections.MailToSend));
  return setDoc(
    newMailRef,
    {
      to,
      template: {
        name: 'InvitationEmail',
        data,
      },
    },
    { merge: true },
  );
};

export const SendInvitationCodeEmail = (to: string, name: string, code: string) => {
  const newMailRef = doc(collection(firestore, Collections.MailToSend));
  return setDoc(
    newMailRef,
    {
      to,
      template: {
        name: 'InvitationCode',
        data: {
          name,
          code,
        },
      },
    },
    { merge: true },
  );
};

export const CancelInvitation = (id: string) => {
  const doc = InvitationDocument(id);
  return updateDoc(doc, {
    cancelled: true,
  });
};

export const GetUnacceptedInvitations = (organisationId: string) => {
  const listingsRef = collection(firestore, Collections.Invitations);
  const q = query(
    listingsRef,
    where('organisationId', '==', organisationId),
    where('accepted', '==', false),
    where('cancelled', '==', false),
  );
  return getDocs(q);
};

export const GetUnacceptedInvitationsByEmail = (organisationId: string, email: string) => {
  return getDocs(
    query(
      collection(firestore, Collections.Invitations),
      where('organisationId', '==', organisationId),
      where('accepted', '==', false),
      where('cancelled', '==', false),
      where('inviteeEmail', '==', email),
    ),
  );
};

export const AddInvitation = async (
  inviteeEmail: string,
  inviteeName: string,
  organisationId: string,
  inviterName: string,
) => {
  // generate a 4 digit code for confirmation later
  let code = Math.floor(9999 * Math.random()).toString();
  // we appear to be missing .padStart()...
  while (code.length < 4) {
    code = '0' + code;
  }

  let invData = {
    code,
    inviteeEmail,
    inviteeName,
    organisationId,
    inviterName,
    accepted: false,
    cancelled: false,
  };
  const newInviteRef = doc(collection(firestore, Collections.Invitations));
  await setDoc(newInviteRef, invData, { merge: true });
  return newInviteRef.id;
};

export const GetInvitation = (id: string) => {
  return DocumentSnapshot(Collections.Invitations, id);
};

export const InvitationCollection = Collection(Collections.Invitations);
export const InvitationDocument = (docId: string) => doc(InvitationCollection, docId);

export const GetInvitationByOrgId = (orgId: string) =>
  getDocs(query(collection(firestore, Collections.Invitations), where('organisationId', '==', orgId)));
