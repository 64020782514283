import moment from 'moment';
import { ISODateString } from 'react-native-calendar-events';
import { CalendarEvent } from '../../redux/Calendar';

export const monday = 1;
export const saturday = 6;

export interface DateProps {
  date: moment.Moment;
}

export interface SetDateProps {
  setDate: (date: moment.Moment) => void;
}

export interface EventsProps {
  events?: CalendarEvent[];
  onChangeValue?: (events: ISODateString[]) => void;
}

// Selected times text
const selectedTimeText = 'No dates selected';

export const selectedTimesHumanReadable = (dates: ISODateString[], blockLength: number) => {
  if (dates.length === 0) {
    return selectedTimeText;
  }
  let text = '';

  // Convert to array of moment
  const moments = dates.map(date => moment(date));

  // Add first day text
  text += moments[0].format('ddd D MMM');

  // If dates are all on the same day
  if (moments.every(m => m.date() === moments[0].date())) {
    // Show first and last time selected
    text += moments[0].format(': Ha') + moments[moments.length - 1].add(blockLength, 'hour').format(' - Ha');
  } else {
    // Show the last date selected
    text += moments[moments.length - 1].format(' - ddd D MMM');
  }
  return text;
};
