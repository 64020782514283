import { Theme } from 'react-native-elements';
import { Barlow, Colour } from './style';
import { Platform } from 'react-native';

export const theme: Theme = {
  Input: {
    placeholderTextColor: Platform.OS === 'ios' ? '#757575' : '#A0A0A0',
    inputContainerStyle:
      Platform.OS === 'ios'
        ? {
            backgroundColor: Colour.Grey,
            borderRadius: 6,
            marginHorizontal: -10,
            paddingHorizontal: 10,
            borderColor: 'transparent',
          }
        : {
            marginHorizontal: -10,
            paddingHorizontal: 10,
          },
    inputStyle: [Barlow.regular],
    errorStyle: [Barlow.regular, { color: 'red', fontSize: 14 }],
  },
};
