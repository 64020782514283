import React, { useState } from 'react';
import { Linking, ScrollView, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { Image } from 'react-native-elements';
import org from '../../assets/images/iconMenuOrganisation@3x.png';
import profile from '../../assets/images/iconMenuViewProfile@3x.png';
import doylesBanner from '../../assets/images/doylesPhone.png';
import { PrimaryButton, TextButton } from '../../components/Button';
import { useSafeBottomPadding } from '../../components/Hooks';
import Text from '../../components/Text';
import { AccountTypeScreenProps } from '../../navigators/Types';
import { setType, UserType } from '../../redux/Register';
import style, { FontSize } from '../../styles/style';
import LoginBase from './LoginBase';
import { useLinkTo } from '@react-navigation/native';
import config from '../../config.json';
import { logAnalyticsEvent } from '../../services/Firebase';

const AccountTypeScreen = ({ route, navigation }: AccountTypeScreenProps) => {
  // Hooks
  const dispatch = useDispatch();
  const linkTo = useLinkTo();

  const [userType, setUserType] = useState<UserType>();

  const setTypeAndNavigate = (type: UserType) => {
    // Dispatch to store
    dispatch(setType(type));

    // Navigate to registration form page
    linkTo('/signup');
  };

  // Render
  return (
    <LoginBase hasHeader>
      <View style={{ height: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <View style={[style.header, { marginBottom: 20 }]}>
          <Text
            style={[
              FontSize.extraLarge,
              {
                color: 'white',
                letterSpacing: 1.5,
                marginTop: 40,
                marginBottom: 20,
              },
            ]}>
            Account Type
          </Text>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              padding: 20,
              flexWrap: 'wrap',
              maxWidth: 500,
            }}>
            {!userType ? (
              <>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    padding: 20,
                    flexWrap: 'wrap',
                    flexShrink: 1,
                  }}>
                  <Image
                    source={profile}
                    height={48}
                    width={40}
                    style={{ width: 40, height: 48, alignItems: 'center', marginRight: 10 }}
                  />
                  <View style={{ flex: 1 }}>
                    <Text
                      fontFamily="Barlow-SemiBold"
                      style={[FontSize.medium, { color: 'white', letterSpacing: 1.1 }]}>
                      INDIVIDUAL
                    </Text>
                    <Text fontFamily="Barlow-Medium" style={[{ color: 'white' }]}>
                      Are you looking for service providers, or are you a service provider not associated with an
                      organisation?
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    padding: 20,
                    flexWrap: 'wrap',
                    flexShrink: 1,
                  }}>
                  <Image
                    source={org}
                    height={40}
                    width={49}
                    style={{ width: 49, height: 40, alignItems: 'center', marginRight: 10 }}
                  />
                  <View style={{ flex: 1 }}>
                    <Text
                      fontFamily="Barlow-SemiBold"
                      style={[FontSize.medium, { color: 'white', letterSpacing: 1.1 }]}>
                      ORGANISATION
                    </Text>
                    <Text fontFamily="Barlow-Medium" style={[{ color: 'white' }]}>
                      Are you an organisation with employees looking to list their services?
                    </Text>
                  </View>
                </View>
              </>
            ) : (
              <>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    padding: 20,
                    flexWrap: 'wrap',
                    flexShrink: 1,
                  }}>
                  <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                    <Image source={doylesBanner} style={{ width: 133, height: 156 }} />
                    <Text
                      fontFamily="Barlow"
                      style={[FontSize.normal, { color: 'white', letterSpacing: 1.1, paddingLeft: 5 }]}>
                      Your premier profile will show that you are on the Doyles Guide, and you can upload your Doyles
                      logo. You will also come up when people search for ‘Doyles only’ practitioners.
                    </Text>
                  </View>
                </View>
              </>
            )}
          </View>
        </View>
        <View style={{ flexGrow: 2 }}>
          <View style={[style.footer, { maxHeight: 310 }]}>
            <ScrollView
              style={style.scrollview}
              contentContainerStyle={[style.scrollviewContainer, { paddingBottom: useSafeBottomPadding() }]}>
              {!userType ? (
                <>
                  <View style={style.container}>
                    <PrimaryButton
                      title="INDIVIDUAL"
                      testID="individualSignupButton"
                      onPress={() => {
                        logAnalyticsEvent('account_type_selected', {
                          type: UserType.Individual,
                        });
                        dispatch(setType(UserType.Individual));
                        setUserType(UserType.Individual);
                        // setTypeAndNavigate(UserType.Individual);
                      }}
                    />
                    <Text style={[{ textAlign: 'center', fontSize: 11, marginBottom: 10 }]}>
                      If you are a sole practitioner or employee at an Organisation
                    </Text>
                    <Text style={[{ textAlign: 'center' }]}>OR</Text>
                    <PrimaryButton
                      title="ORGANISATION"
                      testID="organisationSignupButton"
                      onPress={() => {
                        logAnalyticsEvent('account_type_selected', {
                          type: UserType.Organisation,
                        });
                        // setTypeAndNavigate(UserType.Organisation);
                        dispatch(setType(UserType.Organisation));
                        setUserType(UserType.Organisation);
                      }}
                    />

                    <Text style={[{ textAlign: 'center', fontSize: 11, marginBottom: 11 }]}>
                      If you want to setup an Organisation account (and invite staff to join)
                    </Text>
                  </View>

                  <View style={{ alignItems: 'center', paddingBottom: 10 }}>
                    <Text>Already have an account?</Text>
                    <TextButton onPress={() => navigation.replace('Login')} text="Log in" />
                  </View>
                </>
              ) : (
                <>
                  <View style={style.container}>
                    <PrimaryButton
                      title="Doyles Listed"
                      testID="doylesButton"
                      onPress={() => {
                        if (userType === UserType.Organisation) {
                          Linking.openURL(`${config.webPortalDomain}/sign-up/organization?doyles=true`);
                        } else {
                          Linking.openURL(`${config.webPortalDomain}/sign-up/individual?doyles=true`);
                        }
                      }}
                    />
                    <Text style={[{ textAlign: 'center' }]}>OR</Text>
                    <PrimaryButton
                      title="Not Doyles"
                      testID="notDoylesButton"
                      onPress={() => {
                        if (userType === UserType.Organisation) {
                          Linking.openURL(`${config.webPortalDomain}/sign-up/organization`);
                        } else {
                          dispatch(setType(userType));
                          // Navigate to registration form page
                          linkTo('/signup');
                        }
                      }}
                    />
                  </View>

                  <View style={{ alignItems: 'center', paddingBottom: 10 }}>
                    <Text>Already have an account?</Text>
                    <TextButton onPress={() => navigation.replace('Login')} text="Log in" />
                  </View>
                </>
              )}
            </ScrollView>
          </View>
        </View>
      </View>
    </LoginBase>
  );
};

export default AccountTypeScreen;
