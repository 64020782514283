import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Keyboard, View } from 'react-native';
import { Input } from 'react-native-elements';
import MailIcon from '@mui/icons-material/MailOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useDispatch } from 'react-redux';
import { PrimaryButton, TextButton } from '../../components/Button';
import { FormError, emailValidationRx } from '../../components/FormHelper';
import { useSafeBottomPadding } from '../../components/Hooks';
import ScrollView from '../../components/ScrollView';
import Text from '../../components/Text';
import { LoginScreenProps } from '../../navigators/Types';
import { setAuth } from '../../redux/User';
import { GetAuthErrorMessage, SignIn, auth } from '../../services/Firebase';
import style, { FontSize } from '../../styles/style';
import LoginBase from './LoginBase';
import { popSafely } from '../../tools/NavHelpers';
import { useIsFocused, useLinkTo } from '@react-navigation/native';

interface ILoginForm {
  email: string;
  password: string;
}

const LoginScreen = ({ route, navigation }: LoginScreenProps) => {
  const dispatch = useDispatch();
  const linkTo = useLinkTo();
  const isFocused = useIsFocused()
  //useEffect(() => {
    //This fixes us getting stuck on loading, but it means you can't back out of the app after doing anything:(
    //if (auth.currentUser) {
       // linkTo('/search')
    //}
  //}, [isFocused, auth.currentUser])
  const [loading, setLoading] = useState<boolean>(false);
  const [hidePassword, setHidePassword] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(' ');

  const { control, handleSubmit } = useForm<ILoginForm>({
    defaultValues: { email: '', password: '' },
  });

  const onLogin = useCallback(
    (values: ILoginForm) => {
      Keyboard.dismiss();
      setErrorMessage(' ');

      setLoading(true);

      SignIn({ ...values, email: values.email.trim() })
        .then(res => {
          dispatch(
            setAuth({
              userId: res.user.uid,
              email: res.user.email!,
            }),
          );

          if (route.params?.returnToInvite) {
            popSafely(navigation);
          }
        })
        .catch(reason => {
          setLoading(false);
          setErrorMessage(GetAuthErrorMessage(reason.code));
        });
    },
    [dispatch, navigation, route.params?.returnToInvite],
  );

  return (
    <LoginBase hasHeader>
      <View style={style.header}>
        <Text
          style={[
            FontSize.extraLarge,
            {
              color: 'white',
              letterSpacing: 1.5,
              marginTop: 40,
              marginBottom: 100,
            },
          ]}
        >
          Log In
        </Text>
      </View>
      <View style={[style.footer, { maxHeight: '400px' }]}>
        <ScrollView
          style={style.scrollview}
          contentContainerStyle={[style.scrollviewContainer, { paddingBottom: useSafeBottomPadding() }]}
        >
          <View style={[style.container, {justifyContent: 'space-evenly'}]}>
            <Controller
              control={control}
              name="email"
              rules={{
                required: { value: true, message: 'Email is required' },
                pattern: { value: emailValidationRx, message: 'Email is not valid' },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  value={value}
                  onChangeText={onChange}
                  errorMessage={error?.message}
                  testID="emailInputField"
                  placeholder="Email"
                  keyboardType="email-address"
                  returnKeyType="next"
                  autoCapitalize="none"
                  leftIcon={<MailIcon />}
                />
              )}
            />

            <Controller
              control={control}
              name="password"
              rules={{
                required: { value: true, message: 'Password is required' },
                minLength: { value: 8, message: 'Password must be 8 characters long' },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  value={value}
                  onChangeText={onChange}
                  testID="passwordInputField"
                  errorMessage={error?.message}
                  placeholder="Password"
                  secureTextEntry={hidePassword}
                  autoCapitalize="none"
                  leftIcon={<LockOutlinedIcon />}
                  rightIcon={
                    hidePassword ? (
                      <VisibilityOutlinedIcon onClick={() => setHidePassword(v => !v)} />
                    ) : (
                      <VisibilityOffOutlinedIcon onClick={() => setHidePassword(v => !v)} />
                    )
                  }
                />
              )}
            />

            {FormError(errorMessage)}

            <View>
              <PrimaryButton
                loading={loading}
                disabled={loading}
                testID="loginButton"
                title="LOG IN"
                onPress={handleSubmit(onLogin)}
              />

              <TextButton onPress={() => linkTo('/forgot')} text="Forgot Password" />
            </View>
          </View>
          <View style={{ alignItems: 'center' }}>
            <Text>Don't have an account?</Text>
            <TextButton onPress={() => navigation.replace('AccountType')} text="Sign up" />
          </View>
        </ScrollView>
      </View>
    </LoginBase>
  );
};

export default LoginScreen;
