import React from 'react';
import { Image, StyleProp, View, ViewStyle } from 'react-native';
import { Asset } from 'react-native-image-picker';
import iconUpload from '../assets/images/iconUpload.png';
import Text from './Text';
import { Barlow, Colour } from '../styles/style';

interface Props {
  imageUrl?: string;
  onSetImages(image: Asset[]): void;
  style?: StyleProp<ViewStyle>;
}
const MAX_FILE_SIZE = 2000000

const ModalChooseDoylesImages = (props: Props) => {
  const { onSetImages, style } = props;

  const handleImageInput = e => {
    const file = e.target.files[0];
    const sizeInKiloByytes = file.size / 1024;
    if (file.size > MAX_FILE_SIZE) {
      alert('Error', 'Max file size is 2MB');
      return
    }
    if (file) {
      file.uri = URL.createObjectURL(file);
      onSetImages([file]);
    }
  };

  const imageInputRef = React.useRef(null);


  return (
    <View style={[style]}>
      <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 20 }}>
        <View
          onClick={()=> {
            imageInputRef?.current?.click();
          }}
          style={{
            backgroundColor: Colour.LightBlue,
            width: 120,
            height: 34,
            borderRadius: 50,
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            cursor: 'pointer',
            gap: 10,
          }}
        >
          <Image style={{ height: 20, width: 20 }} source={iconUpload} />
          <Text style={{ marginLeft: 4, color: Colour.DarkerBlue, fontFamily: Barlow.medium.fontFamily }}>
            Upload
          </Text>
        </View>
        <Text style={{ marginLeft: 20, color: Colour.MediumGrey, fontFamily: Barlow.medium.fontFamily }}>
          max file size: 2MB
        </Text>
        <input
          ref={imageInputRef}
          accept="image/*"
          type="file"
          style={{ visibility: 'hidden' }}
          onChange={handleImageInput}
        />
        {/* <ActionSheet
          title=""
          isDoyles
          description="Select a photo"
          items={['Choose from Library...']}
          onSelect={_ => pickPhoto()}
        /> */}
      </View>
    </View>
  );
};

export default ModalChooseDoylesImages;
