import React, { useState, useEffect, useMemo } from 'react';
import { Button, Clipboard, Text, View, FlatList } from 'react-native';
import { SearchBar } from 'react-native-elements';
import { TextButton } from '../../components/Button';
import { Listing, Profile } from '../../redux/User';
import { Collection, Collections, CreateFirestoreHelper, IFirebaseBaseDocument } from '../../services/Firebase';

type TestProfile = Profile & IFirebaseBaseDocument;
type TestListing = Listing & IFirebaseBaseDocument & { planStatus: string };
const profileStore = CreateFirestoreHelper<TestProfile>(Collections.Profiles);
const listingStore = CreateFirestoreHelper<TestListing>(Collections.Listings);

const ProfileListScreen = () => {
  const [searchText, setSearchText] = useState('qa');
  const [profiles, setProfiles] = useState<TestProfile[]>([]);
  const [listings, setListings] = useState<TestListing[]>([]);
  const visibleProfiles = useMemo(() => {
    const filtered = profiles.filter(p => p.email?.toLowerCase().includes(searchText.toLowerCase()));
    return filtered;
  }, [profiles, searchText]);

  // load profiles
  useEffect(() => {
    const unsubProfile = profileStore.SubscribeToCollection(setProfiles);
    const unsubListing = listingStore.SubscribeToCollection(setListings);
    return () => {
      unsubListing();
      unsubProfile();
    };
  }, [searchText]);

  return (
    <View style={{ flex: 1 }}>
      <SearchBar value={searchText} onChangeText={setSearchText} />
      <FlatList
        data={visibleProfiles}
        keyExtractor={item => item.email}
        renderItem={({ item: profile, index }) => {
          const listing = listings.find(listing => listing.profileId === profile.id);
          return (
            <View key={index} style={{ margin: 20 }}>
              <View style={{ flexDirection: 'row' }}>
                <View style={{ flexGrow: 1 }}>
                  <Text>{profile.name}</Text>
                  <TextButton
                    text={profile.id!}
                    onPress={() => {
                      Clipboard.setString(profile.id!);
                    }}
                  />
                  <Text>{profile.email}</Text>
                  {listing !== undefined && (
                    <>
                      <Text>{listing.email}</Text>
                      <Text>{listing.id}</Text>
                      <Button
                        title="delete listing"
                        color="red"
                        onPress={() => listingStore.DeleteDocument(listing.id!)}
                      />
                    </>
                  )}
                </View>
                <View>
                  <Button
                    color="red"
                    title="DELETE"
                    onPress={() => {
                      // delete listing
                      if (listing?.id) {
                        listingStore.DeleteDocument(listing.id);
                      }
                      // delete profile
                      if (profile?.id) {
                        profileStore.DeleteDocument(profile.id);
                      }
                    }}
                  />
                  <Button
                    title={profile.plan ? 'Remove Plan' : 'Give Plan'}
                    onPress={() => {
                      const doc = Collection(Collections.Profiles).doc(profile.id!);
                      if (profile.plan) {
                        // Remove
                        profileStore.DeleteField(profile.id!, 'plan');
                      } else {
                        // Add
                        profileStore.UpdateDocument({
                          id: profile.id,
                          plan: {
                            subscriptionId: 'test',
                            profileAmount: 12,
                            period: 0,
                          },
                        });
                      }
                    }}
                  />
                </View>
              </View>
            </View>
          );
        }}
      />
    </View>
  );
};

export default ProfileListScreen;
