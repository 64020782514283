import { useLinkTo } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';

import ListingItem from '../../components/Search/ListingItem';
import SearchParamForm from './components/SearchParamForm';
import Text from '../../components/Text';
import { FilterType, Rate, SearchParams, SortType } from '../../redux/Search';
import { searchStateSelector, userStateSelector } from '../../redux/Store';
import { collection, getDocs } from 'firebase/firestore/lite';
import { firestore } from '../../services/Firebase';
import { Listing } from '../../redux/User';
import { ActivityIndicator, FlatList, View } from 'react-native';
import { useSelector } from 'react-redux';

type SearchListScreenProps = {
  isMainSearch: boolean;
  setIsMainSearch: React.Dispatch<React.SetStateAction<boolean>>;
};

const SearchListScreen = ({ isMainSearch, setIsMainSearch }: SearchListScreenProps) => {
  const linkTo = useLinkTo();

  const { favoriteListingIds } = useSelector(userStateSelector)!;
  const { params } = useSelector(searchStateSelector);
  const [listingList, setListingList] = useState<Listing[]>([]);
  const [loading, setLoading] = useState(false);

  const getListings = async (params: SearchParams | undefined) => {
    let listings: Listing[] = [];
    setLoading(true);
    try {
      const listingsCollection = collection(firestore, 'listings');
      const snapshot = await getDocs(listingsCollection);

      listings = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      listings = listings.filter(listing => listing.planStatus === 'active' || listing.planStatus === 'trialing');

      if (params && params.name) {
        listings = listings.filter(listing => listing?.name?.toLowerCase().includes(params.name!.toLowerCase()));
      }

      if (params?.filters) {
        params.filters.forEach(searchFilter => {
          switch (searchFilter.type) {
            case FilterType.Service:
              const searchServices = searchFilter.value as string[];
              listings = listings.filter(listing =>
                searchServices.some(service => {
                  let services = listing?.services;
                  if (!services) {
                    return false;
                  }

                  const forService = !service.includes('.');

                  if (forService) {
                    services = listing?.services!.map(s => s.split('.')[0]);
                  }

                  return services.includes(service);
                }),
              );
              break;

            case FilterType.Location:
              const searchLocations = searchFilter.value as string[];
              listings = listings.filter(listing =>
                searchLocations.some(location => {
                  if (!listing.locations) {
                    return false;
                  }
                  const splitSearchLocation = location.split('.');

                  return listing.locations.some(listingLocation => {
                    const splitListingLocation = listingLocation.split('.');

                    let i = 0;
                    while (i < Math.min(splitSearchLocation.length, splitListingLocation.length)) {
                      if (splitSearchLocation[i] !== splitListingLocation[i]) {
                        return false;
                      }
                      i++;
                    }
                    return true;
                  });
                }),
              );
              break;

            case FilterType.DateRange:
              const { startDate, endDate } = searchFilter.value as { startDate: string; endDate: string };

              if (params?.sort == SortType.Available) {
                listings = listings.filter(
                  listing => !listing.events?.some(event => event.startDate <= endDate && event.endDate >= startDate),
                );
                break;
              }
              const filteredResults = listings.reduce(
                (res, item) => {
                  res[
                    !item.events?.some(event => event.startDate <= endDate && event.endDate >= startDate) ? 'a' : 'b'
                  ].push(item);
                  return res;
                },
                { a: [] as Listing[], b: [] as Listing[] },
              );

              listings = filteredResults.a.concat(filteredResults.b);
              break;

            case FilterType.Rate:
              const { ratePeriod, rate, onlyHourly, onlyDaily, reportRate, onlyReport } = searchFilter.value as Rate;
              if (rate) {
                const isHourly = ratePeriod === 0;
                listings = listings.filter(listing => {
                  const listingRate = isHourly ? listing.hourlyRate : listing.dailyRate;
                  if (!listingRate) {
                    return false;
                  }

                  if (rate === 0) {
                    return listingRate <= (isHourly ? 200 : 1000);
                  }

                  if (rate === 3) {
                    return listingRate >= (isHourly ? 400 : 3000);
                  }

                  const low = isHourly ? (rate === 1 ? 200 : 300) : rate === 1 ? 1000 : 2000;
                  const high = isHourly ? (rate === 1 ? 299 : 399) : rate === 1 ? 1999 : 2999;

                  return listingRate >= low && listingRate <= high;
                });
              }
              if (onlyHourly) {
                listings = listings.filter(listing => listing.hourlyRate);
              }

              if (onlyDaily) {
                listings = listings.filter(listing => listing.dailyRate);
              }

              if (reportRate) {
                listings = listings.filter(listing => {
                  const listingRate = listing.reportCost;
                  if (!listingRate) {
                    return false;
                  }

                  if (rate === 0) {
                    return listingRate <= 1000;
                  }
                  if (rate === 3) {
                    return listingRate >= 5000;
                  }

                  const low = rate === 1 ? 1000 : 3000;
                  const high = rate === 1 ? 2999 : 4999;

                  return listingRate >= low && listingRate <= high;
                });
              }

              if (onlyReport) {
                listings = listings.filter(listing => listing.reportCost);
              }
              break;

            case FilterType.Doyles:
              const searchValue = searchFilter.value as boolean;
              if (searchValue) {
                listings = listings.filter(listing => listing?.isDoyles === searchValue);
              }
              break;
          }
        });
      }

      setListingList(listings);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching listings:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!params?.name && params?.filters?.length === 0) {
      setListingList([]);
      return;
    }

    if (params?.name) {
      setIsMainSearch(false);
    }

    if (params?.name || params?.filters?.length !== 0) {
      getListings(params);
    }
  }, [params]);

  return isMainSearch ? (
    <View style={{ backgroundColor: 'white', paddingTop: 20, flexGrow: 1, justifyContent: 'space-between' }}>
      <SearchParamForm setIsMainSearch={setIsMainSearch} />
    </View>
  ) : loading ? (
    <View style={{ flex: 1, alignContent: 'center', justifyContent: 'center', backgroundColor: 'white' }}>
      <ActivityIndicator size="large" />
    </View>
  ) : (
    <FlatList
      contentContainerStyle={{ flexGrow: 1 }}
      data={listingList}
      keyExtractor={(item, index) => index.toString()}
      renderItem={({ item: listing }) => (
        <ListingItem
          listing={listing}
          testID="listingItem"
          isFavorite={favoriteListingIds.includes(listing.id!)}
          navigateOnClick={() =>
            linkTo({
              screen: listing?.isOrg ? 'ViewOrganisation' : 'ViewListing',
              params: {
                id: listing.id,
                profileId: listing.profileId,
              },
            })
          }
        />
      )}
      ListEmptyComponent={
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: 'white' }}>
          <Text style={{ color: 'grey' }}>No results found</Text>
        </View>
      }
    />
  );
};

export default SearchListScreen;
