import moment from 'moment';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { View } from 'react-native';
import { Icon, Input } from 'react-native-elements';
import { useDispatch, useSelector } from 'react-redux';
import { PrimaryButton, TextButton } from '../../components/Button';
import { emailValidationRx, ValidateMobile } from '../../components/FormHelper';
import MailIcon from '@mui/icons-material/MailOutlined';
import Text from '../../components/Text';
import { ViewPersonalProfileScreenProps } from '../../navigators/Types';
import { userStateSelector } from '../../redux/Store';
import { Profile, setProfile } from '../../redux/User';
import { SaveProfile } from '../../services/Firebase';
import Style, { Colour, FontSize } from '../../styles/style';
import DeleteProfileButton from '../../components/DeleteProfileButton';
import { popSafely } from '../../tools/NavHelpers';
import { useLinkTo } from '@react-navigation/native';
import LoginBase from '../auth/LoginBase';
import { captureException } from '@sentry/react';

const EditPersonalProfile = ({ route, navigation }: ViewPersonalProfileScreenProps) => {
  const userState = useSelector(userStateSelector);
  const linkTo = useLinkTo();
  const dispatch = useDispatch();

  const { control, handleSubmit } = useForm<Profile>({
    defaultValues: { ...userState.profile },
  });

  const [busy, setBusy] = useState(false);

  const onSubmit = (values: Profile) => {
    setBusy(true);

    const { name, email, phone } = values;

    SaveProfile(userState.auth!.userId, name, email, phone || '')
      .then(result => {
        dispatch(setProfile({ ...userState.profile, name, email, phone, userType: userState.profile?.userType! }));
        popSafely(navigation);
      })
      .catch(error => {
        captureException(error)
        alert('Error saivng profile');
      });
  };

  const hasPlan = userState.profile?.plan;
  let planDescription = 'Free';

  if (hasPlan) {
    const unixTimestamp = Number(userState.profile?.plan?.trial_end);
    const trialEnd = moment.unix(unixTimestamp);
    planDescription = moment() < trialEnd ? `Trial to ${trialEnd.format('DD/MM/YY')}` : 'Active';
  }

  planDescription = userState.profile?.parentProfileId ? 'Organisation Plan' : planDescription;

  return (
    <LoginBase>
      <Text
        style={[
          FontSize.extraLarge,
          {
            color: 'white',
            letterSpacing: 3,
            height: '10%',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
        ]}
      >
        Edit Profile
      </Text>
      <View style={[Style.footer, { maxHeight: '550px' }]}>
        <View style={{ flex: 1, padding: 30 }}>
          <Text style={Style.label}>Full name</Text>
          <Controller
            control={control}
            name="name"
            rules={{
              required: { value: true, message: 'Name is required' },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                value={value}
                testID="nameInput"
                onChangeText={onChange}
                errorMessage={error?.message}
                placeholder="Full Name"
                returnKeyType="next"
                autoCapitalize="none"
              />
            )}
          />

          <Text style={[Style.label]}>Email</Text>
          <Controller
            control={control}
            name="email"
            rules={{
              required: { value: true, message: 'Email is required' },
              pattern: { value: emailValidationRx, message: 'Email is not valid' },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                value={value}
                onChangeText={onChange}
                testID="emailInput"
                errorMessage={error?.message}
                placeholder="Email"
                keyboardType="email-address"
                returnKeyType="next"
                autoCapitalize="none"
                leftIcon={<MailIcon />}
              />
            )}
          />

          <Text style={[Style.label]}>Phone</Text>
          <Controller
            control={control}
            name="phone"
            rules={{
              validate: {
                mobile: value =>
                  value
                    ? ValidateMobile(value) ||
                      'Invalid phone number. Please provide a valid mobile number with an area code.'
                    : undefined,
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                value={value}
                testID="phoneInput"
                onChangeText={onChange}
                errorMessage={error?.message}
                keyboardType="phone-pad"
                placeholder="Phone"
                returnKeyType="next"
                autoCapitalize="none"
              />
            )}
          />

          <Text style={[Style.label]}>Current Plan</Text>
          <View style={{ flexDirection: 'row' }}>
            <Text style={[{ color: Colour.DarkGrey, fontSize: 18, letterSpacing: 0.9, flexGrow: 1, marginTop: 8 }]}>
              {planDescription}
            </Text>
            {userState.profile?.parentProfileId ? null : (
              <TextButton
                style={{ margin: 0 }}
                textStyle={[Style.label, { textAlign: 'right' }]}
                //@ts-ignore
                onPress={() => linkTo('/payment')}
                text="View Plans"
              />
            )}
          </View>
          <View style={{ paddingTop: 10 }}>
            <PrimaryButton title="SAVE" disabled={busy} onPress={handleSubmit(onSubmit)} />
            <DeleteProfileButton />
          </View>
        </View>
      </View>
    </LoginBase>
  );
};

export default EditPersonalProfile;
