import moment, { Moment } from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { Input } from 'react-native-elements';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useSelector } from 'react-redux';
import { PrimaryButton } from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import { useSafeBottomPadding } from '../../components/Hooks';
import ScrollView from '../../components/ScrollView';
import Text from '../../components/Text';
import { SendMessageScreenProps } from '../../navigators/Types';
import { userStateSelector } from '../../redux/Store';
import { Message, SendMessageToUser, logAnalyticsEvent } from '../../services/Firebase';
import Style, { Colour, FontSize } from '../../styles/style';
import { Listing } from '../../redux/User';
import LoginBase from '../auth/LoginBase';
import { GetListing } from '../../services/ListingService';
import MultiTextSelectInput from '../../components/MultiTextSelectInput';

interface DateRange {
  from?: Moment;
  to?: Moment;
}

const SendMessage = ({ route, navigation }: SendMessageScreenProps) => {
  const userState = useSelector(userStateSelector);

  const { id } = route.params;
  const [listing, setListing] = useState<Listing | null>(null);
  useEffect(() => {
    const getListing = async () => {
      const listing = await GetListing(id);
      setListing(listing);
    };

    getListing();
  }, [id]);

  const [message, setMessage] = useState('');
  var timedifference = new Date().toTimeString().slice(9);

  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);

  const [dateBeingPicked, setDateBeingPicked] = useState('');

  const [wholeDay, setWholeDay] = useState(false);

  const [error, setError] = useState('');

  const format = useMemo(() => (wholeDay ? 'D/MM/YYYY' : 'ddd DD MMM YYYY h:mm A'), [wholeDay]);

  const [ccEmails, setCCEmails] = useState([userState.auth?.email].filter(x => x));
  const [ccEmailInputVal, setCCEmailInputVal] = useState('');

  const [selectedDate, setSelectedDate] = useState<DateRange>(() => {
    let newRange = {
      from: undefined,
      to: undefined,
    } as DateRange;
    if (route.params.fromDate !== undefined && route.params.toDate !== undefined) {
      const now = moment();
      let from = moment(route.params.fromDate);
      if (from < now) {
        from = now;
      }
      newRange.from = from;
      let to = moment(route.params.toDate);
      if (to < from) {
        to = from;
      }
      newRange.to = to;
    }

    return newRange;
  });

  const selectedDateFormatted = useMemo(() => {
    const fromMoment = moment(selectedDate.from);
    const toMoment = moment(selectedDate.to);
    return {
      from: fromMoment.format(format),
      fromDate: fromMoment.format('ddd D MMM'),
      fromHour: fromMoment.format('h:mm A'),
      to: toMoment.format(format),
      toDate: toMoment.format('ddd D MMM'),
      toHour: toMoment.format('h:mm A'),
    };
  }, [format, selectedDate.from, selectedDate.to]);

  const send = () => {
    setError('');
    if (!message) {
      setError('You must provide a message');
      return;
    }
    if (!selectedDate.from) {
      setError('You must provide a from date/time');
      return;
    }
    if (!selectedDate.to) {
      setError('You must provide a to date/time');
      return;
    }
    let emailsToCC = ccEmails;
    if (ccEmailInputVal && ccEmailInputVal.length) {
      emailsToCC = [...ccEmails, ccEmailInputVal];
    }
    SendMessageToUser(listing?.email!, emailsToCC, {
      recipient: listing?.name,
      sender: userState.profile!.name,
      message,
      contact: userState.profile!.email,
      from: `${selectedDateFormatted.from} ${timedifference}`,
      to: `${selectedDateFormatted.to} ${timedifference}`,
      listing: listing?.name,
    } as Message);
    logAnalyticsEvent('message_sent', {
      sentTo: listing?.id,
    });
    navigation.replace('MessageSent', { name: listing.name });
  };

  return (
    <LoginBase>
      <View style={[Style.footer, { backgroundColor: 'rgba(0, 0, 0, 0)', justifyContent: 'center' }]}>
        <View style={{ backgroundColor: 'white', borderRadius: 24, padding: 30 }}>
          <Input
            value={message}
            onChange={v => setMessage(v.nativeEvent.text)}
            multiline
            testID="messageInput"
            inputStyle={{ fontFamily: 'Barlow', textAlignVertical: 'top', minHeight: 100 }}
            placeholder="Add your message..."
          />

          <Text
            style={[
              FontSize.normal,
              { color: Colour.DarkGrey, letterSpacing: 0.8, lineHeight: 22.4, marginBottom: 19 },
            ]}>
            Select the ‘from’ and ‘to’ date and time below to make changes
          </Text>

          {wholeDay ? (
            <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-evenly' }}>
              <DatePicker
                label="From"
                format={format}
                value={selectedDate.from}
                sx={{ width: '50%' }}
                onChange={value => setSelectedDate({ ...selectedDate, from: value as Date })}
              />
              <DatePicker
                label="To"
                format={format}
                value={selectedDate.to}
                sx={{ width: '50%' }}
                onChange={value => setSelectedDate({ ...selectedDate, to: value as Date })}
              />
            </View>
          ) : (
            <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-evenly' }}>
              <DateTimePicker
                label="From"
                format={format}
                value={selectedDate.from}
                sx={{ width: '50%' }}
                onChange={value => setSelectedDate({ ...selectedDate, from: value as Date })}
              />
              <DateTimePicker
                label="To"
                format={format}
                value={selectedDate.to}
                sx={{ width: '50%' }}
                onChange={value => setSelectedDate({ ...selectedDate, to: value as Date })}
              />
            </View>
          )}

          <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 18 }}>
            <Checkbox
              value={wholeDay}
              onChange={() => setWholeDay(!wholeDay)}
              text="Select entire day(s)"
              textStyle={{ fontSize: 16, letterSpacing: 0.8, color: Colour.DarkGrey }}
            />
          </View>
          <Text
            style={[
              FontSize.normal,
              { color: Colour.DarkGrey, letterSpacing: 0.8, lineHeight: 22.4, marginBottom: 19 },
            ]}>
            CC message to:
          </Text>
          <View>
            <MultiTextSelectInput
              values={ccEmails}
              setValues={setCCEmails}
              currValue={ccEmailInputVal}
              setCurrValue={setCCEmailInputVal}
            />
          </View>
          {error ? <Text style={{ color: 'red', marginBottom: 10 }}>{error}</Text> : null}

          <View style={{ flex: 1 }} />
          <View style={[{ paddingHorizontal: 20, backgroundColor: 'white', paddingBottom: useSafeBottomPadding() }]}>
            <PrimaryButton title="SEND MESSAGE" onPress={send} />
          </View>
        </View>
      </View>
    </LoginBase>
  );
};

export default SendMessage;
