import { Action } from 'redux';

export enum ActionType {
  SET_INVITATION_ID = 'SET_INVITATION_ID',
  ADD_BAD_INVITE = 'ADD_BAD_INVITE',

  RESET = 'USER_RESET',
}

interface InviteAction extends Action<ActionType> {
  invitationId: string;
}

interface InviteState {
  invitationId?: string;
  badInvitations: string[];
}

export const setInvitationId = (invitationId: string): InviteAction => ({
  type: ActionType.SET_INVITATION_ID,
  invitationId,
});

export const resetInvitationId = (): InviteAction => ({
  type: ActionType.SET_INVITATION_ID,
  invitationId: '',
});

export const addBadInvite = (invitationId: string): InviteAction => ({
  type: ActionType.ADD_BAD_INVITE,
  invitationId,
});

const initialState: InviteState = {
  invitationId: undefined,
  badInvitations: [],
};

const reducer = (state = initialState, action: InviteAction): InviteState => {
  switch (action.type) {
    case ActionType.SET_INVITATION_ID:
      return { ...state, invitationId: action.invitationId };
    case ActionType.ADD_BAD_INVITE:
      state.badInvitations.push(action.invitationId);
      return { ...state };
    case ActionType.RESET:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
