import React, { useCallback, useLayoutEffect, useState } from 'react';
import { Alert, View } from 'react-native';
import { Asset } from 'react-native-image-picker';
import { useDispatch, useSelector } from 'react-redux';
import { useSafeBottomPadding } from '../../components/Hooks';
import ModalChooseAvatar from '../../components/ModalChooseAvatar';
import ScrollView from '../../components/ScrollView';
import { EditOrganisationScreenProps } from '../../navigators/Types';
import { userStateSelector } from '../../redux/Store';
import { setListing } from '../../redux/User';
import { OrgListing, SaveOrg } from '../../services/ListingService';
import Style from '../../styles/style';
import EditOrganizationForm from './components/EditOrganizationForm';
import { OrganizationParams } from './utils/model';
import { popSafely } from '../../tools/NavHelpers';
import LoginBase from '../auth/LoginBase';
import { logAnalyticsEvent } from '../../services/Firebase';
import { UserType } from '../../redux/Register';
import { useLinkTo } from '@react-navigation/native';
import { captureException } from '@sentry/react';

const EditOrganisation = ({ route, navigation }: EditOrganisationScreenProps) => {
  const linkTo = useLinkTo()
  const { listing } = useSelector(userStateSelector);
  const dispatch = useDispatch();

  const [busy, setBusy] = useState(false);

  const [image, setImage] = useState<Asset>();

  const handleSubmit = useCallback(
    (values: OrganizationParams) => {
      setBusy(true);

      const { name, services, streetAddress, email, phone, bio } = values;

      const orgData: OrgListing = {
        id: listing?.id || '',
        name: name as string,
        services: services,
        streetAddress: streetAddress,
        email: email,
        phone: phone,
        bio: bio,
        imageUrl: listing?.imageUrl || '',
      };

      // Save
      SaveOrg(orgData, image)
        .then(newListing => {
          dispatch(setListing(newListing));
          logAnalyticsEvent('listing_updated', {
            new: Boolean(listing?.id?.length),
            type: UserType.Organisation,
            hasImage: Boolean(newListing?.imageUrl?.length),
            hasName: Boolean(newListing?.name?.length),
            hasHourlyRate: Boolean(newListing?.hourlyRate),
            hasDailyRate: Boolean(newListing?.dailyRate),
            hasReportCost: Boolean(newListing?.reportCost),
            hasBio: Boolean(newListing?.bio?.length),
            hasPhone: Boolean(newListing?.phone?.length),
            hasMob: Boolean(newListing?.mobile?.length),
            hasEmail: Boolean(newListing?.email?.length),
            hasAddress: Boolean(newListing?.streetAddress?.length),
            hasSuburb: Boolean(newListing?.suburb?.length),
            hasPostcode: Boolean(newListing?.postcode?.length),
            hasState: Boolean(newListing?.state?.length),
            hasServices: Boolean(newListing?.services?.length),
            hasLocations: Boolean(newListing?.locations?.length),
            hasAccreditations: Boolean(newListing?.accreditations?.length),
            hasCalendar: Boolean(newListing?.nylasConnected),
            hasEvents: Boolean(newListing?.events?.length),
            isDoyles: Boolean(newListing.isDoyles),
            hasDoylesImages: Boolean(newListing?.doylesImageUrls?.length),
          })

          if (listing?.id) {
            linkTo({
              screen: 'ViewOrganisation', 
              params: {
                id: listing?.id,
              },
            })
          } else {
            navigation.replace('ViewOrganisation');
          }
        })
        .catch(error => {
          alert('Error saving organisation');
          captureException(error)
          setBusy(false);
        });
    },
    [dispatch, image, listing?.id, listing?.imageUrl, navigation],
  );

  return (
    <LoginBase>
      <View style={[Style.footer, { justifyContent: 'center', marginTop: 20 }]}>
        <ModalChooseAvatar image={image} imageUrl={listing?.imageUrl} onSetImage={setImage} />

        <EditOrganizationForm loading={busy} listing={listing} onSubmit={handleSubmit} />
      </View>
    </LoginBase>
  );
};

export default EditOrganisation;
