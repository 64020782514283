import React, { FunctionComponent } from 'react';
import { Text, TextProps as RNTextProps } from 'react-native';

type FontFamily = 'Barlow' | 'Barlow-Medium' | 'Barlow-SemiBold' | 'Barlow-Bold' | 'Barlow-Italic';

type TextProps = {
  fontFamily?: FontFamily;
  fontSize?: number;
} & RNTextProps;

const CustomText: FunctionComponent<TextProps> = props => {
  let fontWeight = '400' as '400' | '500' | '600' | '700';
  if (props.fontFamily === 'Barlow-Medium') {
    fontWeight = '500';
  }
  if (props.fontFamily === 'Barlow-SemiBold') {
    fontWeight = '600';
  }
  if (props.fontFamily === 'Barlow-Bold') {
    fontWeight = '700';
  }
  let fontStyle = 'normal' as 'normal' | 'italic' | undefined;
  if (props.fontFamily === 'Barlow-Italic') {
    fontStyle = 'italic';
  }
  return (
    <Text
      {...props}
      style={[
        {
          fontFamily: 'Barlow',
          fontStyle,
        },
        props.style,
        { fontWeight },
      ]}
    >
      {props.children}
    </Text>
  );
};

export default CustomText;
