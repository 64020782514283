import SegmentedControl from '@react-native-segmented-control/segmented-control';
import React from 'react';
import { View, Text } from 'react-native';
import { UserType } from '../../../redux/Register';
import { SubscriptionPeriod } from '../../../services/StripeService';
import { FontSize, Colour, Barlow } from '../../../styles/style';

const BulletItem = (props: { text: string }) => (
  <View style={{ flexDirection: 'row', alignItems: 'flex-start', margin: 5 }}>
    <View style={{ alignItems: 'center', marginRight: 10 }}>
      <Text style={[FontSize.medium, { color: 'white' }]}>{'\u2022'}</Text>
    </View>
    <View style={{ flex: 1 }}>
      <Text style={[Barlow.medium, FontSize.medium, { color: 'white' }]}>{props.text}</Text>
    </View>
  </View>
);

interface Props {
  userType?: UserType;
  subscriptionPeriod: SubscriptionPeriod;
  onSetSubscription(val: number): void;
}

const PaymentHelperBox = (props: Props) => {
  const { userType, subscriptionPeriod, onSetSubscription } = props;

  return (
    <>
      <View style={{ alignItems: 'center' }}>
        <View>
          <Text style={[FontSize.extraLarge, { color: 'white', letterSpacing: 3 }]}>{`3 Months\nFree Trial`}</Text>
        </View>
      </View>
      <View style={{ marginVertical: 20 }}>
        <BulletItem text="Seamlessly connect with clients, colleagues and other service providers" />
        <BulletItem text="Market yourself to potential clients at the time they are looking to engage" />
        <BulletItem text="Calendar integration means prospective clients know exactly when you’re available" />
        {userType === UserType.Organisation && (
          <BulletItem text="Include a number of individual employee profiles as part of your organisation " />
        )}
      </View>
      <SegmentedControl
        values={['Monthly', 'Annually']}
        selectedIndex={subscriptionPeriod}
        onChange={e => {
          onSetSubscription(e.nativeEvent.selectedSegmentIndex);
        }}
        tintColor="white"
        fontStyle={{ fontFamily: 'Barlow', color: 'white' }}
        activeFontStyle={{
          fontFamily: 'Barlow',
          color: Colour.DarkBlue,
        }}
        style={{
          backgroundColor: '#00000030',
          height: 40,
        }}
        backgroundColor="#00000030"
      />
    </>
  );
};

export default PaymentHelperBox;
