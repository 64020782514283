// import { CardField, CardFieldInput, PaymentMethod, useStripe } from '@stripe/stripe-react-native';
import React, { useRef, useState } from 'react';
import { CardElement, Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Alert, Keyboard, Modal, StyleSheet, Text, View } from 'react-native';
import { Button } from 'react-native-elements';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { PaymentDetails } from '../../../services/StripeService';
import { Colour, FontSize } from '../../../styles/style';
import { PaymentMethod, Stripe } from '@stripe/stripe-js';
import { captureException } from '@sentry/react';

interface Props {
  visible: boolean;
  paymentDetail?: PaymentDetails;
  onPaymentSuccess(paymentResult: PaymentMethod): void;
  onClose(): void;
}

const CreditCardPaymentBox = (props: Props) => {
  const { visible, paymentDetail, onPaymentSuccess, onClose } = props;
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);

  const [completeCard, setCompleteCard] = useState(false);

  const paymentCancelledRef = useRef(false);

  const handlePayPress = async () => {
    if (!paymentDetail) {
      return;
    }

    setLoading(true);

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      elements: elements,
    });

    if (error) {
      alert(error.message);
      captureException(error)
      setLoading(false);
      return;
    }

    if (!paymentMethod) {
      setLoading(false);
      return;
    }

    if (paymentCancelledRef.current) {
      return;
    }

    onPaymentSuccess(paymentMethod);
    onClose();
    Keyboard.dismiss();
  };

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={visible}
      onShow={() => {
        setLoading(false);
        setCompleteCard(false);
        paymentCancelledRef.current = false;
      }}
    >
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0,0,0,0.5)' }}>
        <View
          style={[
            {
              backgroundColor: 'white',
              borderRadius: 16,
              minWidth: '70%',
              maxWidth: 500,
              overflow: 'hidden',
              marginLeft: 16,
              marginRight: 16,
              marginBottom: useSafeAreaInsets().bottom,
            },
          ]}
        >
          <View style={{ backgroundColor: Colour.Blue }}>
            <Text style={[FontSize.large, { padding: 10, textAlign: 'center', color: 'white' }]}>Enter your card</Text>
          </View>

          <View style={{ padding: 4 }}>
            <CardElement
              options={{
                hidePostalCode: true,
                style: {
                  base: {
                    padding: '10px',
                  },
                },
              }}
              onChange={cardDetails => {
                if (completeCard !== cardDetails.complete) {
                  setCompleteCard(cardDetails.complete);
                }
              }}
            />
          </View>

          <View style={{ flexDirection: 'row', padding: 16, justifyContent: 'space-between' }}>
            <Button
              type="clear"
              title="Cancel"
              onPress={() => {
                paymentCancelledRef.current = true;
                onClose();
              }}
              buttonStyle={{ width: 120 }}
            />
            <Button
              type="clear"
              testID="doneButton"
              loading={loading}
              disabled={loading || !completeCard}
              title="Done"
              onPress={handlePayPress}
              buttonStyle={{ width: 120 }}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default CreditCardPaymentBox;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    paddingTop: 20,
    paddingHorizontal: 16,
  },
  cardField: {
    width: '100%',
    height: 50,
    marginVertical: 30,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    marginLeft: 12,
  },
  input: {
    height: 44,
    borderBottomColor: '#0A2540',
    borderBottomWidth: 1.5,
  },
});

const inputStyles: CardFieldInput.Styles = {
  borderWidth: 1,
  backgroundColor: '#FFFFFF',
  borderColor: '#999999',
  borderRadius: 8,
  fontSize: 14,
  textColor: '#000000',
  placeholderColor: '#999999',
};
