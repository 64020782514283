import { useLinkTo, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useEffect, useState } from 'react';
import { Controller, NestedValue, useForm } from 'react-hook-form';
import { Image, TouchableOpacity, View } from 'react-native';
import { Divider } from 'react-native-elements';
import { useDispatch, useSelector } from 'react-redux';
import { SecondaryButton, PrimaryButton } from '../../../components/Button';
import { selectedTimesHumanReadable } from '../../../components/Calendar/CalendarHelper';
import Chip from '../../../components/Chip';
import InlinePicker from '../../../components/InlinePicker';
import ModalMultiSelect from '../../../components/ModalMultiSelect';
import ModalRateSelect from '../../../components/ModalRateSelect';
import { AllStackParamList } from '../../../navigators/Types';
import { Filter, FilterType, Rate, SearchParams, setDateRange, setSearchParams } from '../../../redux/Search';
import { pickListsStateSelector, searchStateSelector } from '../../../redux/Store';
import { Barlow, Colour, FontColour, FontSize } from '../../../styles/style';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { ISearchFilterParams } from '../utils/model';
import Text from '../../../components/Text';
import ToggleSwitch from 'toggle-switch-react-native';
import image_doyles from '../../../assets/images/doyles.png';
import { popSafely } from '../../../tools/NavHelpers';
import moment from 'moment';

const headerTextStyle = [Barlow.medium, FontSize.large, FontColour.darkBlue, { paddingLeft: 20, paddingVertical: 10 }];

const sortList = ['Available for all selected days', 'Favorited'];

const defaultSearchForm: ISearchFilterParams = {
  services: [] as unknown as NestedValue<string[]>,
  locations: [] as unknown as NestedValue<string[]>,
};

const SearchFilterForm = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation<StackNavigationProp<AllStackParamList>>();
  const linkTo = useLinkTo();
  const [valueDoyles, setValuesDoyles] = useState<Filter>({
    type: FilterType.Doyles,
    value: false,
  });

  const { services, locations } = useSelector(pickListsStateSelector);
  const { dates, params } = useSelector(searchStateSelector);

  const [isEnabled, setIsEnabled] = useState(params?.filters?.find(filter => filter.type === FilterType.Doyles)?.value);

  const { control, setValue, reset, watch } = useForm<ISearchFilterParams>({
    defaultValues: {
      services: params?.filters?.find(filter => filter.type === FilterType.Service)?.value as unknown as NestedValue<
        string[]
      >,
      locations: params?.filters?.find(filter => filter.type === FilterType.Location)?.value as unknown as NestedValue<
        string[]
      >,
      rates: params?.filters?.find(filter => filter.type === FilterType.Rate)?.value as Rate,
      dates,
      sort: params?.sort,
      // isDoyles: params?.filters?.find(filter => filter.type === FilterType.Doyles)?.value as ISearchDoyles
    },
  });

  useEffect(() => {
    setValue('dates', dates);
  }, [dates, setValue]);

  const toggleSwitch = () => {
    setIsEnabled(previousState => {
      const modifiedValue = !previousState;
      setValue('isDoyles', modifiedValue);
      setValuesDoyles({
        type: FilterType.Doyles,
        value: modifiedValue,
      });
      return modifiedValue;
    });
  };

  const servicesValue = watch('services');
  const locationsValue = watch('locations');
  const ratesValue = watch('rates');

  useEffect(() => {
    const startDate = dates && dates[0];
    const endDate = dates && dates.length > 1 ? dates[1] : moment(startDate).add(4, 'hour').toISOString();

    const newParams: SearchParams = {
      name: params?.name,
      filters: [
        ...(servicesValue?.length
          ? [
              {
                type: FilterType.Service,
                value: servicesValue,
              },
            ]
          : []),

        ...(locationsValue?.length
          ? [
              {
                type: FilterType.Location,
                value: locationsValue,
              },
            ]
          : []),
        ...(dates?.length
          ? [
              {
                type: FilterType.DateRange,
                value: { startDate, endDate },
              },
            ]
          : []),
        ...(ratesValue
          ? [
              {
                type: FilterType.Rate,
                value: ratesValue,
              },
            ]
          : []),
        ...(valueDoyles.value ? [valueDoyles] : []),
      ],
    };

    dispatch(setSearchParams(newParams));
  }, [servicesValue, locationsValue, ratesValue, dates, valueDoyles]);

  return (
    <>
      <View style={{ flex: 1 }}>
        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <Text style={headerTextStyle}>Sort by</Text>
          <TouchableOpacity
            style={[{ margin: 10 }]}
            onPress={() => {
              reset(defaultSearchForm);
              setIsEnabled(_ => {
                const modifiedValue = false;
                setValue('isDoyles', modifiedValue);
                return modifiedValue;
              });
              setValuesDoyles({
                type: FilterType.Doyles,
                value: false,
              });
              dispatch(setDateRange(undefined));
            }}>
            <Text style={{ color: Colour.DarkBlue }} fontFamily="Barlow-Medium">
              Reset
            </Text>
          </TouchableOpacity>
        </View>
        <Divider />

        <View style={{ paddingLeft: 20 }}>
          <Controller
            control={control}
            name="sort"
            render={({ field: { onChange, value } }) => (
              <InlinePicker value={value} onChange={onChange} items={sortList} />
            )}
          />
        </View>

        <Text style={headerTextStyle}>Filter by</Text>
        <Divider />

        <View style={{ marginHorizontal: 20 }}>
          <Controller
            control={control}
            name="services"
            render={({ field: { onChange, value } }) => (
              <ModalMultiSelect
                value={value}
                onChange={onChange}
                items={services}
                selectText="Add Service"
                headerText="Services / Specialisations"
              />
            )}
          />

          <Divider />

          <Controller
            control={control}
            name="locations"
            render={({ field: { onChange, value } }) => (
              <ModalMultiSelect
                value={value}
                onChange={onChange}
                items={locations}
                selectText="Add location"
                headerText="Locations"
              />
            )}
          />

          <Divider />

          <SecondaryButton
            title="Date Range"
            iconRight
            icon={<DateRangeIcon sx={{ color: Colour.DarkBlue }} />}
            onPress={() => linkTo('/calendar')}
          />
          {dates && (
            <Chip onDelete={() => dispatch(setDateRange(undefined))}>
              <Text>{selectedTimesHumanReadable(dates, 4)}</Text>
            </Chip>
          )}
          <Divider />

          <Controller
            control={control}
            name="rates"
            render={({ field: { onChange, value } }) => <ModalRateSelect value={value} onChange={onChange} />}
          />

          <Divider />
        </View>

        <View style={{ marginHorizontal: 20, flexDirection: 'row', marginTop: 20 }}>
          <ToggleSwitch
            isOn={isEnabled}
            onColor={Colour.NavyBlue}
            offColor={Colour.Grey}
            size="large"
            onToggle={toggleSwitch}
          />
          <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 20 }}>
            <View
              style={{
                width: 30,
                height: 30,
                backgroundColor: Colour.LightYellow,
                borderRadius: 50,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Image source={image_doyles} style={{ width: 22, height: 22 }} />
            </View>
            <Text style={{ fontFamily: Barlow.regular.fontFamily, marginLeft: 10 }}>Doyles Recommended</Text>
          </View>
        </View>
      </View>

      <View style={{ paddingHorizontal: 20, paddingTop: 10 }}>
        <PrimaryButton title="APPLY FILTER" onPress={() => popSafely(navigation)} />
      </View>
    </>
  );
};

export default SearchFilterForm;
