import { Action } from 'redux';
import { createSelector } from 'reselect';

// MODELS
export enum SortType {
  Available = '0',
  Favourited = '1',
}

export enum FilterType {
  Service,
  Location,
  DateRange,
  Rate,
  Doyles,
}

export interface Rate {
  ratePeriod: number;
  rate?: number;

  onlyHourly: boolean;
  onlyDaily: boolean;

  reportRate?: number;
  onlyReport: boolean;
}

export interface Filter {
  type: FilterType;
  value: string | string[] | { startDate: string; endDate: string } | number | Rate | boolean;
}

export interface SearchParams {
  name?: string;
  sort?: string;
  filters?: Filter[];
}

// ACTION TYPES
export enum ActionType {
  SET_SEARCH_PARAMS = 'SET_SEARCH_PARAMS',
  SET_DATE_RANGE = 'SET_DATE_RANGE',
  RESET = 'USER_RESET',
}

interface SearchAction extends Action<ActionType> {
  params?: SearchParams;
  dates?: string[];
}

// ACTIONS
export const setSearchParams = (params?: SearchParams): SearchAction => ({
  type: ActionType.SET_SEARCH_PARAMS,
  params,
});

export const setDateRange = (dates?: string[]): SearchAction => ({
  type: ActionType.SET_DATE_RANGE,
  dates,
});

// STATE
interface SearchState {
  params?: SearchParams;
  dates?: string[];
  recent: SearchParams[];
}

const initialState: SearchState = {
  params: undefined,
  dates: undefined,
  recent: [],
};

// REDUCER
const reducer = (state = initialState, action: SearchAction): SearchState => {
  switch (action.type) {
    case ActionType.SET_SEARCH_PARAMS: {
      const { params } = action;
      const recent = [...state.recent];

      if (params && (params.name || params.filters?.length! > 0)) {
        if (recent.length > 0 && recent[recent.length - 1].name !== undefined && params.name !== undefined) {
          recent.pop();
        }
        if (recent.length > 2) {
          recent.splice(0, recent.length - 2);
        }
        recent.push(params);
      }

      return { ...state, params, recent };
    }
    case ActionType.SET_DATE_RANGE:
      return { ...state, dates: action.dates };
    case ActionType.RESET:
      return initialState;
    default:
      return state;
  }
};

export default reducer;

export const searchStateSelector = (state: any) => state.search;

export const selectSearchParams = createSelector(
  [searchStateSelector],
  (searchState) => searchState.params
);

export const selectRecentSearchParams = createSelector(
  [searchStateSelector],
  (searchState) => searchState.recent
);