import React from 'react';
import { Controller, NestedValue, useForm } from 'react-hook-form';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { Image, Input } from 'react-native-elements';
import { useSelector } from 'react-redux';
import { PrimaryButton } from '../../../components/Button';
import ModalChooseDoylesImages from '../../../components/ModalChooseDoylesImages';
import ModalMultiSelect from '../../../components/ModalMultiSelect';
import Text from '../../../components/Text';
import { pickListsStateSelector } from '../../../redux/Store';
import { Listing } from '../../../redux/User';
import Style, { Barlow, Colour, FontSize } from '../../../styles/style';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CloseIcon from '@mui/icons-material/Close';

import { IListingParams } from '../utils/model';

const stylesheet = StyleSheet.create({
  errorText: { color: 'red', paddingRight: 16 },
});

interface Props {
  openAddService(): void;
  onNextStep4(values: IListingParams): void;
  setDoylesImages(image: Asset[]): void;
  onRemoveDoylesImage(filename: string): void;
  isDoylesAccount?: boolean;
  listing?: Listing;
  loading: boolean
  doylesImages?: Asset[];
}

const CreateListingStep3 = (props: Props) => {
  const { listing, openAddService, onNextStep4, setDoylesImages, doylesImages, onRemoveDoylesImage, isDoylesAccount, loading } =
    props;
  const { services, locations } = useSelector(pickListsStateSelector);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IListingParams>({
    defaultValues: {
      services: listing?.services
        ? (listing?.services as unknown as NestedValue<string[]>)
        : ([] as unknown as NestedValue<string[]>),
      locations: listing?.locations
        ? (listing?.locations as unknown as NestedValue<string[]>)
        : ([] as unknown as NestedValue<string[]>),
      accreditations: listing?.accreditations || '',
    },
  });

  return (
    <>
      <View style={{ flex: 1 }}>
        <Text
          fontFamily="Barlow-Medium"
          style={{ color: Colour.DarkBlue, fontSize: 24, paddingTop: 20, paddingBottom: 15 }}
        >
          Services &amp; Specialisations
        </Text>

        <View style={{ borderBottomColor: Colour.Grey, borderBottomWidth: 3, marginLeft: -20, marginRight: -40 }} />

        <Text style={[Style.label, {marginTop: 15, marginBottom: 15}]}>Services / Specialisations</Text>
        <Text style={[FontSize.normal, { color: Colour.DarkGrey }]}>
          Be sure to tick each level of specialisation. For instance, under 'Barrister' select 'Family Law'.
        </Text>

        <Controller
          control={control}
          name="services"
          rules={{
            validate: {
              minService: (values) => {
                return (values && !!values.length) || 'Please select at least one service / specialisation'
              },
              maxService: values =>
                (values && values.length <= 10) || 'You may only select up to ten services / specialisations',
            },
          }}
          render={({ field: { onChange, value } }) => {
            return (
            <ModalMultiSelect
              value={value}
              onChange={onChange}
              items={services}
              selectText="Add Service / Specialisation"
              headerText="Services / Specialisations"
            />
          )}}
        />

        {errors?.services?.message && 
          <Text style={stylesheet.errorText}>{errors?.services?.message || ' '}</Text>
        }

        <View style={{ borderBottomColor: Colour.Grey, borderBottomWidth: 3, marginTop: 15, marginLeft: -20, marginRight: -40 }} />

        <Text style={[Style.label, {marginTop: 15, marginBottom: 15}]}>Location(s)</Text>
        <Text style={[FontSize.normal, { color: Colour.DarkGrey }]}>
          Select your main practice locations/suburbs and any other states/cities serviced
        </Text>
        <Controller
          control={control}
          name="locations"
          rules={{
            validate: {
              minLocation: values => (values && !!values.length) || 'Please select at least one location',
            },
          }}
          render={({ field: { onChange, value } }) => (
            <ModalMultiSelect
              value={value}
              onChange={onChange}
              items={locations}
              selectText="Add location"
              headerText="Locations"
            />
          )}
        />

        {errors?.locations?.message && 
          <Text style={stylesheet.errorText}>{errors?.locations?.message || ' '}</Text>
        }

        <TouchableOpacity
          onPress={openAddService}
          style={{
            marginVertical: 15,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(10,123,184,0.2)',
            paddingTop: 9,
            paddingBottom: 12,
            paddingLeft: 18,
            paddingRight: 18,
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Text style={{ width: '92%', color: Colour.DarkBlue, fontSize: 16 }}>
            Service, specialisation or location not listed? Tap here
          </Text>
          <KeyboardArrowRightIcon sx={{ color: Colour.DarkBlue }} />
        </TouchableOpacity>

        <View style={{ borderBottomColor: Colour.Grey, borderBottomWidth: 3, marginTop: 15, marginLeft: -20, marginRight: -40 }} />

        <Text style={[Style.label, {marginTop: 15, marginBottom: 15}]}>Accreditation(s)</Text>
        <Controller
          control={control}
          name="accreditations"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              value={value}
              onChangeText={onChange}
              errorMessage={error?.message}
              multiline
              placeholder="Add description"
              numberOfLines={4}
              inputStyle={{ fontFamily: 'Barlow', textAlignVertical: 'top', minHeight: 100 }}
            />
          )}
        />
        {isDoylesAccount && (
          <>
            <View style={{ borderBottomColor: Colour.Grey, borderBottomWidth: 3, marginTop: 15, marginLeft: -20, marginRight: -40 }} />
            <Text style={Style.label}>Upload Doyle’s Recommendation</Text>
            <ModalChooseDoylesImages onSetImages={setDoylesImages} />
          </>
        )}
        {isDoylesAccount && listing?.doylesImageUrls ? (
          listing?.doylesImageUrls.map(images => (
            <View
              key={images}
              style={{
                backgroundColor: Colour.Grey,
                marginBottom: 10,
                flexDirection: 'row',
                alignItems: 'center',
                padding: 10,
              }}
            >
              <View style={{ flex: 1 }}>
                <Image source={{uri: images}} style={{height: 50, width: 50}}/>
              </View>
              <TouchableOpacity
                style={{ width: 30, alignItems: 'flex-end' }}
                onPress={() => onRemoveDoylesImage(images)}
              >
                <CloseIcon sx={{color: Colour.DarkerBlue}} />
              </TouchableOpacity>
            </View>
          ))
        ) : (
          <></>
        )}
        {isDoylesAccount && doylesImages ? (
          doylesImages.map(images => (
            <View
              key={images.name}
              style={{
                backgroundColor: Colour.Grey,
                marginBottom: 10,
                flexDirection: 'row',
                alignItems: 'center',
                padding: 10,
              }}
            >
              <View style={{ flex: 1 }}>
                <Text style={{ fontFamily: Barlow.medium.fontFamily }}>{images.name}</Text>
              </View>
              <TouchableOpacity
                style={{ width: 30, alignItems: 'flex-end' }}
                onPress={() => onRemoveDoylesImage(images.name!)}
              >
                <CloseIcon sx={{color: Colour.DarkerBlue}} />
              </TouchableOpacity>
            </View>
          ))
        ) : (
          <></>
        )}
      </View>
      <PrimaryButton
        title="NEXT"
        loading={loading}
        iconRight
        icon={
          <View style={{ justifyContent: 'center' }}>
            <KeyboardArrowRightIcon sx={{ color: 'white' }} />
          </View>
        }
        onPress={handleSubmit(onNextStep4)}
      />
    </>
  );
};

export default CreateListingStep3;
