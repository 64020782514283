import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Platform, View } from 'react-native';
import { SearchBar } from 'react-native-elements';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import Avatar from '@mui/material/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import { ToInitials } from '../components/TextHelper';
import { searchStateSelector, setSearchParams } from '../redux/Search';
import FavoriteListScreen from '../screens/search/FavoriteListScreen';
import SearchListScreen from '../screens/search/SearchListScreen';
import style, { Colour } from '../styles/style';
import { SearchNavigatorProps, SearchTabParamList } from './Types';
import { useLinkTo } from '@react-navigation/native';
import LoginBase from '../screens/auth/LoginBase';
import { MenuContext } from '../../App';
import { logAnalyticsEvent } from '../services/Firebase';
import _debounce from 'lodash/debounce';
import { userStateSelector } from '../redux/Store';

const Tab = createBottomTabNavigator<SearchTabParamList>();

const SearchNavigator = ({ route, navigation }: SearchNavigatorProps) => {
  const dispatch = useDispatch();
  const linkTo = useLinkTo();
  const userState = useSelector(userStateSelector);
  const searchState = useSelector(searchStateSelector);
  const [menuContext, setMenuContext] = useContext(MenuContext);
  const [searchText, setSearchText] = useState('');
  const [isMainSearch, setIsMainSearch] = useState(true);

  const debouncedOnChangeText = useCallback(
    _debounce(name => {
      logAnalyticsEvent('text_search_performed', { text: name });
      dispatch(setSearchParams({ filters: searchState.params?.filters || [], name: name || undefined }));
    }, 300),
    [dispatch, searchState.params],
  );

  const onChangeText = useCallback(
    (name = '') => {
      setSearchText(name);
      debouncedOnChangeText(name);
    },
    [debouncedOnChangeText],
  );

  const clearFilters = () => {
    setSearchText('');
    dispatch(setSearchParams({ name: '', filters: searchState.params?.filters }));
  };

  const initials = useMemo(
    () => (userState.profile?.name && !userState.listing?.imageUrl ? ToInitials(userState.profile?.name) : undefined),
    [userState.profile?.name, userState.listing?.imageUrl],
  );

  return (
    <LoginBase>
      <View
        style={{
          ...style.footer,
          marginTop: 30,
          maxHeight: 700,
        }}>
        <View style={{ backgroundColor: Colour.Blue, borderRadius: 20 }}>
          <View
            style={{
              backgroundColor: Colour.Blue,
              flexDirection: 'row',
              alignItems: 'center',
              borderTopRightRadius: 20,
              borderTopLeftRadius: 20,
            }}>
            <Avatar
              sx={{ bgcolor: 'rgba(1,1,1,0.2)', marginLeft: 1, marginRight: 1, cursor: 'pointer' }}
              src={userState.listing?.imageUrl ? userState.listing?.imageUrl : undefined}
              children={initials}
              onClick={() => setMenuContext(!menuContext)}
            />
            <View style={{ flex: 1, marginHorizontal: 10 }}>
              <SearchBar
                platform={Platform.OS === 'ios' ? 'ios' : 'android'}
                searchIcon={<SearchOutlinedIcon sx={{ color: 'white' }} />}
                cancelIcon={<ArrowBackIcon sx={{ color: 'white' }} />}
                clearIcon={<ClearIcon sx={{ color: 'white' }} onClick={() => clearFilters()} />}
                placeholder="Search Listings"
                placeholderTextColor={Colour.Grey}
                inputContainerStyle={{
                  backgroundColor: 'rgba(0,0,0,0.2)',
                  borderRadius: 10,
                }}
                containerStyle={{
                  flexGrow: 1,
                  backgroundColor: 'transparent',
                  borderRadius: 5,
                  borderWidth: 0,
                }}
                inputStyle={{
                  fontSize: 16,
                  color: 'white',
                  fontFamily: 'Barlow',
                }}
                value={searchText}
                onChangeText={onChangeText}
              />
            </View>
            {!isMainSearch && (
              <View style={{ position: 'relative' }}>
                <TuneOutlinedIcon
                  testID="refineSearch"
                  sx={{ color: 'white', marginRight: 3, cursor: 'pointer' }}
                  onClick={() => {
                    linkTo('/refinesearch');
                  }}
                />
                {searchState.params?.filters.length !== 0 && <p
                  style={{
                    width: '12px',
                    height: '12px',
                    background: 'red',
                    position: 'absolute',
                    color: 'white',
                    top: '-13px',
                    right: '19px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '8px',
                    fontSize: '10px',
                  }}>
                  {searchState.params?.filters.length}
                </p>}
              </View>
            )}
          </View>
        </View>
        <View style={{ width: '100%', height: '100%' }} id={'searchTabWrapper'}>
          <Tab.Navigator
            initialRouteName="Search"
            id={'Testing'}
            screenOptions={({ route }) => ({
              tabBarLabelStyle: { fontFamily: 'Barlow' },
              tabBarStyle: {
                overflow: 'hidden',
                borderBottomLeftRadius: 30,
                borderBottomRightRadius: 30,
              },
              headerShown: false,
            })}>
            <Tab.Screen
              name="Favorite"
              options={{
                tabBarIcon: ({ color, size }) => <StarBorderOutlinedIcon sx={{ color, height: size, width: size }} />,
              }}
              component={FavoriteListScreen}
            />
            <Tab.Screen
              name="Search"
              options={{
                tabBarIcon: ({ color, size }) => (
                  <SearchOutlinedIcon sx={{ color, height: size, width: size }} />
                  // <Icon name="search" size={size} color={color} tvParallaxProperties={undefined} />
                ),
              }}>
              {() => <SearchListScreen isMainSearch={isMainSearch} setIsMainSearch={setIsMainSearch} />}
            </Tab.Screen>
          </Tab.Navigator>
        </View>
      </View>
    </LoginBase>
  );
};

export default SearchNavigator;
