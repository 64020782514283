import { AnyAction, applyMiddleware, combineReducers, createStore } from 'redux';
import thunk, { ThunkAction } from 'redux-thunk';
import calendarReducer from './Calendar';
import inviteReducer from './Invites';
import pickListsReducer from './PickLists';
import registerReducer from './Register';
import searchReducer from './Search';
import stripeReducer from './Stripe';
import userReducer from './User';

// Thunk action helpers
export type ThunkResult<R> = ThunkAction<R, RootState, undefined, AnyAction>;
export type ThunkPromise = ThunkResult<Promise<void>>;

const rootReducer = combineReducers({
  calendar: calendarReducer,
  user: userReducer,
  register: registerReducer,
  pickLists: pickListsReducer,
  search: searchReducer,
  invites: inviteReducer,
  stripe: stripeReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const store = createStore(rootReducer, applyMiddleware(thunk));

// SELECTORS
export const rootStateSelector = (state: RootState) => state;
export const calendarStateSelector = (state: RootState) => state.calendar;
export const userStateSelector = (state: RootState) => state.user;
export const authStateSelector = (state: RootState) => state.user.auth;
export const profileStateSelector = (state: RootState) => state.user.profile;
export const registerStateSelector = (state: RootState) => state.register;
export const pickListsStateSelector = (state: RootState) => state.pickLists;
export const searchStateSelector = (state: RootState) => state.search;
export const inviteSelector = (state: RootState) => state.invites;
export const stripeSelector = (state: RootState) => state.stripe;

export default store;
