import React from 'react';
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native';
import { ListItem } from 'react-native-elements';
import CheckIcon from '@mui/icons-material/Check';
import { Barlow, Colour } from '../styles/style';

export interface InlinePickerProps {
  value?: string;
  items: string[];
  onChange: (value: string) => void;
  containerStyle?: StyleProp<ViewStyle>;
  itemStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  itemDivider?: boolean;
}

const defaultContainerStyle: StyleProp<ViewStyle> = {};
const defaultTextStyle: StyleProp<TextStyle> = Barlow.regular;

const InlinePicker = (props: InlinePickerProps) => {
  const { value, items, onChange } = props;

  return (
    <View style={props.containerStyle || defaultContainerStyle}>
      {items.map((item, i) => (
        <ListItem
          containerStyle={{ paddingVertical: 15, paddingHorizontal: 20 }}
          key={i}
          onPress={() => onChange(i.toString())}
          bottomDivider={props.itemDivider ?? true}
          hasTVPreferredFocus={undefined}
          tvParallaxProperties={undefined}
        >
          <ListItem.Content>
            <ListItem.Title style={props.textStyle || defaultTextStyle}>{item}</ListItem.Title>
          </ListItem.Content>
          {value === i.toString() && <CheckIcon sx={{ color: Colour.Blue, size: 18 }} />}
        </ListItem>
      ))}
    </View>
  );
};

export default InlinePicker;
