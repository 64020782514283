import React from 'react';
import styled from 'styled-components/native';
import { useLinkTo } from '@react-navigation/native';

const DeleteButton = styled.TouchableWithoutFeedback``;
const Text = styled.Text`
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-family: Barlow;
  font-weight: 500;
  font-size: 18;
`;

const View = styled.View`
  background-color: #ef4444;
  height: 50;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
`;

const DeleteProfileButton = () => {
  const linkTo = useLinkTo();
  return (
    <DeleteButton
      style={{cursor: 'pointer'}}
      onPress={() => {
        linkTo('/deleteprofile');
      }}
    >
      <View>
        <Text>Cancel and Delete Data</Text>
      </View>
    </DeleteButton>
  );
};

export default DeleteProfileButton;
