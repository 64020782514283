import React, { useEffect, useMemo } from 'react';
import { FlatList, Image, Linking, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import img_editListing from '../assets/images/iconMenuEditListing@3x.png';
import img_help from '../assets/images/iconMenuHelp@3x.png';
import img_logout from '../assets/images/iconMenuLogOut@3x.png';
import img_organisation from '../assets/images/iconMenuOrganisation@3x.png';
import img_plans from '../assets/images/iconMenuPlans@3x.png';
import img_resetPassword from '../assets/images/iconMenuResetPassword@3x.png';
import img_viewListing from '../assets/images/iconMenuViewListing@3x.png';
import img_profile from '../assets/images/iconMenuViewProfile@3x.png';
import img_policy from '../assets/images/iconBackArrow16px@3x.png';
import img_search from '../assets/images/iconTabBarSearchWhite@3x.png';
import Text from '../components/Text';
import { UserType } from '../redux/Register';
import { authStateSelector, userStateSelector } from '../redux/Store';
import { Listing, setListing } from '../redux/User';
import {
  ListingsForUser,
  ListingsForUserEmail, SignOut
} from '../services/Firebase';
import { Colour, FontSize } from '../styles/style';
import { StackActions, useLinkTo, useNavigation } from '@react-navigation/native';
import Drawer from '@mui/material/Drawer';

type menuItem = {
  title: string;
  onPress: (linkTo) => void;
  imageSource?: any;
  imageHeight: number;
  imageWidth: number;
};

const Menu = ({ }) => {
  // Redux
  const dispatch = useDispatch();
  const linkTo = useLinkTo();
  const nav = useNavigation();
  const userState = useSelector(userStateSelector);
  const authState = useSelector(authStateSelector);

  const { userType, name, plan, parentProfileId } = userState.profile || {};

  const hasPlan = plan?.subscriptionId !== undefined || parentProfileId != null;

  useEffect(() => {
    function update() {
      if (authState) {
        ListingsForUser(authState.userId!).then(result => {
          if (result.docs.length > 0) {
            let listing = result.docs[0].data() as Listing;
            listing.id = result.docs[0].id;
            dispatch(setListing(listing));
          }
        });
        ListingsForUserEmail(authState.email!).then(result => {
          if (result.docs.length > 0) {
            let listing = result.docs[0].data() as Listing;
            listing.id = result.docs[0].id;
            dispatch(setListing(listing));
          }
        });
      }
    }
    update();
    const interval = setInterval(update, 15000);
    return () => clearInterval(interval);
  }, [authState, dispatch]);

  const menuItems: menuItem[] = useMemo(() => {
    let items: menuItem[] = [];
    items = items.concat(
      userType === UserType.Individual
        ? [
          {
            title: 'Search',
            onPress: () => linkTo('/search'),
            imageSource: img_search,
            imageHeight: 20,
            imageWidth: 20,
          },
          {
            title: 'View Profile',
            onPress: () => linkTo('/profile'),
            imageSource: img_profile,
            imageHeight: 20,
            imageWidth: 18,
          },
          ...(userState.listing
            ? [
              {
                title: 'View listing',
                onPress: () => {
                  linkTo({
                    screen: 'ViewListing',
                    params: {
                      id: userState.listing?.id,
                    },
                  })
                },
                imageSource: img_viewListing,
                imageHeight: 20,
                imageWidth: 17,
              },
              {
                title: 'Edit listing',
                onPress: () => {
                  if (userState.listing) {
                    linkTo({
                      screen: 'CreateListing',
                      params: {
                        id: userState.listing?.id,
                      },
                    });
                  } else {
                    linkTo({
                      screen: 'Payment',
                      params: {
                        invite: false,
                        createListing: true,
                      },
                    });
                  }
                },
                imageSource: img_editListing,
                imageHeight: 20,
                imageWidth: 20,
              },
            ]
            : [
              {
                title: 'Create listing',
                onPress: () => {
                  if (hasPlan) {
                    linkTo({
                      screen: 'CreateListing',
                      params: {
                        id: userState.listing?.id,
                      },
                    });
                  } else {
                    linkTo({
                      screen: 'Payment',
                      params: {
                        invite: false,
                        createListing: true,
                      },
                    });
                  }
                },
                imageSource: img_editListing,
                imageHeight: 20,
                imageWidth: 20,
              },
            ]),
        ]
        : [
          {
            title: 'Search',
            onPress: () => linkTo('/search'),
            imageSource: img_search,
            imageHeight: 20,
            imageWidth: 20,
          },
          {
            title: userState.listing ? 'View Organisation' : 'Create Organisation',
            onPress: () =>
              linkTo({
                screen: userState.listing ? 'ViewOrganisation' : 'EditOrganisation',
                params: {
                  id: userState.listing?.id,
                  parentProfileId: userState.profile?.parentProfileId,
                },
              }),
            imageSource: img_organisation,
            imageHeight: 20,
            imageWidth: 25,
          },
          ...(userState.listing
            ? [
              {
                title: 'Edit Organisation',
                onPress: () =>
                  linkTo({
                    screen: 'EditOrganisation',
                    params: {
                      id: userState.listing?.id,
                      parentProfileId: userState.profile?.parentProfileId,
                    },
                  }),
                imageSource: img_editListing,
                imageHeight: 20,
                imageWidth: 25,
              },
            ]
            : []),
        ],
    );

    if (userState.profile?.parentProfileId !== undefined) {
      items.push({
        title: 'Organisation',
        onPress: async () => {
          linkTo({
            screen: 'ViewOrganisation',
            params: {
              parentProfileId: userState.profile!.parentProfileId,
            },
          });
        },
        imageSource: img_organisation,
        imageHeight: 20,
        imageWidth: 25,
      });
    }

    items = items.concat([
      {
        title: 'Reset password',
        onPress: () => {
          linkTo('/passwordreset');
        },
        imageSource: img_resetPassword,
        imageHeight: 20,
        imageWidth: 15,
      },
      {
        title: 'Help',
        // onPress: (linkTo) => navigation.push('Help'),
        onPress: () => Linking.openURL('https://www.auslawconcierge.com.au/contact'),
        imageSource: img_help,
        imageHeight: 20,
        imageWidth: 12,
      },
      {
        title: 'Plans',
        onPress: () => linkTo('/payment'),
        imageSource: img_plans,
        imageHeight: 20,
        imageWidth: 20,
      },
      {
        title: 'Privacy Policy',
        onPress: () => Linking.openURL('https://auslawconcierge.com.au/privacy-policy'),
        imageSource: img_policy,
        imageHeight: 20,
        imageWidth: 12,
      },
      {
        title: 'Logout',
        onPress: () => {
          SignOut();
          linkTo('/welcome');
        },
        imageSource: img_logout,
        imageHeight: 20,
        imageWidth: 19,
      },
      // {
      //         title: 'Dev: Set services',
      //         onPress: (linkTo) => SetLocations(),
      //       },
      //       {
      //         title: 'Dev: Set services',
      //         onPress: (linkTo) => SetServices(),
      //       },
    ]);

    //dev
    // if (false) {
    //   items = items.concat([
    //     {
    //       title: 'Dev: Show profiles',
    //       onPress: (linkTo) => navigation.navigate('ProfileList'),
    //     },
    //     {
    //       title: 'Dev: Select calendar',
    //       onPress: (linkTo) => navigation.navigate('ChooseCalendar'),
    //     },
    //     {
    //       title: 'Dev: Delete listing',
    //       onPress: (linkTo) => {
    //         const id = userState.listing?.id;
    //         if (id !== undefined) {
    //           Collection(Collections.Listings).doc(id).delete();
    //           dispatch(setListing(undefined));
    //           Alert.alert('Listing deleted');
    //         } else {
    //           Alert.alert('Listing does not exist');
    //         }
    //       },
    //     },
    //     
    //     // {
    //     //     title: 'Dev: Set locations',
    //     //     onPress: (linkTo) => SetLocations(),
    //     // },
    //     {
    //       title: 'Dev: Delete this user',
    //       onPress: (linkTo) => DeleteUser(),
    //     },
    //     // {
    //     //     title: 'Dev: test',
    //     //     onPress: (linkTo) => Collection(Collections.Invitations).where('inviteeEmail', '==', 'e.cea@speedwell.com.au').get().then(docs => docs.forEach(doc => Collection(Collections.Invitations).doc(doc.id).delete()))
    //     // }
    //     // ] : []),
    //     {
    //       title: 'Dev: Set Prices',
    //       onPress: (linkTo) => {
    //         products.forEach(StripeProductFirestore.SetDocument);
    //       },
    //     },
    //     {
    //       title: 'Dev: write 10000 events lol',
    //       onPress: (linkTo) => {
    //         //generate events
    //         const date = moment();
    //         var myArray = [
    //           { name: 'oldest', date: '2007-01-17T08:00:00Z' },
    //           { name: 'newest', date: '2011-01-28T08:00:00Z' },
    //           { name: 'old', date: '2009-11-25T08:00:00Z' },
    //         ];
    //         lexicographicSort(myArray, p => p.date);
    //         console.log(JSON.stringify(myArray, null, ' '));
    //         // console.log(roughSizeOfObject({
    //         //     startDate: date.add(1, 'hour').toISOString(),
    //         //     endDate: date.add(1, 'hour').add(1, 'hour').toISOString()
    //         // }))
    //         // Collection(Collections.Listings).doc('oZ52sxPb7QPZCE4XUF1a')
    //         //     .set({ events }, { merge: true })
    //         //     .then(console.log)
    //         //     .catch(console.log);
    //       },
    //     },
    //   ]);
    // }

    return items;
  }, [dispatch, hasPlan, userState.auth, userState.listing, userState.profile, userType]);
  return (
    <div style={{ flex: 1, height:'100%', width: '100%', background: 'linear-gradient(#0A7BB8, #0865A5, #064F93, #053A81)', position: 'absolute' }}>
      <FlatList
        style={{ marginTop: 20 }}
        data={menuItems}
        keyExtractor={(item, index) => index.toString()}
        renderItem={({ item, index }) => (
          <>
            <View
              style={{
                flex: 1,
                flexDirection: 'row',
                paddingVertical: 20,
                paddingLeft: 20,
                paddingRight: 20,
                cursor: 'pointer',
                justifyContent: 'center',
              }}
              onClick={() => {
                item.onPress();
              }}
            >
              {item.imageSource && (
                <View style={{ width: 45 }}>
                  <Image source={item.imageSource} style={{ height: item.imageHeight, width: item.imageWidth }} />
                </View>
              )}
              <Text fontFamily="Barlow-Medium" style={[FontSize.medium, { color: 'white', letterSpacing: 0.9 }]}>
                {item.title}
              </Text>
            </View>
            <View
              style={{
                marginLeft: 20,
                borderBottomWidth: 2,
                borderBottomColor: 'rgba(0, 0, 0, 0.1)',
              }}
            />
          </>
        )}
      />
    </div>
  );
};

export default Menu;
