import React, { FunctionComponent } from 'react';
import { KeyboardAwareScrollView, KeyboardAwareScrollViewProps } from 'react-native-keyboard-aware-scroll-view';

const ScrollView: FunctionComponent<KeyboardAwareScrollViewProps> = props => {
  return (
    <KeyboardAwareScrollView keyboardOpeningTime={Number.MAX_SAFE_INTEGER} extraScrollHeight={40} {...props}>
      {props.children}
    </KeyboardAwareScrollView>
  );
};

export default ScrollView;
