import React from 'react';
import { ActivityIndicator, View } from 'react-native';

const FullscreenActivityIndicator = () => {
  return (
    <View
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: '#00000055',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      pointerEvents="none"
    >
      <ActivityIndicator size="large" />
    </View>
  );
};

export default FullscreenActivityIndicator;
