import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Keyboard, View } from 'react-native';
import { Icon, Input } from 'react-native-elements';
import { PrimaryButton } from '../../components/Button';
import { emailValidationRx } from '../../components/FormHelper';
import Text from '../../components/Text';
import { ForgotScreenProps } from '../../navigators/Types';
import { ResetPassword } from '../../services/Firebase';
import style, { FontSize } from '../../styles/style';
import LoginBase from './LoginBase';

interface IForgotPasswordForm {
  email: string;
}

const ForgotScreen = ({ route, navigation }: ForgotScreenProps) => {
  const { control, handleSubmit } = useForm<IForgotPasswordForm>({
    defaultValues: { email: '' },
  });

  const onForgotPassword = useCallback(
    (values: IForgotPasswordForm) => {
      Keyboard.dismiss();

      ResetPassword(values.email);
      navigation.replace('PasswordSent');
    },
    [navigation],
  );

  return (
    <LoginBase hasHeader>
      <View style={style.header}>
        <Text
          style={[
            FontSize.extraLarge,
            {
              color: 'white',
              letterSpacing: 1.5,
              marginTop: 40,
            },
          ]}
        >
          Forgot password
        </Text>
        <Text
          fontFamily="Barlow-Medium"
          style={[
            FontSize.normal,
            {
              color: 'white',
              textAlign: 'center',
              marginTop: 40,
              marginBottom: 80,
              paddingHorizontal: 30,
            },
          ]}
        >
          Enter you email address below and we will send you instructions to reset your password
        </Text>
      </View>
      <View
        style={[
          style.footer,
          {
            padding: 30,
          },
        ]}
      >
        <View style={style.container}>
          <Controller
            control={control}
            name="email"
            rules={{
              required: { value: true, message: 'Email is required' },
              pattern: { value: emailValidationRx, message: 'Email is not valid' },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                value={value}
                onChangeText={onChange}
                errorMessage={error?.message}
                placeholder="Email"
                keyboardType="email-address"
                autoCapitalize="none"
                leftIcon={<Icon name="mail-outline" tvParallaxProperties={undefined} />}
              />
            )}
          />

          <PrimaryButton title="SEND" onPress={handleSubmit(onForgotPassword)} />
        </View>
      </View>
    </LoginBase>
  );
};

export default ForgotScreen;
