import React, { useEffect, useState } from 'react';
import { Controller, NestedValue, useForm } from 'react-hook-form';
import { Input } from 'react-native-elements';
import { useSelector } from 'react-redux';
import { PrimaryButton } from '../../../components/Button';
import { emailValidationRx, ValidateMobile, ValidateAuMobile } from '../../../components/FormHelper';
import ModalMultiSelect from '../../../components/ModalMultiSelect';
import CText from '../../../components/Text';
import { pickListsStateSelector } from '../../../redux/Store';
import { Listing } from '../../../redux/User';
import Style, { Colour, FontSize } from '../../../styles/style';
import { OrganizationParams } from '../utils/model';
import { View } from 'react-native';
import Script from 'react-load-script';
import config from '../../../config.json';
const Text = (props: any) => <CText fontFamily="Barlow-Medium" {...props} />;
let apiKey = 'AIzaSyDS1DOw3KWwmFXcbDecj7ctCQzJs-1AERI';

interface Props {
  listing?: Listing;
  loading: boolean;
  onSubmit(values: OrganizationParams): void;
}

const EditOrganizationForm = (props: Props) => {
  const { listing, loading, onSubmit } = props;

  const pickLists = useSelector(pickListsStateSelector);

  useEffect(() => {
    if (listing) {
      setValue('name', listing?.name);
      setValue('streetAddress', listing?.streetAddress ?? '');
      setValue('email', listing?.email ?? '');
      setValue('phone', listing?.phone ?? '');
      setValue('bio', listing?.bio ?? '');
      setValue(
        'services',
        listing?.services
          ? (listing?.services as unknown as NestedValue<string[]>)
          : ([] as unknown as NestedValue<string[]>),
      );
    }
  }, [listing]);

  const { control, handleSubmit, setValue } = useForm<OrganizationParams>({
    defaultValues: {
      name: listing?.name || '',
      streetAddress: listing?.streetAddress || '',
      email: listing?.email || '',
      phone: listing?.phone || '',
      bio: listing?.bio || '',
      services: listing?.services
        ? (listing?.services as unknown as NestedValue<string[]>)
        : ([] as unknown as NestedValue<string[]>),
    },
  });

  const handleScriptLoad = () => {
    const autocomplete = new google.maps.places.Autocomplete(document.getElementById('autocomplete'), {
      fields: ['formatted_address'],
    });

    const handlePlaceSelect = () => {
      const addressObject = autocomplete.getPlace();
      setValue('streetAddress', addressObject.formatted_address);
    };
    // Fire Event when a suggested name is selected
    autocomplete.addListener('place_changed', handlePlaceSelect);
  };

  return (
    <>
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${config.firebaseApiKey}&libraries=places`}
        onLoad={handleScriptLoad}
      />
      <View style={{ paddingHorizontal: 30 }}>
        <Text style={Style.label}>Organisation name</Text>

        <Controller
          control={control}
          name="name"
          rules={{
            required: { value: true, message: 'Name is required' },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              testID="orgNameInput"
              value={value}
              onChangeText={onChange}
              errorMessage={error?.message}
              placeholder="e.g. Smith &amp; Jones"
            />
          )}
        />

        <Text style={Style.label}>Service(s)</Text>
        <Text style={[FontSize.normal, { color: Colour.DarkGrey }]}>
          Be sure to choose a specialisation for each service selected to maximise search results
        </Text>
        <Controller
          control={control}
          name="services"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <ModalMultiSelect
              value={value}
              onChange={onChange}
              items={pickLists.services}
              selectText="Add Service / Specialisation"
              headerText="Services / Specialisations"
            />
          )}
        />

        <Text style={Style.label}>Office Address</Text>
        <Controller
          control={control}
          name="streetAddress"
          render={({ field: { onChange, value } }) => (
            <Input value={value} id="autocomplete" onChangeText={onChange} placeholder="Address" />
          )}
        />

        <Text style={Style.label}>Email</Text>
        <Controller
          control={control}
          name="email"
          rules={{
            required: { value: true, message: 'Email is required' },
            pattern: { value: emailValidationRx, message: 'Email is not valid' },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              testID="orgEmailInput"
              value={value}
              onChangeText={onChange}
              placeholder="Email"
              keyboardType="email-address"
              autoCapitalize="none"
              errorMessage={error?.message}
            />
          )}
        />

        <Text style={Style.label}>Phone</Text>
        <Controller
          control={control}
          name="phone"
          rules={{
            validate: {
              mobile: value =>
                value
                  ? ValidateMobile(value) || 'Invalid phone number. Please provide a valid mobile number.'
                  : undefined,
            },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              value={value}
              onChangeText={onChange}
              keyboardType="phone-pad"
              placeholder="Phone"
              errorMessage={error?.message}
            />
          )}
        />

        <Text style={Style.label}>Bio</Text>
        <Controller
          control={control}
          name="bio"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              value={value}
              onChangeText={onChange}
              multiline
              numberOfLines={4}
              inputStyle={{ fontFamily: 'Barlow', textAlignVertical: 'top', minHeight: 100 }}
            />
          )}
        />

        <PrimaryButton loading={loading} title="SAVE" onPress={handleSubmit(onSubmit)} />
      </View>
    </>
  );
};

export default EditOrganizationForm;
