import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { PrimaryButton } from '../../../components/Button';
import Style, { Colour } from '../../../styles/style';
import { View } from 'react-native';
import Text from '../../../components/Text';
import { StackNavigationProp } from '@react-navigation/stack';
import { AllStackParamList } from '../../../navigators/Types';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { CallFunction, auth } from '../../../services/Firebase';

interface ConnectCalendarProps {
  inviteId?: string
}
function ConnectCalendar({inviteId}: ConnectCalendarProps) {
  const nav = useNavigation<StackNavigationProp<AllStackParamList>>();
  const [busy, setBusy] = useState(false)
  const [error, setError] = useState(false)
  const authorise = async () => {
    // User needs to select a calendar
    setBusy(true);
    try {
      const response = (await CallFunction('getNylasAuthLink', {
        email: auth.currentUser?.email,
        inviteId
      })) as string;
      //Redirect to the pyas hosted auth
      window.location.replace(response);
      setError(false)
    } catch(e) {
      setError(true)
    }
    setBusy(false)
  };
  return (
    <View>
      {error &&  <Text style={[Style.errorText, {textAlign: 'center'}]}>There was an error connecting your calendar. Please try again.</Text>}
      <PrimaryButton
        inverted
        title="CONNECT CALENDAR"
        loading={busy}
        loadingProps={{color: Colour.Blue}}
        titleStyle={{
          fontFamily: 'Barlow',
          fontSize: 18,
          letterSpacing: 1.5,
          color: Colour.Blue,
        }}
        
        buttonStyle={{
          height: 50,
          borderRadius: 25,
          backgroundColor: 'white',
          borderColor: Colour.Grey,
          borderWidth: 1,
        }}
        icon={<CalendarMonthIcon style={{ marginRight: 10, color: Colour.Blue }} />}
        iconContainerStyle={{ marginRight: 15 }}
        onPress={() => authorise()}
      />
      <View
        style={{
          backgroundColor: 'rgba(10,123,184,0.2)',
          paddingTop: 13,
          paddingBottom: 13,
          paddingLeft: 22,
          paddingRight: 22,
          marginTop: 23,
        }}
      >
        <Text
          style={{
            textAlign: 'center',
            color: Colour.DarkBlue,
            fontSize: 16,
            letterSpacing: 0.67,
            lineHeight: 21.4,
          }}
        >
          Please connect to a calendar to be used exclusively with this app. Anything within that calendar will be
          marked as unavailable within this app.
        </Text>
      </View>
    </View>
  );
}

export default ConnectCalendar;
