/* global COMMITHASH */
import Text from '../components/Text';
import React from 'react';

const VersionScreen = () => {
  return (
    <Text>{COMMITHASH}</Text>
  );
};

export default VersionScreen;
