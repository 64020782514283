import React, { useEffect, useState } from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';
import Text from '../components/Text';
import { MergeListingsScreenProps } from '../navigators/Types';
import { PrimaryButton } from '../components/Button';
import { userStateSelector } from '../redux/Store';
import {
  CallFunction
} from '../services/Firebase';
import { useLinkTo } from '@react-navigation/native';
import { captureException } from '@sentry/react';

const MergeListing = ({ route }: MergeListingsScreenProps) => {
  const linkTo = useLinkTo()
  const [errorMessage, setErrorMessage] = useState<string>()
  const [loading, setLoading] = useState(true)
  const [success, setSuccess] = useState(false)
  const [runOnce, setRunOnce] = useState(false)
  const userState = useSelector(userStateSelector);


  useEffect(() => {
    if (!userState.loadingState && !runOnce) {
      setRunOnce(true)
      if (!userState.listing || !route?.params?.code) {
        setErrorMessage('Looks like something has gone wrong, please click the link in your email again.')
      } else {
        CallFunction('mergeListing', {
          newListingId: userState.listing.id,
          code: route?.params?.code
        }).then(() => {
          setErrorMessage(undefined)
          setSuccess(true)
        }).catch(error => {
          captureException(error)
          setErrorMessage('There was an error merging your listing')
        }).finally(() => {
          setLoading(false)
        })
      }
    }
  }, [userState.listing, userState.loadingState, runOnce])

  return (
    <View style={{ flex: 1, alignSelf: 'center', justifyContent: 'center' }}>
      {errorMessage?.length && 
        <View style={{ flex: 1, justifyContent: 'center' }}>
        <Text
          fontFamily="Barlow-Medium"
          style={{
            marginTop: 35,
            color: 'white',
            fontSize: 24,
            letterSpacing: 1.2,
            paddingHorizontal: 65,
            textAlign: 'center',
            lineHeight: 32,
          }}
        >
          {errorMessage}
        </Text>

        <PrimaryButton
          containerStyle={{ marginTop: 50, paddingHorizontal: 30 }}
          title="Continue without merging"
          onPress={() => {
            linkTo('/search')
          }}
          inverted
        />
      </View>
      }
      {success && 
        <View style={{ flex: 1, justifyContent: 'center' }}>
        <Text
          fontFamily="Barlow-Medium"
          style={{
            marginTop: 35,
            color: 'white',
            fontSize: 24,
            letterSpacing: 1.2,
            paddingHorizontal: 65,
            textAlign: 'center',
            lineHeight: 32,
          }}
        >
          Successfully merged listings
        </Text>

        <PrimaryButton
          containerStyle={{ marginTop: 50, paddingHorizontal: 30 }}
          title="Continue"
          onPress={() => {
            linkTo('/search')
          }}
          inverted
        />
      </View>
      }
      {loading && 
      <>
        <ActivityIndicator color={'white'} size="large" />
        <Text style={{color: 'white', marginTop: 15}}>Please wait while we merge your listings</Text>
      </>
      }
    </View>
  )
};

export default MergeListing;
