import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useLinkTo } from '@react-navigation/native';
import { deleteField, updateDoc } from 'firebase/firestore/lite';
import React, { useEffect, useMemo, useState } from 'react';
import { ActivityIndicator, Animated, Image, Linking, StyleSheet, View } from 'react-native';
import { Divider } from 'react-native-elements';
import { useDispatch, useSelector } from 'react-redux';
import callIcon from '../../assets/images/iconCall@3x.png';
import { TextButton } from '../../components/Button';
import FavoriteButton from '../../components/FavoriteButton';
import { useSafeBottomPadding } from '../../components/Hooks';
import { ProfileImage } from '../../components/ProfileImage';
import Text from '../../components/Text';
import { StringIsUndefinedOrEmpty } from '../../components/TextHelper';
import config from '../../config.json';
import { ViewOrganisationScreenProps } from '../../navigators/Types';
import { getCategoryName, getNameFromMultiIndex } from '../../redux/PickLists';
import { UserType } from '../../redux/Register';
import { pickListsStateSelector, stripeSelector, userStateSelector } from '../../redux/Store';
import { Invite, Listing, cancelInvite, fetchOrgInvites, fetchOrgListings, toggleFavorite } from '../../redux/User';
import {
  GetProfile, ListingDocument,
  ListingsForUser,
  ProfileDocument,
  logAnalyticsEvent
} from '../../services/Firebase';
import { GetInvitationByOrgId, InvitationDocument } from '../../services/InvitationService';
import { GetListing, GetListingByProfile } from '../../services/ListingService';
import Style, { Barlow, Colour, FontSize } from '../../styles/style';
import LoginBase from '../auth/LoginBase';

const stylesheet = StyleSheet.create({
  profileCell: { width: '50%', alignItems: 'center', marginTop: 30, cursor: 'pointer' },
  invitationWrapper: {
    alignItems: 'center',
    borderStyle: 'dashed',
    borderColor: '#dddddd',
    borderWidth: 2,
    justifyContent: 'center',
  },
  profileCellText: {
    color: Colour.DarkBlue,
    fontFamily: 'Barlow',
    fontSize: 16,
    marginTop: 7,
    letterSpacing: 1,
  },
  button: {
    borderRadius: 50,
    width: 77,
    height: 77,
    backgroundColor: Colour.Blue,
    alignItems: 'center',
    justifyContent: 'center',
  },
  topButton: {
    color: 'white',
    fontSize: 14,
    letterSpacing: 1.17,
    marginTop: 11,
    textAlign: 'center',
  },
});

const ViewOrganisation = ({ route, navigation }: ViewOrganisationScreenProps) => {
  const linkTo = useLinkTo();
  // Redux
  const dispatch = useDispatch();
  const { favoriteListingIds, listing, profile, orgListings, orgInvites } = useSelector(userStateSelector);
  const { services } = useSelector(pickListsStateSelector);
  const { products } = useSelector(stripeSelector);
  const userState = useSelector(userStateSelector);

  const [showTeamAlert, setShowTeamAlert] = useState(false);
  const [showCancelInviteDialog, setShowCancelInviteDialog] = useState(false);
  const [teamMemberToRemove, setTeamMemberToRemove] = useState<Listing | null>(null);
  const [inviteToCancel, setInviteToCancel] = useState<Invite | null>(null);
  const [bioExpanded, setBioExpanded] = useState(false);
  const [bioHeight] = useState(new Animated.Value(115));
  const [profileDoyles, setProfileDoyles] = useState(false);
  const [orgListing, setOrgListing] = useState<Listing | null>(null);
  
  useEffect(() => {
    if (route.params?.profileId) {
      GetProfile(route.params.profileId!).then(profileResult => {
        setProfileDoyles(profileResult?.isDoyles);
      });
    }
    return () => {
      setProfileDoyles(false);
    };
  }, [route.params?.profileId]);

  useEffect(() => {
    const getListing = async () => {
      if (route?.params?.id) {
        const listing = await GetListing(route?.params?.id);
        setOrgListing(listing);
      } else if (route?.params?.parentProfileId) {
        const listing = (await GetListingByProfile(route?.params?.parentProfileId)) as Listing;
        setOrgListing(listing);
      }
    };
    getListing();
  }, [route?.params?.id, route?.params?.parentProfileId]);

  const userIsTheOrg = useMemo(
    () => route?.params?.id === userState.listing?.id,
    [userState.listing?.id, route?.params?.id],
  );

  const [busy, setBusy] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(userIsTheOrg && profile?.plan?.subscriptionId !== undefined);
  useEffect(() => {
    if (!userIsTheOrg) {
      setBusy(true);
      Promise.all([dispatch(fetchOrgListings(orgListing?.profileId))]).then(() => setBusy(false));
    } else if (profile?.plan?.subscriptionId) {
      // Refresh our listings and invites if user is an org and they have an active subscription
      Promise.all([dispatch(fetchOrgListings()), dispatch(fetchOrgInvites())]).then(() => setRefreshing(false));
    }
  }, [orgListing]);

  const teamCount = useMemo(() => (orgListings?.length || 0) + (orgInvites?.length || 0), [orgListings, orgInvites]);
  const teamMaxCount = useMemo(() => profile?.plan?.profileAmount, [profile?.plan]);

  const isNotInfinite = useMemo(() => {
    if (teamMaxCount !== undefined && products !== undefined) {
      //Look for the org product
      const orgProduct = products.find(p => p.id === UserType.Organisation);
      if (orgProduct !== undefined) {
        return (
          teamMaxCount !==
          Math.max.apply(
            Math,
            orgProduct.plans[0].prices.map(price => price.amount!),
          )
        );
      }
    }
  }, [products, teamMaxCount]);

  const [orgServices, orgSpecialisations] = useMemo(() => {
    if (orgListing?.services && orgListing?.services?.length > 0) {
      return [
        [...new Set(orgListing?.services.map(s => getCategoryName(s, services)))].join(', '),
        [
          ...new Set(
            orgListing?.services.filter(s => s.includes('.')).map(s => getNameFromMultiIndex(s, services, true)),
          ),
        ].join(', '),
      ];
    } else {
      return [undefined, undefined];
    }
  }, [orgListing?.services]);

  if (!orgListing) {
    return <LoginBase></LoginBase>
  }
  return (
    <LoginBase>
      <View
        style={{
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          paddingTop: 10,
          width: '100%',
        }}
      >
        <ProfileImage inverted uri={orgListing?.imageUrl} size={135} name={orgListing?.name} isDoyles={orgListing?.isDoyles || profileDoyles} />
      </View>

      <View style={{ alignContent: 'center', alignItems: 'center', paddingBottom: 20 }}>
        <Text fontFamily="Barlow-Medium" style={{ marginTop: 20, color: 'white', fontSize: 24, letterSpacing: 1 }}>
          {orgListing?.name}
        </Text>
        <Text
          fontFamily="Barlow-Medium"
          style={{
            marginTop: 11,
            paddingHorizontal: 15,
            color: 'white',
            fontSize: 20,
            letterSpacing: 0.83,
            textAlign: 'center',
          }}
        >
          {orgServices}
        </Text>
      </View>

      <View
        style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', marginBottom: 30 }}
      >
        {!StringIsUndefinedOrEmpty(orgListing?.phone) && !userIsTheOrg && (
          <View style={{ alignItems: 'center', alignContent: 'center', paddingLeft: 20, paddingRight: 20 }}>
            <View onClick={() => {
              logAnalyticsEvent('call_pressed', {
                listingId: orgListing?.id!
              })
              Linking.openURL('tel:' + orgListing?.phone)
            }} style={[Style.shadow, stylesheet.button]}>
              <Image source={callIcon} style={{ height: 29, width: 29 }} />
            </View>
            <Text fontFamily="Barlow-SemiBold" style={stylesheet.topButton}>
              CALL
            </Text>
          </View>
        )}

        {!userIsTheOrg && (
          <View style={{ alignItems: 'center', alignContent: 'center', paddingLeft: 20, paddingRight: 20 }}>
            <View
              testID="messageButton"
              onClick={() => linkTo({ screen: 'SendMessage', params: { id: orgListing?.id.id } })}
              style={[Style.shadow, stylesheet.button]}
            >
              <Image source={require('../../assets/images/iconMessage@3x.png')} style={{ height: 20, width: 29 }} />
            </View>
            <Text fontFamily="Barlow-SemiBold" style={stylesheet.topButton}>
              MESSAGE
            </Text>
          </View>
        )}
      </View>

      <View style={[Style.footer, { padding: 20, paddingBottom: useSafeBottomPadding() }]}>
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 1 }}>
            {orgSpecialisations !== undefined && orgSpecialisations.length > 0 && (
              <>
                <Text fontFamily="Barlow-Medium" style={{ color: Colour.DarkBlue, fontSize: 16, letterSpacing: 0.8 }}>
                  Specialisation(s):
                </Text>
                <Text style={{ color: Colour.DarkGrey, fontSize: 16, letterSpacing: 0.8 }}>{orgSpecialisations}</Text>
              </>
            )}
          </View>
          {!userIsTheOrg && (
            <FavoriteButton
              onClick={() => dispatch(toggleFavorite(orgListing?.id!))}
              isFavorite={favoriteListingIds.includes(orgListing?.id!)}
            />
          )}
        </View>
        {((orgSpecialisations !== undefined && orgSpecialisations.length > 0) || !userIsTheOrg) && (
          <Divider style={{ marginVertical: 15 }} />
        )}

        {!StringIsUndefinedOrEmpty(orgListing?.bio) && (
          <>
            <Text
              fontFamily="Barlow-Medium"
              style={{ color: Colour.DarkBlue, fontSize: 24, letterSpacing: 1, marginBottom: 8 }}
            >
              Bio
            </Text>
            <Animated.Text
              style={[
                Barlow.regular,
                {
                  color: Colour.DarkGrey,
                  fontSize: 16,
                  letterSpacing: 0.8,
                  // height: needsReadMore ? bioHeight : requiredBioHeight,
                },
              ]}
            >
              {orgListing?.bio}
            </Animated.Text>
            {/* 
            {needsReadMore ? (
              <View
                activeOpacity={0.5}
                style={{ alignItems: 'center', height: 23, marginTop: 8 }}
                onClick={() => toggleBio()}
              >
                <View
                  style={{
                    backgroundColor: Colour.Grey,
                    height: 1,
                    width: '100%',
                    position: 'absolute',
                    top: 9,
                  }}
                />

                <Text
                  fontFamily="Barlow-Medium"
                  style={{
                    backgroundColor: 'white',
                    paddingLeft: 15,
                    paddingRight: 15,
                    color: '#757575',
                    fontSize: 14,
                    letterSpacing: 0.7,
                  }}
                >
                  {bioExpanded ? 'COLLAPSE' : 'READ MORE'}
                </Text>
              </View>
            ) : (
              <Divider style={{ marginVertical: 15 }} />
            )} */}
          </>
        )}

        {!StringIsUndefinedOrEmpty(orgListing?.streetAddress) && (
          <>
            <Text
              fontFamily="Barlow-Medium"
              style={{ color: Colour.DarkBlue, fontSize: 24, letterSpacing: 1, marginBottom: 8 }}
            >
              Address
            </Text>
            <Text style={{ color: Colour.DarkGrey, fontSize: 16, letterSpacing: 0.8 }}>
              {orgListing?.streetAddress}
            </Text>
            <Divider style={{ marginVertical: 15 }} />
          </>
        )}

        {busy ? (
          <ActivityIndicator />
        ) : (
          <>
            <View style={{ flexDirection: 'row' }}>
              <Text
                fontFamily="Barlow-Medium"
                style={{ textAlignVertical: 'center', color: Colour.DarkBlue, fontSize: 24, letterSpacing: 1 }}
              >
                Team
                {userIsTheOrg && (
                  <Text style={[Barlow.medium, FontSize.normal, { color: Colour.DarkGrey }]}>
                    {'  '}(
                    {profile?.plan?.subscriptionId
                      ? `${teamCount}${isNotInfinite ? ` / ${teamMaxCount}` : ''}`
                      : 'Requires Plan'}
                    )
                  </Text>
                )}
              </Text>
              {userIsTheOrg && refreshing && <ActivityIndicator style={{ marginLeft: 10 }} />}
            </View>

            <View style={{ flex: 1, flexDirection: 'row', flexWrap: 'wrap', marginTop: 8 }}>
              {orgListings
                ?.filter(x => x.name?.length > 0)
                .map((listing, index) => {
                  return (
                    <View key={index} style={stylesheet.profileCell}>
                      <ProfileImage
                        size={110}
                        uri={listing.imageUrl}
                        name={listing.name}
                        defaultImage={userIsTheOrg ? 'team' : null}
                        onClick={() => {
                          linkTo({ screen: 'ViewListing', params: { id: listing.id } });
                        }}
                      />
                      <Text
                        style={stylesheet.profileCellText}
                        onClick={() => linkTo({ screen: 'ViewListing', params: { id: listing.id } })}
                      >
                        {listing.name}
                      </Text>
                      {userIsTheOrg && (
                        <TextButton
                          text="Remove"
                          textStyle={{ color: Colour.MediumGrey }}
                          style={{ margin: 0 }}
                          onPress={() => {
                            setTeamMemberToRemove(listing);
                          }}
                        />
                      )}
                    </View>
                  );
                })}
              <Dialog
                open={!!teamMemberToRemove}
                onClose={() => setTeamMemberToRemove(null)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">Remove team member</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {`Are you sure you want to remove ${teamMemberToRemove?.name}?`}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setTeamMemberToRemove(null)}>Cancel</Button>
                  <Button
                    onClick={async () => {
                      // update invitation

                      const inviteeProfile = await GetProfile(teamMemberToRemove?.profileId!);
                      const parentProfileId = inviteeProfile.parentProfileId!;
                      GetInvitationByOrgId(parentProfileId).then(snapshot => {
                        const parseInvitesData: Invite[] = snapshot.docs.map(doc => ({
                          ...doc.data(),
                          id: doc.id,
                        }));
                        const data = parseInvitesData.find(
                          invitation => invitation.inviteeEmail === teamMemberToRemove?.email,
                        );
                        if (data) {
                          updateDoc(InvitationDocument(data.id), {
                            cancelled: true,
                            accepted: false,
                          });
                        }
                      });
                      const listingId = (await ListingsForUser(teamMemberToRemove?.profileId!)).docs[0].id;
                      updateDoc(ListingDocument(listingId), {
                        planStatus: 'cancelled',
                      });

                      updateDoc(ProfileDocument(teamMemberToRemove?.profileId!), {
                        parentProfileId: deleteField(),
                      });

                      dispatch(fetchOrgListings());
                      setTeamMemberToRemove(null);
                    }}
                    autoFocus
                  >
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
              {userIsTheOrg ? (
                <>
                  {orgInvites?.map((invite, index) => {
                    return (
                      <View
                        key={index}
                        style={stylesheet.profileCell}
                        onClick={() => {
                          setInviteToCancel(invite);
                          setShowCancelInviteDialog(true);
                        }}
                      >
                        <View style={[Style.teamPicture, stylesheet.invitationWrapper]}>
                          {invite.loading ? (
                            <ActivityIndicator />
                          ) : (
                            <Text style={{ fontSize: 16, color: Colour.Silver, letterSpacing: 1 }}>Invited</Text>
                          )}
                        </View>
                        <Text style={stylesheet.profileCellText}>{invite.inviteeName}</Text>
                      </View>
                    );
                  })}
                  <View
                    style={stylesheet.profileCell}
                    onClick={() => {
                      if (!profile?.plan?.subscriptionId) {
                        setShowTeamAlert(true);
                      } else {
                        if (teamCount < teamMaxCount!) {
                          Linking.openURL(`${config.webPortalDomain}/home/invite-user/single`)
                        } else {
                          setShowTeamAlert(true);
                        }
                      }
                    }}
                  >
                    <ProfileImage size={110} defaultImage="team" />
                    <Text style={stylesheet.profileCellText}>Invite Team Member</Text>
                  </View>
                  <Dialog
                    open={showTeamAlert}
                    onClose={() => setShowTeamAlert(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {!profile?.plan?.subscriptionId ? 'Requires Plan' : 'Team limit reached'}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {!profile?.plan?.subscriptionId
                          ? 'You must subscribe to a plan to invite members to your team'
                          : 'You have reached the limit for team members, please upgrade your plan to continue.'}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setShowTeamAlert(false)}>Cancel</Button>
                      <Button
                        onClick={() => {
                          setShowTeamAlert(false);
                          linkTo({ screen: 'Payment', params: { invite: true, createListing: false } });
                        }}
                        autoFocus
                      >
                        OK
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Dialog
                    open={showCancelInviteDialog}
                    onClose={() => setShowCancelInviteDialog(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">Cacnel invitation?</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {`${inviteToCancel?.inviteeName} will be uninvited`}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setShowCancelInviteDialog(false)}>Cancel</Button>
                      <Button
                        onClick={() => {
                          setShowCancelInviteDialog(false);
                          dispatch(cancelInvite(inviteToCancel.id));
                        }}
                        autoFocus
                      >
                        OK
                      </Button>
                    </DialogActions>
                  </Dialog>
                </>
              ) : null}
            </View>
          </>
        )}
      </View>
    </LoginBase>
  );
};

export default ViewOrganisation;
