import React, { useState } from 'react';
import { View } from 'react-native';
import Accordion from 'react-native-collapsible/Accordion';
import { Divider, Icon } from 'react-native-elements';

import { HelpScreenProps } from '../navigators/Types';
import Text from '../components/Text';
import { FontColour, Colour, FontSize } from '../styles/style';

interface HelpScreenItem {
  title: string;
  text: string[];
}

const helpScreenItems: HelpScreenItem[] = [
  {
    title: 'Vestibulum id ligula porta felis euismod semper?',
    text: [
      'Nullam id dolor id nibh ultricies vehicula ut id elit.',
      'Sed posuere consectetur est at lobortis.',
      'Maecenas sed diam eget risus varius blandit sit amet non magna.',
      'Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.',
    ],
  },
  {
    title: 'Sed posuere consectetur est at?',
    text: [
      'Nullam id dolor id nibh ultricies vehicula ut id elit.',
      'Sed posuere consectetur est at lobortis.',
      'Maecenas sed diam eget risus varius blandit sit amet non magna.',
      'Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.',
    ],
  },
  {
    title: 'Integer posuere erat a ante venena?',
    text: [
      'Nullam id dolor id nibh ultricies vehicula ut id elit.',
      'Sed posuere consectetur est at lobortis.',
      'Maecenas sed diam eget risus varius blandit sit amet non magna.',
      'Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.',
    ],
  },
];

const HelpScreen = ({ route, navigation }: HelpScreenProps) => {
  const [activeSections, setActiveSections] = useState<number[]>([]);

  return (
    <Accordion
      containerStyle={{ backgroundColor: 'white' }}
      sections={helpScreenItems}
      renderHeader={(item, index) => (
        <View style={{ backgroundColor: 'white' }}>
          <Divider />
          <View style={{ alignItems: 'center', flexDirection: 'row' }}>
            <Text
              fontFamily="Barlow-Medium"
              style={[FontColour.darkBlue, { flex: 1, marginLeft: 20, marginVertical: 20, fontSize: 18 }]}
            >
              {item.title}
            </Text>
            <Icon
              style={{ marginHorizontal: 20, rotation: activeSections.includes(index) ? -90 : 90 }}
              size={30}
              name="chevron-right"
              color={Colour.DarkBlue}
              tvParallaxProperties={undefined}
            />
          </View>
        </View>
      )}
      renderContent={item => (
        <View style={{ flex: 1, paddingHorizontal: 20, paddingBottom: 20 }}>
          <Text style={[FontSize.normal, { color: '#2E2E2E' }]}>{item.text.join('\n')}</Text>
        </View>
      )}
      activeSections={activeSections}
      onChange={sections => setActiveSections(sections)}
    />
  );
};

export default HelpScreen;
