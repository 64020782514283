import { HeaderBackButton } from '@react-navigation/elements';
import React, { useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { Asset } from 'react-native-image-picker';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { CreateListingScreenProps } from '../../navigators/Types';
import { userStateSelector } from '../../redux/Store';
import AddServiceOrSpecialisation from './components/AddServiceOrSpecialisation';
import CreateListingStep1 from './components/CreateListingStep1';
import CreateListingStep2 from './components/CreateListingStep2';
import CreateListingStep3 from './components/CreateListingStep3';
import StepProgressHeader from './components/StepProgressHeader';
import CreateListingStep4 from './components/CreateListingStep4';
import { Listing, setListing } from '../../redux/User';
import { SaveIndividual } from '../../services/ListingService';
import LoginBase from '../auth/LoginBase';
import Style, { Colour } from '../../styles/style';
import { logAnalyticsEvent } from '../../services/Firebase';
import { UserType } from '../../redux/Register';
import { captureException } from '@sentry/react';


const CreateListingScreen = ({ route, navigation }: CreateListingScreenProps) => {
  const dispatch = useDispatch();
  const { listing, profile } = useSelector(userStateSelector);
  const [step, setStep] = useState(0);
  const [listingParams, setListingParams] = useState(listing);


  useEffect(() => {
      if (route?.params?.skipToStep) {
        setStep(Number(route?.params?.skipToStep))
      }
  }, [route.params])

  useEffect(() => {
    if (!listingParams) {
      setListingParams(listing);
    }
  }, [listing]);

  const [image, setImage] = useState<Asset>();
  const [doylesImages, setDoylesImages] = useState<Asset[]>();

  const scroll = useRef<KeyboardAwareScrollView>(null);

  const [manualAddress, setManualAddress] = useState(false);

  const [isAddingService, setIsAddingService] = useState(false);

  const [loading, setLoading] = useState<boolean>(false);

  const scrollToTop = useDebouncedCallback(() => {
    if (scroll?.current !== undefined) {
      scroll.current!.scrollToPosition(0, 0, true);
    }
  }, 100);
  
  
  const saveListing = (listingToSave: Listing) => {

    setLoading(true);

    return SaveIndividual(listingToSave, image, doylesImages).then(newListing => {
      logAnalyticsEvent('listing_updated', {
        new: !listingToSave.id?.length,
        type: UserType.Individual,
        hasImage: Boolean(newListing?.imageUrl?.length),
        hasName: Boolean(newListing?.name?.length),
        hasHourlyRate: Boolean(newListing?.hourlyRate),
        hasDailyRate: Boolean(newListing?.dailyRate),
        hasReportCost: Boolean(newListing?.reportCost),
        hasBio: Boolean(newListing?.bio?.length),
        hasPhone: Boolean(newListing?.phone?.length),
        hasMob: Boolean(newListing?.mobile?.length),
        hasEmail: Boolean(newListing?.email?.length),
        hasAddress: Boolean(newListing?.streetAddress?.length),
        hasSuburb: Boolean(newListing?.suburb?.length),
        hasPostcode: Boolean(newListing?.postcode?.length),
        hasState: Boolean(newListing?.state?.length),
        hasServices: Boolean(newListing?.services?.length),
        hasLocations: Boolean(newListing?.locations?.length),
        hasAccreditations: Boolean(newListing?.accreditations?.length),
        hasCalendar: Boolean(newListing?.nylasConnected),
        hasEvents: Boolean(newListing?.events?.length),
        isDoyles: Boolean(newListing.isDoyles),
        hasDoylesImages: Boolean(newListing?.doylesImageUrls?.length),
      })
      dispatch(setListing(newListing));
      setLoading(false)
      return newListing
  })
}
  const onComplete = () => {
    if (!listingParams) {
      alert('Listing params error');
      captureException(new Error('listing params error'))
      return;
    }
    saveListing({
      id: listing?.id || '',
      ...listingParams,
      hourlyRate: listingParams?.hourlyRate || 0,
      dailyRate: listingParams?.dailyRate || 0,
      reportCost: listingParams?.reportCost || 0,
      email: listingParams?.email?.trimLeft().trimRight(),
      imageUrl: listing?.imageUrl || '',
      doylesImageUrls: listingParams?.doylesImageUrls || [],
    }).then((newListing) => {
      setStep(0);
      navigation.replace('ViewListing', { id: newListing.id });
    })
  };

  const handleRemoveDoylesImage = (filename: string) => {
    if (doylesImages !== undefined) {
      const remainedDoylesImage = doylesImages!.filter(image => image.name !== filename);
      setDoylesImages(remainedDoylesImage);
    }

    //remove items from the listing.doylesImageUrls array that match filename parameter
    if (listingParams?.doylesImageUrls !== undefined) {
      const remainedDoylesImageUrls = listingParams?.doylesImageUrls.filter(image => image !== filename);
      setListingParams({ ...listingParams, doylesImageUrls: remainedDoylesImageUrls });
    }
  };

  return (
    <LoginBase>
      <View style={[Style.footer, { backgroundColor: 'rgba(0, 0, 0, 0)', justifyContent: 'center' }]}>
        <StepProgressHeader step={step} setStep={setStep} />

        <View style={{ backgroundColor: 'white', borderRadius: 24, padding: 30 }}>
          {step !== 0 && 
            <HeaderBackButton tintColor={Colour.DarkBlue} onPress={() => setStep(step - 1)}/>
          }
          <View style={{ flex: 1, paddingHorizontal: 20, minHeight: 845 }}>
            {step === 0 && (
              <CreateListingStep1
                listing={listingParams}
                image={image}
                setImage={setImage}
                onNextStep2={values => {
                  setListingParams({ ...listingParams, ...values });
                  setStep(1);
                }}
              />
            )}

            {step === 1 && (
              <CreateListingStep2
                listing={listingParams}
                useManualAddress={manualAddress}
                changeManualAddress={setManualAddress}
                onNextStep3={values => {
                  setListingParams({ ...listingParams, ...values });
                  setStep(2);
                }}
              />
            )}

            {step === 2 &&
              (isAddingService ? (
                <AddServiceOrSpecialisation onClose={() => setIsAddingService(false)} />
              ) : (
                <CreateListingStep3
                  listing={listingParams}
                  openAddService={() => setIsAddingService(!isAddingService)}
                  doylesImages={doylesImages}
                  loading={loading} 
                  isDoylesAccount={profile?.isDoyles}
                  setDoylesImages={setDoylesImages}
                  onRemoveDoylesImage={handleRemoveDoylesImage}
                  onNextStep4={values => {
                    setLoading(true)
                    setListingParams({ ...listingParams, ...values });
                    if (!listing) {
                      if (!listingParams) {
                        alert('Listing params error');
                        captureException(new Error('listing params error'))
                        return;
                      }
                      saveListing({
                        ...listingParams,
                        ...values,
                        hourlyRate: listingParams?.hourlyRate || 0,
                        dailyRate: listingParams?.dailyRate || 0,
                        reportCost: listingParams?.reportCost || 0,
                        email: listingParams?.email?.trimLeft().trimRight(),
                        doylesImageUrls: listingParams?.doylesImageUrls || [],
                      }).then(() => {
                        setStep(3);
                        setLoading(false)
                      })
                    } else {
                      setStep(3)
                      setLoading(false)
                    }
                  }}
                />
              ))}
            {step === 3 && (
              <CreateListingStep4
                loading={loading}
                onSetEvents={events => listingParams && setListingParams({ ...listingParams, events })}
                onComplete={onComplete}
              />
            )}
          </View>
        </View>
      </View>
    </LoginBase>
  );
};

export default CreateListingScreen;
