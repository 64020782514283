import { useHeaderHeight } from '@react-navigation/elements';
import React from 'react';
import { View } from 'react-native';
import Style from '../../styles/style';

const LoginBase = (props: any & { hasHeader: boolean }) => {
  const headerHeight = useHeaderHeight();

  return (
    <View
      style={[
        props.style,
        Style.container,
        {
          paddingTop: props.hasHeader ? headerHeight : 0,
          alignItems: 'center',
        },
      ]}
      // source={bg} The imagebackgrond is now in app.tsx. This is now justa view that wraps things since removing this means refactoring every screen :(
    >
      {props.children}
    </View>
  );
};

export default LoginBase;
