import { getDoc, setDoc } from 'firebase/firestore/lite';
import { ISODateString } from 'react-native-calendar-events';
import { Action } from 'redux';
import { SelectedCalendar } from '../services/CalendarService';
import { ThunkPromise, ThunkResult } from './Store';
import { captureException } from '@sentry/react';

// MODELS
export interface CalendarEvent {
  startDate: ISODateString;
  endDate: ISODateString;
}

export interface Calendar {
  id: string;
  title: string;
  source: string;
  color: string;
  timezone: string;
  accountId: string;
  connected: string;
}

export interface CalendarSection {
  title: string;
  data: Calendar[];
}

// ACTION TYPES
export enum ActionType {
  // Model Calendar
  SET_SELECTED_CALENDAR = 'SET_SELECTED_CALENDAR',

  RESET = 'USER_RESET',
}

interface CalendarAction extends Action<ActionType> {
  calendar?: Calendar;
}

// ACTIONS
export const setSelectedCalendar = (calendar: Calendar): CalendarAction => ({
  type: ActionType.SET_SELECTED_CALENDAR,
  calendar,
});

// THUNKS
export const fetchSelectedCalendar = (): ThunkResult<void> => (dispatch, getState) => {
  // Download the selected calendar
  getDoc(SelectedCalendar())
    .then(snapshot => {
      if (snapshot.exists()) {
        const calendar = snapshot.data() as Calendar;
        dispatch(setSelectedCalendar(calendar));
      }
    })
    .catch((err) => {
      captureException(err)
    });
};

export const updateSelectedCalendar =
  (calendar: Calendar): ThunkPromise =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      // Set the selected calendar
      setDoc(SelectedCalendar(), {
        title: calendar.title,
        // source: calendar.source,
        id: calendar.id,
        timezone: calendar.timezone,
        accountId: calendar.accountId,
      })
        .then(() => {
          // Update local state
          dispatch(setSelectedCalendar(calendar));
          resolve();
        })
        .catch(error => {
          alert('Error selecting calendar');
          captureException(error)
          reject();
        });
    });

// STATE
interface CalendarState {
  selectedCalendar?: Calendar;
}

const initialState: CalendarState = {
  selectedCalendar: undefined,
};

// REDUCER
const reducer = (state = initialState, action: CalendarAction): CalendarState => {
  switch (action.type) {
    case ActionType.SET_SELECTED_CALENDAR:
      return { ...state, selectedCalendar: action.calendar };

    case ActionType.RESET:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
