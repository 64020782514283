import React from 'react';
import { View } from 'react-native';
import { PrimaryButton } from '../../components/Button';
import { useSafeBottomPadding } from '../../components/Hooks';
import Text from '../../components/Text';
import { PasswordSentScreenProps } from '../../navigators/Types';
import LoginBase from './LoginBase';
import { ResetPassword } from '../../services/Firebase';
import { useSelector } from 'react-redux';
import { userStateSelector } from '../../redux/Store';
import { useLinkTo } from '@react-navigation/native';

const PasswordResetScreen = ({ route, navigation }: PasswordSentScreenProps) => {
  const userState = useSelector(userStateSelector);
  const linkTo = useLinkTo();
  if (!userState.auth?.email) {
    //Error!
    linkTo('/search');
  }

  return (
    <LoginBase style={{ paddingHorizontal: 30, paddingBottom: useSafeBottomPadding() }} hasHeader>
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <Text
          fontFamily="Barlow-Medium"
          style={[{ textAlign: 'center', color: 'white', paddingHorizontal: 20, fontSize: 24 }]}
        >
          Press the button below to reset your password.
        </Text>
        <PrimaryButton
          inverted
          title="RESET"
          onPress={() => {
            ResetPassword(userState.auth?.email!);
            linkTo('/passwordsent');
          }}
        />
      </View>
    </LoginBase>
  );
};

export default PasswordResetScreen;
