import moment from 'moment';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { ActivityIndicator, Image, Keyboard, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { CodeField, Cursor, useClearByFocusCell } from 'react-native-confirmation-code-field';
import { useDispatch, useSelector } from 'react-redux';
import image_cross from '../assets/images/iconCross19px@3x.png';
import image_organisationplus from '../assets/images/iconOrganisationPlus@3x.png';
import { PrimaryButton, TextButton } from '../components/Button';
import { useSafeBottomPadding } from '../components/Hooks';
import Text from '../components/Text';
import { StringIsUndefinedOrEmpty } from '../components/TextHelper';
import { AcceptInviteScreenProps, AllStackParamList } from '../navigators/Types';
import { resetInvitationId } from '../redux/Invites';
import { UserType } from '../redux/Register';
import { userStateSelector } from '../redux/Store';
import { calendarStateSelector } from '../redux/Store';
import { reset, setProfile } from '../redux/User';
import {
  AuthErrorCode,
  CallFunction,
  GetProfile,
  ProfileDocument,
  Register,
  SignOut,
  logAnalyticsEvent,
} from '../services/Firebase';
import { ConfirmInvitation, SendInvitationCodeEmail } from '../services/InvitationService';
import Style, { Colour, FontSize } from '../styles/style';
import SignUpForm from './auth/components/SignUpForm';
import LoginBase from './auth/LoginBase';
import { ISignUpForm } from './auth/utils/model';
import ConnectCalendar from './listing/components/ConnectCalendar';
import calendarTick from '../assets/images/iconCalendarCircleTick@3x.png';

import { useLinkTo, useNavigation } from '@react-navigation/native';

import { StackNavigationProp } from '@react-navigation/stack';

import { setDoc } from 'firebase/firestore/lite';
import style from '../styles/style';
import { GetListing, SaveIndividual } from '../services/ListingService';

const CELL_SIZE = 70;
const stylesheet = StyleSheet.create({
  codeFieldRoot: {
    height: CELL_SIZE,
    paddingHorizontal: 20,
    justifyContent: 'center',
  },
  cell: {
    height: CELL_SIZE,
    width: CELL_SIZE,
    lineHeight: CELL_SIZE - 5,
    fontSize: 30,
    textAlign: 'center',
    color: 'white',
  },
  cellContainer: {
    backgroundColor: '#00000055',
    borderRadius: 6,
    marginHorizontal: 8,
  },
});

const AcceptInvitation = ({ route, navigation }: AcceptInviteScreenProps) => {
  const linkTo = useLinkTo();
  const calendarState = useSelector(calendarStateSelector);
  const userState = useSelector(userStateSelector);

  const dispatch = useDispatch();

  const [registering, setRegistering] = useState(false);
  const [confirming, setConfirming] = useState(false);
  const [showCodePrompt, setShowCodePrompt] = useState(false);
  const [showMergeAuthPrompt, setShowMergeAuthPrompt] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [forceEmail, setForceEmail] = useState(route.params.setForceEmail);
  const [allDone, setAllDone] = useState(route.params.allDone);
  const [signUpErrorMessage, setSignUpErrorMessage] = useState<string>(' ');
  const [codeError, setCodeError] = useState('');

  //This is pretty janky, but if keepTwoListings is undefined or null (as opposed to false)
  //we actually need to consider it as 'true', so we don't do the merge flow :)
  const [keepTwoListings, setkeepTwoListings] = useState(
    route.params.keepTwoListings === undefined ||
      route.params.keepTwoListings === null ||
      route.params.keepTwoListings === 'true',
  );
  const [oldListingId, setOldListingId] = useState<string>(route.params.oldListingId);
  const [codeValue, setCodeValue] = useState('');
  const [checkRecaptch, setCheckRecaptch] = useState({
    isrecaptchaError: false,
    isRecaptchaToken: false,
  });
  const [codeFieldProps, getCellOnLayoutHandler] = useClearByFocusCell({
    value: codeValue,
    setValue: setCodeValue,
  });

  //This is hacky and doesn't entirely work in all cases, but it's an easy way to get a bit more safety here
  const setOldListingIdAsQParam = (listingId: string) => {
    if ('URLSearchParams' in window) {
      var searchParams = new URLSearchParams(window.location.search);
      searchParams.set('oldListingId', listingId);
      var newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
      history.pushState(null, '', newRelativePathQuery);
      setOldListingId(listingId);
    }
  };

  const setkeepTwoListingsAsQParam = (merge: boolean) => {
    if ('URLSearchParams' in window) {
      var searchParams = new URLSearchParams(window.location.search);
      searchParams.set('keepTwoListings', String(merge));
      var newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
      history.pushState(null, '', newRelativePathQuery);
      setkeepTwoListings(merge);
    }
  };
  const signoutAndAcceptInviteWithNewEmail = () => {
    SignOut().then(() => {
      if ('URLSearchParams' in window) {
        var searchParams = new URLSearchParams(window.location.search);
        searchParams.set('setForceEmail', 'true');
        var newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
        history.pushState(null, '', newRelativePathQuery);
      }
      setForceEmail(true);
      dispatch(reset);
    });
  };

  useEffect(() => {
    if (userState.auth && userState.listing?.isOrg) {
      signoutAndAcceptInviteWithNewEmail();
    }
  }, [userState.auth, userState.listing?.isOrg, signoutAndAcceptInviteWithNewEmail]);

  const emailsMatch = useMemo(() => {
    return userState.auth?.email === route.params?.inviteeEmail;
  }, [userState.auth?.email, route.params?.inviteeEmail]);

  useEffect(() => {
    if (!emailsMatch && !userState.listing && !userState.loadingState) {
      signoutAndAcceptInviteWithNewEmail();
    }
  }, [emailsMatch, userState.listing, userState.loadingState, signoutAndAcceptInviteWithNewEmail]);

  const mustRegister = useMemo(() => {
    return userState.auth === undefined;
  }, [userState.auth]);

  const join = () => {
    // Note that the user may have chosen to register with a different email than was in the original invitation... so use the logged in users email here
    SendInvitationCodeEmail(userState.auth!.email!, route?.params?.inviteeName!, route?.params?.code!);
    setShowCodePrompt(true);
  };
  const confirmCode = () => {
    setCodeError('');

    if (codeValue == route?.params?.code) {
      setConfirming(true);

      ConfirmInvitation(
        route?.params?.id,
        userState.auth?.userId!,
        route?.params?.organisationId,
        userState.profile?.plan?.subscriptionId!,
        !keepTwoListings,
      )
        .then(async () => {
          dispatch(resetInvitationId());
          logAnalyticsEvent('invite_accepted', {
            uid: userState.auth?.userId!,
            orgId: route?.params?.organisationId,
          });
          const profileResult = await GetProfile(userState?.auth?.userId!);
          dispatch(
            setProfile({
              ...profileResult,
              parentProfileId: route?.params?.organisationId,
            }),
          );

          if (!keepTwoListings) {
            if (!userState.listing) {
              throw new Error(`Can't keep two listings if there isn't already a listing`);
            }
            CallFunction('requestAuthorisationToMergeListing', {
              listingId: oldListingId,
            }).then(() => {
              setShowCodePrompt(false);
              setShowMergeAuthPrompt(true);
              setConfirming(false);
            });
          } else {
            setShowCodePrompt(false);
            setShowCalendar(true);
            setConfirming(false);
          }
        })
        .catch(error => {
          setCodeError(error.message);
          setConfirming(false);
        });
    } else {
      setCodeError('Code incorrect');
    }
  };

  const onComplete = () => {
    setShowCalendar(false);
    setAllDone(true);
    // SaveIndividual({} as unknown as Listing).then(newListing => {
    //   setAllDone(true);
    // });
  };

  const onSignUp = useCallback(
    (values: ISignUpForm) => {
      if (!checkRecaptch.isRecaptchaToken) {
        setCheckRecaptch({
          isrecaptchaError: true,
          isRecaptchaToken: false,
        });
        return;
      }
      Keyboard.dismiss();
      setSignUpErrorMessage(' ');

      setRegistering(true);
      Register({
        ...values,
        email: values.email.trim(),
        userType: UserType.Individual,
      })
        .then(res => {
          // Set up firestore profile data
          const data = {
            userType: UserType.Individual,
            name: values.name,
            email: values.email.trim(),
            phone: '',
            dateCreated: moment().toISOString(),
          };
          setDoc(ProfileDocument(res.user.uid), data);
          // Might not get fetched by our auth state listener, so dispatch new profile data to store
          dispatch(setProfile(data));
        })
        .catch(reason => {
          if (reason.code == AuthErrorCode.AlreadyInUse) {
            setSignUpErrorMessage(
              'The email address is already in use. If this is your email address, login below to continue the invitation process.',
            );
          }
          setRegistering(false);
        });
    },
    [dispatch, checkRecaptch],
  );

  const safeBottomPadding = useSafeBottomPadding();

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity
          onPress={() =>
            mustRegister
              ? linkTo({ screen: 'Login', params: { returnToInvite: route?.params?.id } })
              : linkTo(showCodePrompt ? '/welcome' : '/searchnavigator')
          }
          style={{ width: 19, height: 19, marginRight: 15 }}>
          <Image source={image_cross} />
        </TouchableOpacity>
      ),
    });
  });

  const body = () => {
    if (userState.listing?.isOrg) {
      //This path should never be shown.. but just in case :)
      return (
        <>
          <View style={{ flex: 1, justifyContent: 'center' }}>
            <Image source={image_organisationplus} style={{ alignSelf: 'center' }} />

            <Text
              fontFamily="Barlow-Medium"
              style={{
                marginTop: 35,
                color: 'white',
                fontSize: 24,
                letterSpacing: 1.2,
                paddingHorizontal: 65,
                textAlign: 'center',
                lineHeight: 32,
              }}>
              It looks like you are already logged in with an Organisation account, please sign up for an individual
              listing to accept this invitation.
            </Text>

            <PrimaryButton
              containerStyle={{ marginTop: 50, paddingHorizontal: 30 }}
              title="CONTINUE"
              onPress={() => signoutAndAcceptInviteWithNewEmail()}
              inverted
            />
          </View>
        </>
      );
    }
    if (!emailsMatch && userState.listing && !userState.listing.isOrg) {
      return (
        <>
          <View
            style={[
              Style.footer,
              { flex: 1, backgroundColor: 'white', marginTop: 20, maxHeight: '400px', justifyContent: 'center' },
            ]}>
            <View style={Style.scrollview}>
              <Text style={[FontSize.medium, { textAlign: 'center', marginBottom: 30, marginTop: 30 }]}>
                It looks like you’re already logged in to AusLaw, but with a different email address. We can bring your
                existing profile across so it sits under your organisation. Otherwise, you could keep the old one, and
                set up a new listing with this email address. What’s best?
              </Text>

              <PrimaryButton
                title="Merge into one listing"
                loading={confirming}
                testID="oneListingButton"
                onPress={() => {
                  setkeepTwoListingsAsQParam(false);
                  setOldListingIdAsQParam(userState.listing?.id!);
                  signoutAndAcceptInviteWithNewEmail();
                }}
              />
              <Text style={[{ textAlign: 'center' }]}>OR</Text>
              <PrimaryButton
                loading={confirming}
                title="Keep as separate listings"
                testID="twoListingsButton"
                onPress={() => {
                  setkeepTwoListings(true);
                  signoutAndAcceptInviteWithNewEmail();
                }}
              />
            </View>
          </View>
        </>
      );
    } else if (allDone) {
      return (
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <Text
            fontFamily="Barlow-Medium"
            style={{
              color: 'white',
              fontSize: 24,
              letterSpacing: 1.2,
              paddingHorizontal: 30,
              textAlign: 'center',
              lineHeight: 32,
            }}>
            Thank you, your profile is now linked to {route?.params?.inviterName}
          </Text>

          <PrimaryButton
            containerStyle={{ marginTop: 50, paddingHorizontal: 30 }}
            title="CONTINUE"
            onPress={() => linkTo('/searchnavigator')}
            inverted
          />
        </View>
      );
    } else if (mustRegister) {
      return (
        <View style={[Style.footer, { flex: 1, backgroundColor: 'white', marginTop: 20, maxHeight: '600px' }]}>
          <ScrollView
            style={Style.scrollview}
            contentContainerStyle={{ paddingBottom: safeBottomPadding, paddingTop: 30 }}>
            <View style={{ flex: 1 }}>
              <Text style={[FontSize.medium, { textAlign: 'center', marginBottom: 30 }]}>
                To accept this invitation, you must first register an account.
              </Text>
              {/* hello */}
              <SignUpForm
                loading={registering}
                errorMessage={signUpErrorMessage}
                suggestedName={route?.params?.inviteeName}
                suggestedEmailAddress={route?.params?.inviteeEmail}
                onSubmit={onSignUp}
                forceEmail={forceEmail}
                setCheckRecaptch={setCheckRecaptch}
                checkRecaptch={checkRecaptch}
              />
            </View>
            <View style={{ alignItems: 'center', marginTop: 20 }}>
              <Text>Already have an account?</Text>
              <TextButton
                onPress={() => {
                  linkTo({ screen: 'Login', params: { returnToInvite: route?.params?.id } });
                }}
                text="Log in"
              />
            </View>
          </ScrollView>
        </View>
      );
    } else if (showCodePrompt) {
      return (
        <ScrollView
          contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}
          keyboardShouldPersistTaps="handled">
          <Text
            fontFamily="Barlow-Medium"
            style={{
              color: 'white',
              fontSize: 24,
              letterSpacing: 1.2,
              paddingHorizontal: 30,
              textAlign: 'center',
              lineHeight: 32,
            }}>
            We sent you an email with a code to join the team
          </Text>
          <View style={{ flexDirection: 'row', marginTop: 40, justifyContent: 'center' }}>
            <CodeField
              {...codeFieldProps}
              value={codeValue}
              onFocus={() => setCodeError('')}
              clearTextOnFocus={true}
              onChangeText={value => {
                setCodeValue(value);
                if (value.length === 4) {
                  Keyboard.dismiss();
                }
              }}
              cellCount={4}
              rootStyle={stylesheet.codeFieldRoot}
              keyboardType="number-pad"
              textContentType="oneTimeCode"
              renderCell={({ index, symbol, isFocused }) => (
                <View key={index} style={stylesheet.cellContainer}>
                  <Text style={stylesheet.cell} onLayout={getCellOnLayoutHandler(index)}>
                    {symbol || (isFocused ? <Cursor /> : null)}
                  </Text>
                </View>
              )}
            />
          </View>

          {!StringIsUndefinedOrEmpty(codeError) && (
            <View
              style={{
                backgroundColor: 'white',
                alignSelf: 'center',
                marginTop: 20,
                paddingHorizontal: 15,
                paddingVertical: 10,
                borderRadius: 6,
              }}>
              <Text fontFamily="Barlow-SemiBold" style={{ color: 'red', textAlign: 'center' }}>
                {codeError}
              </Text>
            </View>
          )}

          <PrimaryButton
            loading={confirming}
            disabled={confirming}
            containerStyle={{ marginTop: 50, paddingHorizontal: 30 }}
            title="JOIN"
            onPress={() => confirmCode()}
            inverted
          />
        </ScrollView>
      );
    } else if (showMergeAuthPrompt) {
      return (
        <>
          <View style={{ maxWidth: 800, flex: 1, justifyContent: 'center' }}>
            <Image source={image_organisationplus} style={{ alignSelf: 'center' }} />

            <Text
              fontFamily="Barlow-Medium"
              style={{
                marginTop: 35,
                color: 'white',
                fontSize: 24,
                letterSpacing: 1.2,
                paddingHorizontal: 65,
                textAlign: 'center',
                lineHeight: 32,
              }}>
              We have sent an email to the address that you initially used to set up your listing. Please check that
              email account and press the button there to continue merging your listings.
            </Text>

            <PrimaryButton
              containerStyle={{ marginTop: 50, paddingHorizontal: 30 }}
              title="Continue without merging your listings"
              //TODO -> Make this progress to calendar screen
              onPress={() => {
                setShowMergeAuthPrompt(false);
                setShowCalendar(true);
              }}
              inverted
            />
          </View>
        </>
      );
    } else if (showCalendar) {
      return (
        <View
          style={{
            flex: 1,
            justifyContent: 'space-between',
            backgroundColor: 'white',
            marginTop: 16,
            paddingHorizontal: 24,
            borderRadius: 24,
            maxWidth: 600,
            width: '100%',
            marginBottom: '10%',
          }}>
          <View></View>
          {calendarState.selectedCalendar ? (
            <View style={{ flex: 1, justifyContent: 'space-evenly' }}>
              <View>
                <Image style={{ width: 135, height: 135, alignSelf: 'center' }} source={calendarTick} />
                <Text style={{ color: '#757575', fontSize: 20, textAlign: 'center', marginTop: 30, marginBottom: 25 }}>
                  CALENDAR CONNECTED
                </Text>

                <Text fontFamily="Barlow-Bold" style={{ color: Colour.DarkBlue, fontSize: 24, textAlign: 'center' }}>
                  {calendarState.selectedCalendar.title}
                </Text>
              </View>
              <PrimaryButton
                inverted
                raised
                containerStyle={{ borderRadius: 25 }}
                buttonStyle={{
                  height: 50,
                  backgroundColor: 'white',
                }}
                titleStyle={{
                  fontFamily: 'Barlow-Medium',
                  fontSize: 18,
                  letterSpacing: 1.5,
                  color: Colour.Blue,
                }}
                title="CHOOSE DIFFERENT CALENDAR"
                onPress={() => linkTo('/chooseCalendar')}
              />
            </View>
          ) : (
            <ConnectCalendar inviteId={route?.params?.id} />
          )}
          <View style={{ paddingBottom: safeBottomPadding }}>
            <PrimaryButton title="COMPLETE" onPress={onComplete} />
          </View>
        </View>
      );
    } else if (route?.params?.id) {
      return (
        <>
          <View style={{ flex: 1, justifyContent: 'center' }}>
            <Image source={image_organisationplus} style={{ alignSelf: 'center' }} />

            <Text
              fontFamily="Barlow-Medium"
              style={{
                marginTop: 35,
                color: 'white',
                fontSize: 24,
                letterSpacing: 1.2,
                paddingHorizontal: 65,
                textAlign: 'center',
                lineHeight: 32,
              }}>
              {route?.params?.inviterName} has invited you to their team
            </Text>

            <PrimaryButton
              containerStyle={{ marginTop: 50, paddingHorizontal: 30 }}
              title="JOIN NOW"
              onPress={() => join()}
              inverted
            />
          </View>

          <TextButton
            text="Dismiss"
            onPress={() => navigation.replace('SearchNavigator')}
            textStyle={{
              fontFamily: 'Barlow',
              color: 'white',
              fontSize: 16,
              letterSpacing: 0.9,
              marginBottom: safeBottomPadding,
            }}
          />
        </>
      );
    }

    return (
      <View style={{ flex: 1, alignSelf: 'center', justifyContent: 'center' }}>
        <ActivityIndicator size="large" />
      </View>
    );
  };

  return <LoginBase hasHeader>{body()}</LoginBase>;
};

export default AcceptInvitation;
