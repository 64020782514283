import React from 'react';
import { View } from 'react-native';
import { PrimaryButton } from '../../components/Button';
import { useSafeBottomPadding } from '../../components/Hooks';
import Text from '../../components/Text';
import { PasswordSentScreenProps } from '../../navigators/Types';
import LoginBase from './LoginBase';
import { popSafely } from '../../tools/NavHelpers';

const PasswordSentScreen = ({ route, navigation }: PasswordSentScreenProps) => {
  return (
    <LoginBase style={{ paddingHorizontal: 30, paddingBottom: useSafeBottomPadding() }} hasHeader>
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <Text
          fontFamily="Barlow-Medium"
          style={[{ textAlign: 'center', color: 'white', paddingHorizontal: 20, fontSize: 24 }]}
        >
          We have just sent a temporary password to your email address
        </Text>
        <PrimaryButton
          inverted
          title="CONTINUE"
          onPress={() => {
            navigation.navigate('SearchNavigator');
          }}
        />
      </View>
    </LoginBase>
  );
};

export default PasswordSentScreen;
