import React, { useCallback, useState } from 'react';
import { Alert, Keyboard, ScrollView, View } from 'react-native';
import styled from 'styled-components/native';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import Style, { FontSize } from '../../../styles/style';
import { useSafeBottomPadding } from '../../../components/Hooks';
import { Controller, useForm } from 'react-hook-form';
import { FormError } from '../../../components/FormHelper';
import { Icon, Input } from 'react-native-elements';
import { PrimaryButton } from '../../../components/Button';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import { userStateSelector } from '../../../redux/Store';
import { DeleteAccount, DeleteUser, GetAuthErrorMessage, SignIn, SignOut } from '../../../services/Firebase';
import { setAuth } from '../../../redux/User';
import CustomText from '../../../components/Text';
import LoginBase from '../../auth/LoginBase';

const ConfirmationCopy = styled.Text`
  font-family: Barlow;
  font-weight: 500;
  font-size: 18px;
  margin-top: 24px;
  margin-bottom: 24px;
`;

const ConfirmAccountDeletionScreen = () => {
  const { control, handleSubmit } = useForm({
    defaultValues: { password: '' },
  });
  const userState = useSelector(userStateSelector);
  const [hidePassword, setHidePassword] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(' ');
  const [loading, setLoading] = useState<boolean>(false);
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const onDelete = useCallback(
    values => {
      if (userState.profile?.email) {
        Keyboard.dismiss();
        setErrorMessage(' ');
        SignIn({ ...values, email: userState.profile.email })
          .then(async res => {
            await DeleteUser(userState.profile?.plan?.subscriptionId || '');
            //@ts-ignore
            navigation.replace('AccountRemovedScreen');
          })
          .catch(reason => {
            setLoading(false);
            setErrorMessage(GetAuthErrorMessage(reason.code));
          });
        setLoading(true);
      }
    },
    [dispatch, navigation, userState],
  );

  return (
    <LoginBase>
      <View style={[Style.footer, { backgroundColor: 'rgba(0, 0, 0, 0)', justifyContent: 'flex-start' }]}>
        <CustomText
          style={[
            FontSize.extraLarge,
            {
              color: 'white',
              letterSpacing: 3,
              height: '10%',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
          ]}
        >
          Delete Profile
        </CustomText>
        <View style={{ backgroundColor: 'white', borderRadius: 24, padding: 30 }}>
          <ConfirmationCopy>
            Please confirm that would like to cancel your account and delete all data associated with your account. To
            do so please confirm by entering your account password
          </ConfirmationCopy>
          <Controller
            control={control}
            name="password"
            rules={{
              required: { value: true, message: 'Password is required' },
              minLength: { value: 8, message: 'Password must be 8 characters long' },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                value={value}
                onChangeText={onChange}
                errorMessage={error?.message}
                placeholder="Password"
                secureTextEntry={hidePassword}
                autoCapitalize="none"
                leftIcon={<LockOutlinedIcon />}
                rightIcon={
                  hidePassword ? (
                    <VisibilityOutlinedIcon onClick={() => setHidePassword(v => !v)} />
                  ) : (
                    <VisibilityOffOutlinedIcon onClick={() => setHidePassword(v => !v)} />
                  )
                }
              />
            )}
          />
          <CustomText style={{ color: 'red', fontSize: '18px', marginTop: 10 }} fontFamily="Barlow-Medium">
            {errorMessage}
          </CustomText>
          <PrimaryButton loading={loading} disabled={loading} title="Submit" onPress={handleSubmit(onDelete)} />
        </View>
      </View>
    </LoginBase>
  );
};

export default ConfirmAccountDeletionScreen;
