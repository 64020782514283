import { UserType } from '../redux/Register';
import { CallFunction, Collections, CreateFirestoreHelper, IFirebaseBaseDocument } from './Firebase';

export enum SubscriptionPeriod {
  Monthly,
  Yearly,
}

export interface PricingTier {
  title?: string;
  amount?: number;
  price: number;
  savings?: number;
}

export interface PricingModel {
  title?: string;
  isDoyles?: boolean;
  priceId: string;
  subscriptionPeriod: SubscriptionPeriod;
  prices: PricingTier[];
}

export interface Product extends IFirebaseBaseDocument {
  plans: PricingModel[];
}

export const products: Product[] = [
  {
    id: UserType.Organisation,
    plans: [
      {
        subscriptionPeriod: SubscriptionPeriod.Monthly,
        priceId: 'price_1IQh37DxvNMXOwTSFIOx38e5',
        prices: [
          {
            title: '1 - 5',
            price: 79.95,
            amount: 5,
          },
          {
            title: '6 - 12',
            price: 179.95,
            amount: 12,
          },
          {
            title: '13 - 20',
            price: 289,
            amount: 20,
          },
          {
            title: 'Unlimited Profiles',
            amount: 21,
            price: 495,
          },
        ],
      },
      {
        subscriptionPeriod: SubscriptionPeriod.Yearly,
        priceId: 'price_1IQh4ADxvNMXOwTSS7VN0ib8',
        prices: [
          {
            title: '1 - 5',
            price: 767,
            amount: 5,
            savings: 192.4,
          },
          {
            title: '6 - 12',
            price: 1728,
            amount: 12,
            savings: 431.4,
          },
          {
            title: '13 - 20',
            price: 2774,
            amount: 20,
            savings: 694,
          },
          {
            title: 'Unlimited Profiles',
            amount: 21,
            price: 4752,
            savings: 1188,
          },
        ],
      },
      {
        isDoyles: true,
        subscriptionPeriod: SubscriptionPeriod.Yearly,
        priceId: 'price_1Lx4W6DxvNMXOwTSkFvQsObn',
        prices: [
          {
            title: '1 - 5',
            price: 767,
            amount: 5,
            savings: 192.4,
          },
          {
            title: '6 - 12',
            price: 1728,
            amount: 12,
            savings: 431.4,
          },
          {
            title: '13 - 20',
            price: 2774,
            amount: 20,
            savings: 694,
          },
          {
            title: 'Unlimited Profiles',
            amount: 21,
            price: 4752,
            savings: 1188,
          },
        ],
      },
    ],
  },
  {
    id: UserType.Individual,
    plans: [
      {
        subscriptionPeriod: SubscriptionPeriod.Monthly,
        priceId: 'price_1Hm5PwDxvNMXOwTSmM4Vlp6h',
        prices: [
          {
            price: 39.95,
          },
        ],
      },
      {
        subscriptionPeriod: SubscriptionPeriod.Yearly,
        priceId: 'price_1Hm5PwDxvNMXOwTS5dPRg37A',
        prices: [
          {
            price: 384.5,
            savings: 94.9,
          },
        ],
      },
      {
        title: 'individual',
        isDoyles: true,
        subscriptionPeriod: SubscriptionPeriod.Yearly,
        priceId: 'price_1Lx4SLDxvNMXOwTSTMaNM0fw',
        prices: [
          {
            price: 900,
          },
        ],
      },
    ],
  },
];

// Payment details models
export interface PaymentDetails {
  stripeToken?: string;
  subscriptionPeriod: SubscriptionPeriod;
  priceId: string;
  profileAmount?: number;
  isDoyles?: boolean;
}

// Payment results models
export interface Plan {
  customerId: string;
  subscriptionId?: string;
  trial_end?: string;
  // priceId?: string;
  profileAmount?: number;
  period: SubscriptionPeriod;
}
export interface PaymentResults {
  success: boolean;
  plan?: Plan;
  statusCode?: number;
  error?: any;
}

export const DoPayment = (tokenId: string, details: PaymentDetails) =>
  new Promise<PaymentResults>((resolve, reject) => {
    CallFunction('payWithStripe', { ...details, stripeToken: tokenId })
      .then(res => {
        const paymentResults = res as PaymentResults;
        resolve(paymentResults);
      })
      .catch(reject);
  });

export const StripeProductFirestore = CreateFirestoreHelper<Product>(Collections.Prices);
