import moment from 'moment';
import React, { useCallback } from 'react';
import { Keyboard, ScrollView, View } from 'react-native';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { Divider, Icon, ListItem } from 'react-native-elements';
import { useDispatch, useSelector } from 'react-redux';
import { selectedTimesHumanReadable } from '../../components/Calendar/CalendarHelper';
import { ratePeriods, rateValues, reportValues } from '../../components/ModalRateSelect';
import Text from '../../components/Text';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { RefineSearchScreenProps } from '../../navigators/Types';
import { getNameFromMultiIndex } from '../../redux/PickLists';
import { Filter, FilterType, Rate, SearchParams, setSearchParams } from '../../redux/Search';
import { pickListsStateSelector, searchStateSelector } from '../../redux/Store';
import Style, { Barlow, Colour, FontColour, FontSize } from '../../styles/style';
import SearchFilterForm from './components/SearchFilterForm';
import { ISearchFilterParams } from './utils/model';
import { popSafely } from '../../tools/NavHelpers';
import LoginBase from '../auth/LoginBase';

const headerTextStyle = [Barlow.medium, FontSize.large, FontColour.darkBlue, { paddingLeft: 20, paddingVertical: 10 }];



const RefineSearchScreen = ({ route, navigation }: RefineSearchScreenProps) => {
  const dispatch = useDispatch();
  const { recent } = useSelector(searchStateSelector);
  const { services, locations } = useSelector(pickListsStateSelector);

  const FilterToString = (filter: Filter) => {
    if (filter.type === FilterType.Service) {
      return (filter.value as string[]).map(val => getNameFromMultiIndex(val, services)).join(', ');
    }
  
    if (filter.type === FilterType.Location) {
      return (filter.value as string[]).map(val => getNameFromMultiIndex(val, locations)).join(', ');
    }
  
    if (filter.type === FilterType.Rate) {
      const rate = filter.value as Rate;
      let text: string[] = [];
      if (rate.rate !== undefined) {
        text.push(`${ratePeriods[rate.ratePeriod]}: ${rateValues[4 * Number(rate.ratePeriod) + Number(rate.rate)]}`);
      }
      if (rate.onlyHourly) {
        text.push('Only Hourly');
      }
      if (rate.onlyDaily) {
        text.push('Only Daily');
      }
      if (rate.reportRate !== undefined) {
        text.push(`Report Rate: ${reportValues[rate.reportRate]}`);
      }
      if (rate.onlyReport) {
        text.push('Only Report');
      }
      return text.join(', ');
    }
  
    if (filter.value instanceof Array) {
      if (filter.value.length > 0) {
        return filter.value.reduce((v, c) => (v += ', ' + c));
      }
      return undefined;
    }
  
    if (filter.value instanceof String || filter.value instanceof Number) {
      return filter.value.toString();
    }
  
    const value = filter.value as { startDate: string; endDate: string };
  
    const duration = moment.duration(moment(value.endDate).diff(value.startDate));
    const hours = duration.asHours()
    const block = hours % 4 === 0 ? 0 : 4
  
  
    return selectedTimesHumanReadable([value.startDate, value.endDate], block);
  };

  const onFilter = useCallback(
    (values: ISearchFilterParams) => {
      Keyboard.dismiss();

      const { sort, services, locations, dates, rates, isDoyles } = values;
      const newParams: SearchParams = { filters: [] };

      if (sort) {
        newParams.sort = sort;
      }

      if (services?.length) {
        newParams.filters!.push({
          type: FilterType.Service,
          value: services,
        });
      }

      if (locations?.length) {
        newParams.filters!.push({
          type: FilterType.Location,
          value: locations,
        });
      }

      if (dates && dates.length) {
        const startDate = dates[0];
        const endDate = dates.length > 1 ? dates[1] : moment(startDate).add(4, 'hour').toISOString();

        newParams.filters!.push({
          type: FilterType.DateRange,
          value: { startDate, endDate },
        });
      }

      if (rates) {
        newParams.filters!.push({
          type: FilterType.Rate,
          value: rates,
        });
      }

      if (isDoyles) {
        newParams.filters!.push({
          type: FilterType.Doyles,
          value: isDoyles,
        });
      }

      // console.log(JSON.stringify(newParams));
      popSafely(navigation);
      dispatch(setSearchParams(newParams || {}));
    },
    [dispatch, navigation],
  );

  return (
    <LoginBase>
      <View style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <View style={Style.footer}>
          <SearchFilterForm onFilter={onFilter} />

          {recent.length > 0 && (
            <>
              <Text style={[headerTextStyle, { marginTop: 0 }]}>Recent</Text>
              <Divider />
              <View style={{borderRadius: 24, overflow: 'hidden'}}>
                {recent.map((param, i) => {
                  return (
                    <ListItem
                      key={i.toString()}
                      bottomDivider
                      onPress={() => {
                        dispatch(setSearchParams(param));
                        popSafely(navigation);
                      }}
                      hasTVPreferredFocus={undefined}
                      tvParallaxProperties={undefined}
                    >
                      <AccessTimeFilledIcon style={{color:"#C0C0C0"}} />

                      <ListItem.Content>
                        <ListItem.Title>
                          {param.name ? (
                            <Text style={Style.text} numberOfLines={1}>
                              {param.name}
                            </Text>
                          ) : (
                            param.filters!.map((filter, index) => {
                              const text = FilterToString(filter);
                              return (
                                <Text style={[Style.text, {}]} key={index} numberOfLines={1}>
                                  {text}
                                </Text>
                              );
                            })
                          )}
                        </ListItem.Title>
                      </ListItem.Content>
                      <KeyboardArrowRightIcon name="chevron-right" style={{color: Colour.Blue}}/>
                    </ListItem>
                  );
                })}
              </View>
            </>
          )}
        </View>
      </View>
    </LoginBase>
  );
};

export default RefineSearchScreen;
