import React from 'react';
import { Image, View } from 'react-native';
import { Header } from 'react-native-elements';
import image_sent from '../../assets/images/iconEnvelopeTick@3x.png';
import image_logo from '../../assets/images/imgLogoIcon34px@3x.png';
import { PrimaryButton } from '../../components/Button';
import { useSafeBottomPadding } from '../../components/Hooks';
import Text from '../../components/Text';
import { MessageSentScreenProps } from '../../navigators/Types';
import LoginBase from '../auth/LoginBase';
import { popSafely } from '../../tools/NavHelpers';

const MessageSent = ({ route, navigation }: MessageSentScreenProps) => {
  return (
    <LoginBase
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
        paddingBottom: useSafeBottomPadding(),
      }}
    >
      <View style={{ alignItems: 'center' }}>
        <Image source={image_sent} style={{ position: 'relative', left: 13 }} />
        <Text
          fontFamily="Barlow-Medium"
          style={{
            textAlign: 'center',
            color: 'white',
            marginTop: 25,
            fontSize: 24,
            letterSpacing: 1.2,
            paddingLeft: 50,
            paddingRight: 50,
          }}
        >
          Your message has been emailed to {route.params.name}.
        </Text>
        <PrimaryButton inverted title="CONTINUE" onPress={() => popSafely(navigation)} />
      </View>
    </LoginBase>
  );
};

export default MessageSent;
