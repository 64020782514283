import { StackNavigationProp } from '@react-navigation/stack';
import { AllStackParamList } from '../navigators/Types';

export const popSafely = (navigation: StackNavigationProp<AllStackParamList, keyof AllStackParamList>) => {
  if (navigation.canGoBack()) {
    navigation.pop();
  } else {
    navigation.navigate('SearchNavigator');
  }
};
